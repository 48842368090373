import { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import { Spinner } from 'react-bootstrap';

const useStyles = makeStyles(theme => ({
  videoContainer: {
    visibility: prop => (prop.loading ? 'hidden' : 'visible')
  },
  loader: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    '& .spinner-border': {
      width: '4rem',
      height: '4rem'
    }
  }
}));

const VideoTag = ({
  customClasses,
  controls,
  autoPlay,
  preload,
  src,
  type,
  customStyles,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles({ loading });

  const onLoadedData = e => {
    // hide loading, when video data loaded
    setLoading(false);
  };

  return (
    <>
      {/* visibility hidden when loading and will be visible on loading false */}
      <video
        className={`${classes.videoContainer} ${customClasses}`}
        controls={controls}
        autoPlay={autoPlay}
        preload={preload}
        style={customStyles}
        onLoadedData={onLoadedData}
        {...rest}
      >
        <source src={src} type={type}></source>
      </video>
      {/* show loader */}
      {loading && (
        <span className={classes.loader}>
          <Spinner animation="border" variant="secondary" size="lg" />
        </span>
      )}
    </>
  );
};

VideoTag.propTypes = {
  customClasses: PropTypes.string,
  controls: PropTypes.bool,
  autoPlay: PropTypes.bool,
  preload: PropTypes.string,
  src: PropTypes.string.isRequired,
  type: PropTypes.string,
  customStyles: PropTypes.object
};

VideoTag.defaultProps = {
  customClasses: '',
  controls: true,
  autoPlay: false,
  preload: 'author',
  src: '',
  type: 'video/mp4',
  customStyles: {}
};

export default VideoTag;
