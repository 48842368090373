export const en_hoursCallConnect = {
  heading1: 'Hours/Call Connect',
  heading2: 'Call Connect',
  heading3: 'Call Connect Hours',
  heading4: 'Office Hours',
  timeZone: {
    label: 'Time Zone',
    info: 'The timezone of the business and call connect number.'
  },
  quickConnectPhoneNumber: {
    label: 'Phone Number',
    info: 'The call connect is the number are system will connect the visitor to during or after business hours.'
  },
  transcriptOverride: {
    label: 'Transcript Override',
    info: 'If set, the "Call Connect Transcript" will be delivered only to "Call Connect Transcript Emails".'
  },
  transcriptEmail: {
    label: 'Transcript Email',
    info: 'Insert email addresses to send Call Connect Transcript to.'
  },
  useCallConnectNumberforCallerID: {
    label: 'Use Call Connect Number for Caller ID',
    info: 'If set, uses the Call Connect number for the caller ID; this requires that the number be validated in Twilio).'
  },
  companyNumberFirst: {
    label: 'Company Number First',
    info: 'If set, Call Connect will automatically call company number first.'
  },
  override: {
    label: 'Override',
    info: 'If set, a number will be selected from the company level pool of Twilio numbers.'
  },
  officeHourMonitoring: {
    label: 'Office Hour Monitoring',
    info: 'If enabled, and the office hours are set, the invitation will only drop during or after office hours as appropriate; if office hours are NOT set, the office is assumed to be open and the invitation will drop anyway; if disabled, office hours will be ignored and the invitation will simply always be shown. This is still subject to the "monitoring enabled" flag.'
  },
  smsChatNumber: {
    label: 'SMS Chat Number',
    info: 'A unique SMS number purchased for the account to enable SMS text-to-chat.'
  },
  visitorgreeting: {
    label: 'Visitor greeting',
    info: 'Message to say to the visitor over the phone when connecting via call connect [leave blank to use the host setting]; you can use "<#=companyName#>" and "<#=hostTitle#>" in this template.'
  },
  visitorgreetingdelay: {
    label: 'Visitor greeting delay',
    info: 'Number of seconds to wait before Visitor greeting plays. A lot of times, a part of this greeting is cut off as it starts playing before the visitor starts listening.'
  },
  businessgreeting: {
    label: 'Business greeting',
    info: 'Message to say to the business over the phone when connecting via call connect [leave blank to use the host setting]; you can use "<#=companyName#>" and "<#=hostTitle#>" in this template.'
  },
  businessgreetingdelay: {
    label: 'Business greeting delay',
    info: 'Number of seconds to wait before connecting the call to the business. This is usefull when the call gets picked up by a machine before transferring to a human. This delay will depend on how long the automated message is. The purpose of having this delay is to deliver the business greeting to a human and not to the automated call attendant.'
  },
  monday: {
    label: 'Monday'
  },
  tuesday: {
    label: 'Tuesday'
  },
  wednesday: {
    label: 'Wednesday'
  },
  thursday: {
    label: 'Thursday'
  },
  friday: {
    label: 'Friday'
  },
  saturday: {
    label: 'Saturday'
  },
  sunday: {
    label: 'Sunday'
  },
  copyToAll: {
    label: 'Copy to all'
  }
};
export default en_hoursCallConnect;
export { default as fr_hoursCallConnect } from '../fr-FR-Dictionary/fr-hoursCallConnect';
export { default as sp_hoursCallConnect } from '../sp-SP-Dictionary/sp-hoursCallConnect';
