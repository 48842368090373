export const fr_chatDetails = {
  heading: 'Détails de la conversation',
  chatTooltip: "Le portail affiche jusqu'à 6 mois de données",
  createdOn: {
    label: 'Créé',
    info: 'Lorsque le chat a été lancé dans notre système.'
  },
  appointmentStatus: {
    label: 'Statut du rendez-vous',
    info: 'Statut du rendez-vous'
  },
  appointmentStartTime: {
    label: 'Heure de début',
    info: 'Heure de début'
  },
  appointmentEndTime: {
    label: 'Heure de fin',
    info: 'Heure de fin'
  },
  pickedUpOn: {
    label: 'Ramassée',
    info: 'Quand le chat a été ramassé sur.'
  },
  pickedUpBy: {
    label: 'Ramassé par',
    info: 'Qui a pris le chat.'
  },
  endedOn: {
    label: 'Terminé',
    info: 'Quand le chat a pris fin dans notre système.'
  },
  leadFlag: {
    label: 'Drapeau plomb',
    info: 'Si la conversation dans le chat transformé en une avance valide.'
  },
  ip: {
    label: 'IP',
    info: "L'IP où le chat a commencé."
  },
  location: {
    label: 'Emplacement',
    info: "L'emplacement du visiteur."
  },
  referrer: {
    label: 'Référent',
    info: 'Le referrer où le chat a été lancé.'
  },
  chatTranscript: {
    label: 'Transcription des conversations',
    info: 'Transcription des conversations'
  }
};
export default fr_chatDetails;
