export const en_tour = {
  tourWelcome: 'Hello <user>, welcome to the new chat dashboard.',
  tourWelcomeText:
    "Let's take a quick tour of the new chat dashboard to show you around and familiarize yourself with the functionality and changes you can make now.",
  featureTourWelcome: 'Hello <user>, welcome to the new portal.',
  featureTourWelcomeText:
    "Let's take a quick tour of the new features and familiarize yourself with the functionality and changes you can make now.",
  featureTourDisclaimer:
    'Disclaimer: Based on roles and permissions, few users might not be seeing some features.',
  whatsNew: "What's New",
  weHaveAnUpdate: 'We Have An Update!',
  tourSubHeading: 'We have added following missing items into the new portal:',
  tourAnalytics: 'Welcome to the new analytics dashboard. More is coming soon!',
  tourMessaging:
    'You can now use the new and improved messaging dashboard within the portal.',
  tourCompanies:
    'The company tab allows you to make changes within your account.',
  tourChats: 'Incoming chats handled for the past six months.',
  tourLeads: 'Incoming chat Lead for the past six months.',
  tourReports:
    'You can now create new reports and reviewing existing reports within the new portal.',
  tourOnboarding: 'You can onboard a new account within the new portal.',
  tourHelp:
    '<p><b>SUPPORT BOT</b> - The support bot is here to answer questions and submit a ticket to our internal team who will get back to you.</p><p><b>LAUNCH TOUR</b> - Take a quick tour and familiarize yourself with the new layout.</p>',
  tourSwitch: 'You can switch between the new and existing system.',
  tourEnd: "That's the end of the tour.",
  tourEnd2:
    'We hope you enjoyed the tour and look forward to bringing more features to the new chat dashboard soon. If you have any questions please reach out to us. We are always here to help.',
  btn1: 'Maybe Later',
  btn2: 'Start Tour',
  btn3: 'Previous',
  btn4: 'Got it',
  btn5: 'Next',
  btn6: 'Close',
  btn7: 'Skip tour',
  btn8: 'Done',
  btn9: 'Recently Added Features',
  feature: {
    exportFeature: 'The button will allow you to export grid data.'
  },
  tourSettings: '<p><b>Settings</b> - Get your widget integration options here.'
};
export default en_tour;
export { default as fr_tour } from '../fr-FR-TourDictionary/fr-Tour';
export { default as sp_tour } from '../sp-SP-TourDictionary/sp-Tour';
