import renderToolTip from 'pub-components/ui-controls/renderToolTip';
import React, { useCallback, useEffect } from 'react';
import { Button, OverlayTrigger, Tab, Tabs } from 'react-bootstrap';
import 'assets/app-styles/scss/tabs.scss';
import {
  UilAngleLeft,
  UilAngleRight,
  UilTimes
} from '@iconscout/react-unicons';
import './styles.scss';
import { useState } from 'react';
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const timer = 50;

export const TabsContainer = ({
  children,
  onTabSelect,
  activeKey,
  unControlled,
  clearAll,
  openColapse,
  selectedData = [],
  fullWidthCollapsible,
  leftHoverBtn = true,
  rightHoverBtn = true,
  closeAllBtn = true,
  mountOnEnter = false
}) => {
  const [scrollable, setScrollable] = useState(false);
  let scroll_right = false,
    scroll_left = false;

  // scroll right
  const move_right_over = () => {
    scroll_right = true;
    rightSelfCall();
  };

  const rightSelfCall = () => {
    let element = document.getElementsByClassName('sidebar-right-navtabs')[0];
    let maxScrollLeft = element.scrollWidth - element.clientWidth;
    if (scroll_right && maxScrollLeft !== Math.round(element.scrollLeft)) {
      element.scrollLeft += 20;
      setTimeout(() => {
        rightSelfCall();
      }, timer);
    }
  };

  const move_right_out = () => {
    scroll_right = false;
  };
  // ------------ end ----------

  // scroll left
  const move_left_over = () => {
    scroll_left = true;
    leftSelfCall();
  };
  const leftSelfCall = () => {
    let element = document.getElementsByClassName('sidebar-right-navtabs')[0];
    if (scroll_left && element.scrollLeft !== 0) {
      element.scrollLeft -= 20;
      setTimeout(() => {
        leftSelfCall();
      }, timer);
    }
  };

  const move_left_out = () => {
    scroll_left = false;
  };
  // ---------- end ---------

  const move_to_tab = useCallback(() => {
    let elem = document.getElementById(`tab-${activeKey}`);
    if (elem) {
      let divLeft = elem.offsetLeft;
      document.getElementsByClassName('nav-tabs')[0].scrollLeft = divLeft - 52;
    }
  }, [activeKey]);

  useEffect(() => {
    move_to_tab();
  }, [openColapse, selectedData]);

  useEffect(() => {
    if (openColapse) {
      checkIsScrollable();
    } else {
      setTimeout(() => {
        checkIsScrollable();
      }, 2000);
    }
  }, [openColapse, selectedData.length, fullWidthCollapsible]);

  const checkIsScrollable = () => {
    let element = document.getElementsByClassName('sidebar-right-navtabs')[0];
    if (element && element.scrollWidth > element.clientWidth) {
      setScrollable(true);
    } else {
      setScrollable(false);
    }
  };

  return (
    <>
      <span className="first-arrow">
        {(openColapse || fullWidthCollapsible) && scrollable ? (
          <span
            className="cursor-pointer"
            onMouseOut={move_left_out}
            onMouseOver={move_left_over}
          >
            <UilAngleLeft />
          </span>
        ) : null}
      </span>

      {unControlled ? (
        <Tabs defaultActiveKey={activeKey} className="uncontrolledTabs">
          {/* TODO Multiple api hits because of map, need to fix it  */}
          {children}
        </Tabs>
      ) : (
        <Tabs
          mountOnEnter={mountOnEnter}
          onSelect={e => onTabSelect(e)}
          activeKey={activeKey}
          className="sidebar-right-navtabs navbar-nav"
          style={{
            // width: 'calc(100% - 185px)',
            width: 'calc(100% - 11rem)',
            borderRadius: '24px,0,0,24px',
            position: 'relative',
            // left: '40px',
            marginLeft: `${
              (openColapse || fullWidthCollapsible) && scrollable
                ? '40px'
                : 'unset'
            }`,
            paddingRight: `${
              (openColapse || fullWidthCollapsible) && scrollable
                ? '0.625rem'
                : // '10px'
                  'unset'
            }`
          }}
        >
          {/* TODO Multiple api hits because of map, need to fix it  */}
          {children}
        </Tabs>
      )}
      <span className="second-arrow">
        {(openColapse || fullWidthCollapsible) && scrollable ? (
          <span
            className="cursor-pointer"
            onMouseOut={move_right_out}
            onMouseOver={move_right_over}
          >
            <UilAngleRight />
          </span>
        ) : null}
      </span>
      <span
        className="cursor-pointer mr-3"
        style={{
          position: 'absolute',
          display: 'inline-block',
          top: 6,
          right: 0
        }}
      >
        {(openColapse || fullWidthCollapsible) &&
        closeAllBtn &&
        selectedData.length > 1 ? (
          <span onClick={clearAll ? clearAll : null}>
            <Button
              className="btn-md btn-flex"
              type="button"
              variant="primary"
              style={{ padding: '5px 10px 5px 2px' }}
              title={'Click here to close all tabs.'}
            >
              <UilTimes className="close-btn-icon" /> Close All
            </Button>
          </span>
        ) : null}
      </span>

      {/* {closeAllBtn ? (
        <span className="close-all-tabs cursor-pointer pr-3">
          {(openColapse && selectedData.length > 1) ||
          (fullWidthCollapsible && selectedData.length > 1) ? (
            <span
              // className="close-all-tabs cursor-pointer pr-3"
              onClick={clearAll ? clearAll : null}
            >
              <Button
                className="btn-md btn-flex"
                type="button"
                variant="primary"
                style={{ padding: '5px 10px 5px 2px' }}
                title={'Click here to close all tabs.'}
              >
                <UilTimes className="close-btn-icon" /> Close All
              </Button>
            </span>
          ) : null}
        </span>
      ) : null} */}
    </>
  );
};

// const TabTitle = ({ title, closeButton }) => (
//   <span>
//     {title}
//     <span className="close-btn" onClick={closeButton}>
//       &nbsp;&nbsp;&nbsp; x
//     </span>
//   </span>
// );

export const TabComponent = ({
  disabled = false,
  children,
  eventKey,
  title,
  key,
  id
  // closeButton
}) => {
  return (
    <Tab
      disabled={disabled}
      id={id}
      tabClassName="tab-ui"
      key={key}
      eventKey={eventKey}
      title={
        title
        // <TabTitle title={title} closeButton={closeButton} />
      }
    >
      {children}
    </Tab>
  );
};

export const nextSelectIndex = (index, length) => {
  if (index <= length && length > 1) {
    if (index > 0) {
      return index - 1;
    } else if (index === 0) {
      return index + 1;
    }
  } else return 0;
};
export const CustomTabTitle = ({ label, styles = {} }) => (
  <span style={styles}>{label}</span>
);

export const TabNav = ({
  index,
  id,
  title = '',
  totalTabs = 0,
  removeTab,
  activeKey,
  textLimit = 12,
  className = '',
  customStyles
}) => {
  return (
    <span className={className}>
      <OverlayTrigger placement={'bottom'} overlay={renderToolTip(title)}>
        <span
          style={{
            width: '110px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            textAlign: 'left',
            ...customStyles
          }}
        >
          {title}
        </span>
      </OverlayTrigger>

      {removeTab && (
        <IconButton
          aria-label="delete"
          className={'m-0'}
          size="small"
          onClick={e => {
            e.stopPropagation();
            removeTab(index, id, nextSelectIndex(index, totalTabs));
          }}
        >
          <Clear style={{ fontSize: '1rem' }} />
        </IconButton>
      )}
    </span>
  );
};
