export const sp_mobileSettings = {
  heading1: 'Móviles',
  enableLive: {
    label: 'Habilitar Vivo',
    info: 'Si se activa, los agentes y los operadores serán capaces de transferir al usuario a una sesión de Live.'
  },
  recordLiveSessions: {
    label: 'Grabar Live Sessions',
    info: 'Si se establece, se registrarán las sesiones en vivo para su posterior revisión.'
  },
  videoCallTimeout: {
    label: 'Llamada de vídeo Tiempo de espera (segundos)',
    info: 'Número de segundos de espera antes de que termine la llamada de vídeo.'
  },
  mobileSuggestions: {
    label: 'Sugerencias móviles',
    info: 'El script asociado con la empresa.'
  },
  workflow: {
    label: 'Flujo de Trabajo',
    info: 'Determina las que se utilizará el flujo de trabajo para la búsqueda de agentes vivos.'
  },
  note: {
    label:
      'Nota: Un tema personalizable debe ser utilizado con Live con el fin de funcionar correctamente'
  }
};
export default sp_mobileSettings;
