import Login from 'pages/auth/login';
import ForgotPassword from 'pages/auth/forgot-password';
import SSO from 'pages/auth/sso';
import NotFound from 'pages/errors/404';

const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: props => <Login {...props} />,
    icon: 'user',
    exact: true
  },
  {
    path: '/login/:hostKey',
    name: 'LoginForHost',
    component: props => <Login {...props} />,
    icon: 'user',
    exact: true
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: props => <ForgotPassword {...props} />,
    icon: 'user',
    exact: true
  },
  {
    path: '/sso/:id',
    name: 'sso',
    component: props => <SSO {...props} />,
    icon: 'user',
    exact: true
  },
  {
    path: '/not-found',
    name: 'Not Found',
    component: props => <NotFound {...props} />,
    icon: 'user',
    exact: true
  },
  {
    path: '/access-denied',
    name: 'Permission Denied',
    component: props => (
      <NotFound
        errorCode={403}
        message="Sorry, you do not have permission to access this page."
        goBack={true}
        {...props}
      />
    ),
    icon: 'user',
    exact: true
  }
];

export default authRoutes;
