import React from 'react';
import './style.scss';

const SmartAppBanner = ({ appName, appLink, text, icon, btnText, show }) => {
  return (
    <div className={`app-banner${!show ? ' d-none' : ''}`}>
      <img src={icon} className="app-icon" />
      <span className="ml-3 mr-auto">
        <p className="app-name">{appName}</p>
        <p className="help-text">{text}</p>
      </span>
      <a href={appLink} className="btn btn-lightgray-3 custom-radius">
        {btnText}
      </a>
    </div>
  );
};

export default SmartAppBanner;
