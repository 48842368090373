import React from 'react';
import { Form } from 'react-bootstrap';
import { errors } from 'services/utils/variables';
import { setErrorClass } from 'services/utils/generic.methods';
import UILEyeShow from '@iconscout/react-unicons/icons/uil-eye-slash';
import UILEye from '@iconscout/react-unicons/icons/uil-eye';
import {
  FormControl,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  TextField
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

export const FormGroupField = ({
  controlId = 'oldPassword',
  dataFormIk,
  fieldErrorOnSubmit,
  type = 'text',
  handleOnChange,
  label = 'Label',
  toggleButton,
  disabled = true,
  show
}) => (
  <FormGroup key={controlId + '-field'}>
    <FormControl
      fullWidth
      variant="outlined"
      className={`${
        dataFormIk.values[controlId] || dataFormIk.values[controlId] === 0
          ? 'has-value'
          : ''
      } ${dataFormIk.errors[controlId] ? 'is-invalid' : ''} ${
        !disabled ? 'has-disabled' : ''
      }`}
    >
      <TextField
        id={controlId}
        type={show && type === 'password' ? 'text' : type}
        name={controlId}
        variant="outlined"
        onBlur={dataFormIk.handleBlur}
        label={label}
        onChange={e => handleOnChange(e)}
        value={dataFormIk.values[controlId]}
        endAdornment={
          type === 'password' ? (
            <IconButton onClick={toggleButton} edge="end">
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          ) : null
        }
        disabled={disabled}
        error={!!dataFormIk.errors[controlId]}
      />
      {!!dataFormIk.errors[controlId] && (
        <FormHelperText
          style={{ color: 'red', position: 'absolute', bottom: '-22px' }}
        >
          {dataFormIk.errors[controlId]}
        </FormHelperText>
      )}
    </FormControl>
  </FormGroup>
);

export const FormGroupToggleField = ({
  controlId = 'oldPassword',
  dataFormIk,
  fieldErrorOnSubmit,
  type = 'text',
  handleChecked,
  toggleButton,
  show,
  label = 'Label',
  //   checked = false,
  disabled = true
}) => (
  <Form.Group controlId={controlId} className={'mb-0'}>
    {/* <Form.Control
      className="lined with-label"
      type={type}
      label={controlId}
      name={controlId}
      disabled={true}
      value={dataFormIk.values[controlId]}
    /> */}
    <div className="lined with-label form-control">
      <Form.Label className="label-center">{label}</Form.Label>
    </div>
    {/* <i onClick={toggleButton}>
      {show ? <UILEye size="12" /> : <UILEyeShow size="12" />}
    </i> */}
    <div className="toggleBtn">
      <Form.Check
        type="switch"
        id={controlId}
        checked={dataFormIk.values[controlId]}
        onChange={e => handleChecked(e)}
        title={label}
        disabled={disabled}
      />
    </div>

    {/* <p className="text-red-1 field-error">
      {dataFormIk.errors[controlId] &&
        dataFormIk.touched[controlId] &&
        errors.fieldError(label)}
      {fieldErrorOnSubmit && errors.fieldCorrection(label)}
    </p> */}
  </Form.Group>
);
