import { Enums } from 'services/dropdown-enums';
import {
  ToastTemplate,
  getEnumTextByValue,
  getUserFromViewedUser
} from 'services/utils/generic.methods';
import storage from 'services/utils/storage';
import store from 'store';
import {
  removeFromIncomingSection,
  removeFromOptInLeadsSection,
  pushChatRoom,
  setSelectedChat,
  setChatStack
} from 'store/slices/messagingChats/messagingChats';
import {
  resetLeadFormReason,
  resetLeadFormUserCredentials
} from 'store/slices/lead/leadSlice';
import { toast } from 'react-toastify';
const Chatroom = function (data) {
  let me = this;
  me.data = data;
  const {
    auth: { profile }
  } = store.getState();
  const isUserActive = profile?.Active;
  // const isUserActive = JSON.parse(
  //   storage.getItem('messagingDashboard_isActive')
  // );
  const _currentUserId = profile ? profile.UserID : 0; // get from localStorage profile
  const _currentUserStatus = isUserActive !== undefined ? isUserActive : true; // save/get from localStorage dashboard Active/inActive
  const _checkCurrentUserIsParticipant = () => {
    if (me.data.participants) {
      for (var j = 0; j < me.data.participants.length; j++) {
        if (me.data.participants[j].userId === _currentUserId) {
          return true;
        }
      }
    }
    return false;
  };

  const _isParticipant = !!_checkCurrentUserIsParticipant();
  const _currentUserIsParticipant = () => {
    return _isParticipant;
  };
  const _isAssignedTo = userId => {
    userId = _currentUserId;
    return (
      ((me.data.assignedToAgent === userId ||
        me.data.assignedToOperator === userId) &&
        me.data.currentlyAssignedTo === 0 &&
        !me.data.generallyAvailable &&
        !me.data.transferredTo) ||
      me.data.transferredTo === userId ||
      (_isParticipant && !me.data.transferredTo) ||
      me.data.requestTransferTo === userId ||
      (me.data.requestTransferIds &&
        me.data.requestTransferIds.includes(userId)) ||
      me.data.currentlyAssignedTo === userId
    );
  };
  const _isAssignedToCurrentUser = () => {
    return _isAssignedTo(_currentUserId);
  };
  const _isCurrentlyAssignedTo = () => {
    return (
      me.data.currentlyAssignedTo === _currentUserId &&
      !!_checkCurrentUserIsParticipant()
    );
  };
  const _shouldAutoPick = () => {
    let user = getUserFromViewedUser();
    let autoPickup = user?.ap;
    // user must not be active
    if (!_currentUserStatus) {
      return false;
    }
    // TODO: ApexChat.Dashboard.AutoPickup.isEnabled() Need to implement
    // should auto pick if autopick is enabled, or the chat is transferred to user from agent (through chat transfer request)
    return (
      autoPickup &&
      (_isAssignedToCurrentUser() ||
        ((me.data.transferType === Enums.TransferChatType.AgentToOperatorWeb ||
          me.data.transferType == Enums.TransferChatType.OperatorToOperator) &&
          me.data.transferredTo === _currentUserId))
    );
  };
  const _isGenerallyAvailable = () => {
    return me.data.generallyAvailable;
  };
  const _isGenerallyAvailableToOperators = () => {
    return me.data.generallyAvailableToOperators;
  };
  const _isActive = () => {
    return me.data.status === Enums.ChatStatus.Active;
  };
  const _isOptInChat = () => {
    return me.data.status == Enums.ChatStatus.ClientHandling;
  };
  const _isInactive = () => {
    return me.data.status === Enums.ChatStatus.Inactive;
  };
  const _isAwaitingPickup = () => {
    return me.data.status === Enums.ChatStatus.AwaitingPickup;
  };
  const _isShelved = () => {
    return me.data.status === Enums.ChatStatus.Shelved;
  };
  const _isAvailable = () => {
    return (
      me.data.status === Enums.ChatStatus.AwaitingPickup ||
      me.data.pickedUpBy === 0
    );
  };
  const _hasEverBeenPickedUp = () => {
    return me.data.pickedUpBy > 0;
  };
  const _getId = () => {
    return me.data.chatId;
  };
  const _getCompanyName = () => {
    return me.data.companyName;
  };
  const _getCompanyId = () => {
    return me.data.companyId;
  };
  const _getCompanyKey = () => {
    return me.data.companyKey;
  };
  const _getReferrer = () => {
    return me.data.referrer;
  };
  const _getLocation = () => {
    return me.data.location;
  };
  const _getData = () => {
    return me.data;
  };
  const _getCurrentUserId = () => {
    return _currentUserId;
  };
  const _getPickedUpByUsername = () => {
    return me.data.pickedUpByUsername;
  };
  const _getPriority = () => {
    return me.data.priority;
  };
  const _getLanguage = () => {
    return me.data.threeLetterISOLanguageName;
  };
  const _getPickedUpOn = () => {
    return me.data.pickedUpOn;
  };
  const _getParticipantNames = () => {
    let names = me.data?.participants
      ?.map(participant => participant.username)
      .filter(Boolean);
    return names?.join();
  };
  const _getChatInitiatedBy = () => {
    let value = getEnumTextByValue(Enums.InitiatedBy, me.data.initiatedBy);
    return value;
  };

  const _getTransferType = () => {
    return me.data.transferType;
  };

  const _getIPAddress = () => {
    return me.data.ipAddress;
  };

  const _isRunByAgent = () => {
    return me.data.pickedUpBy > 0 && !me.data.pickedUpByOperator;
  };
  const _isPickedUpByOperator = () => {
    return me.data.pickedUpByOperator;
  };
  const _isRoutedToDefaultGroup = () => {
    return me.data.routedToDefaultGroup;
  };
  const _isSMSTransferred = () => {
    return me.data.transferType === Enums.TransferChatType.AgentToOperatorSMS;
  };
  const _isWebTransferred = () => {
    return me.data.transferType === Enums.TransferChatType.AgentToOperatorWeb;
  };
  const _canOpTransferChat = () => {
    return !!me.data.canOperatorsTransferChat;
  };
  return {
    data: me.data,
    isParticipant: _isParticipant,
    currentUserIsParticipant: _currentUserIsParticipant,
    isAssignedTo: _isAssignedTo,
    isAssignedToCurrentUser: _isAssignedToCurrentUser,
    isCurrentlyAssignedTo: _isCurrentlyAssignedTo,
    shouldAutoPick: _shouldAutoPick,
    isGenerallyAvailable: _isGenerallyAvailable,
    isGenerallyAvailableToOperators: _isGenerallyAvailableToOperators,
    isActive: _isActive,
    isOptInChat: _isOptInChat,
    isInactive: _isInactive,
    isAwaitingPickup: _isAwaitingPickup,
    isShelved: _isShelved,
    isAvailable: _isAvailable,
    hasEverBeenPickedUp: _hasEverBeenPickedUp,
    getId: _getId,
    getCompanyName: _getCompanyName,
    getCompanyId: _getCompanyId,
    getCompanyKey: _getCompanyKey,
    getReferrer: _getReferrer,
    getLocation: _getLocation,
    getData: _getData,
    getPriority: _getPriority,
    getLanguage: _getLanguage,
    getCurrentUserId: _getCurrentUserId,
    getPickedUpOn: _getPickedUpOn,
    getPickedUpByUsername: _getPickedUpByUsername,
    getParticipantNames: _getParticipantNames,
    getChatInitiatedBy: _getChatInitiatedBy,
    getTransferType: _getTransferType,
    getIPAddress: _getIPAddress,
    isRunByAgent: _isRunByAgent,
    isPickedUpByOperator: _isPickedUpByOperator,
    isRoutedToDefaultGroup: _isRoutedToDefaultGroup,
    isSMSTransferred: _isSMSTransferred,
    isWebTransferred: _isWebTransferred,
    canOpTransferChat: _canOpTransferChat
  };
};
export default Chatroom;

export const getRealTimeCommunicationChannels = chatId => {
  var channels = [
    '/chatroom/' + chatId,
    '/chatroom/' + chatId + '/typing',
    '/chatroom/' + chatId + '/participants',
    '/chatroom/' + chatId + '/meta',
    // '/calls/chat/' + chatId,   // we are separatly creating a listner on demand listner_call_connect_status_by_id
    '/chatroom/' + chatId + '/virtualassistant/ner',
    '/chatroom/' + chatId + '/templatedquestions/answer',
    '/chatroom/' + chatId + '/live',
    '/chatroom/' + chatId + '/realtime/text',
    '/chatroom/' + chatId + '/scriptsuggestion'
  ];
  return channels;
};

export const chatChanged = (data, suppressEvents) => {
  let chatroomByType = {
    includeInSections: [],
    excludeInSections: [],
    room: null
  };
  // wrap up the incoming data to get us some helper methods
  var room = new Chatroom(data);

  const user = JSON.parse(storage.getItem('Profile'));
  const _currentUserId = user ? user.UserID : 0; // get from localStorage profile
  const {
    roles: { roles },
    auth: { profile },
    MessagingChats: { incomingChats }
  } = store.getState();
  const messagingDashboard_isActive = profile?.Active;
  let transferedChat = false;
  // update the appropriate queue with any changes
  if (room.isActive()) {
    if (room.isAssignedToCurrentUser()) {
      if (
        roles?.isOperator &&
        (!room.isPickedUpByOperator() || !room.isCurrentlyAssignedTo())
      ) {
        // do nothing
      } else {
        // chatroomByType.type = Enums.RoomType.ActiveChat;
        if (
          !chatroomByType.includeInSections.includes(Enums.RoomType.ActiveChat)
        ) {
          chatroomByType.includeInSections.push(Enums.RoomType.ActiveChat);
        }
        // dispatch::ADD::room to myActiveChats
      }
    }
    // chatroomByType.type = Enums.RoomType.AllChat;
    if (!chatroomByType.includeInSections.includes(Enums.RoomType.AllChat)) {
      chatroomByType.includeInSections.push(Enums.RoomType.AllChat);
    }
    // dispatch::ADD::room to allActiveChats
  } else if (room.isOptInChat()) {
    if (!chatroomByType.includeInSections.includes(Enums.RoomType.ActiveChat)) {
      chatroomByType.includeInSections.push(Enums.RoomType.ActiveChat);
      chatroomByType.room = room;
      //remove from the message leads tab if present
      store.dispatch(removeFromOptInLeadsSection(room.getId()));
      return chatroomByType;
    }
  } else if (room.isAvailable()) {
    if (room.isAssignedToCurrentUser()) {
      // chatroomByType.type = Enums.RoomType.IncomingChat;
      if (
        !chatroomByType.includeInSections.includes(Enums.RoomType.IncomingChat)
      ) {
        chatroomByType.includeInSections.push(Enums.RoomType.IncomingChat);
      }
      // dispatch::ADD::room to incomingChats
    } else if (room.isGenerallyAvailable()) {
      if (
        messagingDashboard_isActive ||
        roles.isOpsManager ||
        roles.isIsrTrainer
      ) {
        if (room.isRoutedToDefaultGroup()) {
          if (roles.isUserAssignedToDefaultGroup) {
            // chatroomByType.type = Enums.RoomType.IncomingChat;
            if (
              !chatroomByType.includeInSections.includes(
                Enums.RoomType.IncomingChat
              )
            ) {
              chatroomByType.includeInSections.push(
                Enums.RoomType.IncomingChat
              );
            }
            // dispatch::ADD::room to incomingChats
          }
        } else {
          // chatroomByType.type = Enums.RoomType.IncomingChat;
          if (
            !chatroomByType.includeInSections.includes(
              Enums.RoomType.IncomingChat
            )
          ) {
            chatroomByType.includeInSections.push(Enums.RoomType.IncomingChat);
          }
          // dispatch::ADD::room to incomingChats
        }
      }
    } else if (room.isGenerallyAvailableToOperators() && roles.isOperator) {
      if (messagingDashboard_isActive) {
        // chatroomByType.type = Enums.RoomType.IncomingChat;
        if (
          !chatroomByType.includeInSections.includes(
            Enums.RoomType.IncomingChat
          )
        ) {
          chatroomByType.includeInSections.push(Enums.RoomType.IncomingChat);
        }
        // dispatch::ADD::room to incomingChats
      }
    }

    // TODO: In future, when we will implement Shelved chat, we will remove this room for shelvedChat section
    if (
      !chatroomByType.excludeInSections.includes(Enums.RoomType.ShelvedChat)
    ) {
      chatroomByType.excludeInSections.push(Enums.RoomType.ShelvedChat);
    }
    // Ext.getCmp('shelvedChats').removeChatRecord(room);
  } else if (room.isShelved() && room.isAssignedToCurrentUser()) {
    // dispatch room to allActiveChats
    // TODO: In future, dispatch::ADD::room to shelvedChats
    if (
      !chatroomByType.includeInSections.includes(Enums.RoomType.ShelvedChat)
    ) {
      chatroomByType.includeInSections.push(Enums.RoomType.ShelvedChat);
    }
    // Ext.getCmp('shelvedChats').upsertChatRecord(room);
  }

  // remove records from the appropriate queues
  if (!room.isAssignedToCurrentUser()) {
    var remove = false;
    if (roles.isAgent && !room.isGenerallyAvailable()) {
      remove = true;
    }
    if (
      roles.isOperator &&
      !room.isGenerallyAvailableToOperators() &&
      !room.isSMSTransferred()
    ) {
      remove = true;
    }

    if (remove) {
      if (
        !chatroomByType.excludeInSections.includes(Enums.RoomType.IncomingChat)
      ) {
        chatroomByType.excludeInSections.push(Enums.RoomType.IncomingChat);
      }
      //dispatch::REMOVE::room to incomingChats
    }
  }

  //Donot remove chat from incoming grid if it is transferred chat
  if (
    !room.isAvailable() &&
    room.data?.transferType !== Enums.TransferChatType.AgentToOperatorWeb
  ) {
    // not available = remove from incoming, always
    if (
      !chatroomByType.excludeInSections.includes(Enums.RoomType.IncomingChat)
    ) {
      chatroomByType.excludeInSections.push(Enums.RoomType.IncomingChat);
    }
    //dispatch::REMOVE::room to incomingChats
  }

  if (
    !room.isAssignedToCurrentUser() ||
    (roles?.isOperator && !room.isPickedUpByOperator()) ||
    (roles?.isAgent && room.isPickedUpByOperator()) ||
    (roles?.isOperator && room.data.currentlyAssignedTo !== _currentUserId)
  ) {
    // the only time when a room will not  have participants is when its a message update....
    if (room.data.participants) {
      if (
        !chatroomByType.excludeInSections.includes(Enums.RoomType.ActiveChat)
      ) {
        chatroomByType.excludeInSections.push(Enums.RoomType.ActiveChat);
      }
      //dispatch::REMOVE::room to myActiveChats
    }
  }

  if (
    roles?.isAgent &&
    room.data.transferType === Enums.TransferChatType.AgentToOperatorSMS
  ) {
    if (
      !chatroomByType.excludeInSections.includes(Enums.RoomType.IncomingChat)
    ) {
      chatroomByType.excludeInSections.push(Enums.RoomType.IncomingChat);
    }
    //dispatch::REMOVE::room to incomingChats
  }

  if (!room.isActive()) {
    if (!chatroomByType.excludeInSections.includes(Enums.RoomType.ActiveChat)) {
      chatroomByType.excludeInSections.push(Enums.RoomType.ActiveChat);
    }
    //dispatch::REMOVE::room to myActiveChats
    if (!room.isShelved()) {
      if (!chatroomByType.excludeInSections.includes(Enums.RoomType.AllChat)) {
        chatroomByType.excludeInSections.push(Enums.RoomType.AllChat);
      }
      //dispatch::REMOVE::room to allActiveChats
    }
  }
  if (
    room.data.transferType === Enums.TransferChatType.OperatorToOperator &&
    room.data.transferredTo === _currentUserId &&
    // room.data.lastUserId !== 0 &&
    room.data.lastUserId !== _currentUserId &&
    room.data.lastUserId !== Enums.ChatMessageType.System &&
    room.data.currentlyAssignedTo === _currentUserId &&
    room.data.pickedUpBy === _currentUserId &&
    room.data.status === Enums.ChatStatus.AwaitingPickup
  ) {
    let userName = room.data.lastUserId === 0 ? 'System' : room.data.lastUser;
    toast.info(
      ToastTemplate.info(`You have received a transferred chat from ${userName} with ID
    ${room.data.chatId}.`),
      { toastId: room.data.chatId }
    );
    transferedChat = true;
  }
  if (!suppressEvents) {
    if (incomingChats.length > 0 || transferedChat) {
      // ApexChat.Sound.notifyIncomingChat();
      if (room.shouldAutoPick()) {
        // delay 1s, then pick up the chat
        // setTimeout(function () {
        // after 1s, check to make sure the room is still available
        var chatId = room.getId();
        var record = transferedChat
          ? room
          : incomingChats.filter(chat => chat.getId() === chatId)[0];
        if (record) {
          // var updatedRoom = new ApexChat.Data.ChatRoom(record.data);
          // only auto-pick chats that are available (status awaiting pickup) and never picked up
          //if (updatedRoom.isAvailable() && !updatedRoom.hasEverBeenPickedUp()) {
          //var shiftFocusToNewChatroom = ApexChat.findActiveChatroom();
          //}
          // ApexChat.activateChatRoom(record.data, false, false);//, shiftFocusToNewChatroom);

          // New Code
          // Push chat into active chats room
          // chatroomByType.type = Enums.RoomType.ActiveChat;
          if (
            !chatroomByType.includeInSections.includes(
              Enums.RoomType.ActiveChat
            )
          ) {
            chatroomByType.includeInSections.push(Enums.RoomType.ActiveChat);
            let arr = [...chatroomByType.excludeInSections];
            let index = arr.findIndex(
              item => item === Enums.RoomType.ActiveChat
            );
            arr.splice(index, 1);
            chatroomByType.excludeInSections = arr;

            let payload = {
              room,
              includeInSections: chatroomByType.includeInSections,
              excludeInSections: chatroomByType.excludeInSections
            };
            // remove previous lead form data when chat autopick
            store.dispatch(resetLeadFormReason(''));
            store.dispatch(resetLeadFormUserCredentials());
            setTimeout(function () {
              store.dispatch(pushChatRoom(payload));
            }, 1000);
          }

          if (
            !chatroomByType.excludeInSections.includes(
              Enums.RoomType.IncomingChat
            )
          ) {
            chatroomByType.excludeInSections.push(Enums.RoomType.IncomingChat);
          }
          // dispatch::ADD::room to myActiveChats
          //  New Code End
          //Remove chats from incoming grid if chat is transfer type and is active
          // if (room.data.transferredTo !== 0) {
          //   // not available = remove from incoming, always
          //   // Ext.getCmp('incomingChats').removeChatRecord(room);

          //   if (
          //     !chatroomByType.excludeInSections.includes(
          //       Enums.RoomType.IncomingChat
          //     )
          //   ) {
          //     chatroomByType.excludeInSections.push(
          //       Enums.RoomType.IncomingChat
          //     );
          //     // remove chat from incoming section directly For testing purpose
          //     // Issue: after setTimeout it is not dispatching pushChatRoom again
          //     store.dispatch(
          //       removeFromIncomingSection(chatroomByType.room.getId())
          //     );
          //   // dispatch::Remove::room from incoming section
          // }

          // Note: added chatId to GlobalSelectedId in case of autopick chat
          // which is being used in onMessageArrived function
          window.GlobalSelectedId = chatId;
          // add chatId in chats stack
          store.dispatch(setChatStack(chatId));
          // add chat into selected chat state in order to open it's detail view
          store.dispatch(setSelectedChat(record));
        }
        // }, 1000);
      }
      // ApexChat.Notifications.notifyIncomingChat(room);
      // ApexChat.Title.flash();
    }
    // else {
    //     ApexChat.Sound.stopNotifyIncomingChat();
    //     ApexChat.Title.stopFlashing();
    // }
  }

  chatroomByType.room = room;

  // if in exclude remove from include
  if (chatroomByType.excludeInSections.length) {
    chatroomByType.includeInSections = chatroomByType.includeInSections.filter(
      item => !chatroomByType.excludeInSections.includes(item)
    );
  }

  return chatroomByType;
  // TODO: suppressEvents work is remaining..
};
