import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { truncateText } from 'services/utils/generic.methods';
import renderToolTip from 'pub-components/ui-controls/renderToolTip';

const TruncateWithToolTip = ({
  limit,
  showInnerHtml = false,
  tooltip = true,
  text = '',
  truncateEnabled = true,
  ...rest
}) => {
  return (
    <OverlayTrigger
      placement="auto"
      overlay={renderToolTip(text)}
      show={tooltip ? undefined : false}
    >
      {showInnerHtml ? (
        <span {...rest}></span>
      ) : (
        <span {...rest}>
          {truncateEnabled ? truncateText(text, limit) : text}
        </span>
      )}
    </OverlayTrigger>
  );
};
export default TruncateWithToolTip;
