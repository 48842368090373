import React from 'react';
import PropTypes from 'prop-types';
import Circular from './loader-assets/circular-svg';
import RippleGif from './loader-assets/ripple-gif';
import { Loading_enums } from 'services/dropdown-enums';
const SplashLoader = props => {
  const { color, size, type, ...otherProps } = props;
  const LoaderProvider = type => {
    switch (type) {
      case Loading_enums.Circular:
        return Circular(size);
      case Loading_enums.RippleGif:
        return RippleGif;
    }
  };
  return LoaderProvider(type);
};

SplashLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SplashLoader.defaultProps = {
  color: 'currentColor',
  size: '200'
};

export default SplashLoader;
