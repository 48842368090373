export const en_trackingIntegration = {
  heading1: 'Tracking',
  heading2: 'Google Analytics Tracking',
  heading3: 'Facebook Pixel Tracking',
  heading4: 'Custom Tracking',
  heading5: 'Google Analytics 4',
  googleAnalyticsAccount: {
    label: 'Google Analytics Account',
    info: 'The Google Analytics Property ID where you want to recieve events.'
  },
  googleAnalyticsDomain: {
    label: 'Google Analytics Domain',
    info: 'The domain name associated with the Google Analytics property.'
  },
  clientSideTracking: {
    label: 'Client Side Tracking',
    info: "Trigger google analytics events from the end user's side."
  },
  googleAnalyticsTrackingWithClientId: {
    label: 'Google Analytics Tracking With Client Id',
    info: 'Send chat and lead events using the client Id method.'
  },
  googleAnalyticsCrossDomainTracking: {
    label: 'Google Analytics Cross Domain Tracking',
    info: 'Send chat and lead events using the cross domain tracking method.'
  },
  dashboardTracking: {
    label: 'Dashboard Tracking',
    info: 'Trigger google analytics events from the server side.'
  },
  salesLeadType: {
    label: 'Sales Lead Type',
    info: 'Send sales lead events.'
  },
  serviceLeadType: {
    label: 'Service Lead Type',
    info: 'Send service lead events.'
  },
  otherLeadType: {
    label: 'Other Lead Type',
    info: 'Send other lead events.'
  },
  chatStartNotification: {
    label: 'Chat Start Notification',
    info: 'Send chat start events.'
  },
  accountCode: {
    label: 'Account Code',
    info: 'The account code associated with your facebook pixel.'
  },
  measurementId: {
    label: 'Measurement Id',
    info: 'The measurement id associated with your Google analytics 4.'
  },
  analyticsTrackingCodeForChats: {
    label: 'Analytics Tracking Code For Chats',
    info: 'JavaScript use to trigger events when a chat is started.'
  },
  analyticsTrackingCodeForLeads: {
    label: 'Analytics Tracking Code For Leads',
    info: 'JavaScript use to trigger events when a lead is converted.'
  }
};
export default en_trackingIntegration;
export { default as fr_trackingIntegration } from '../fr-FR-Dictionary/fr-trackingIntegration';
export { default as sp_trackingIntegration } from '../sp-SP-Dictionary/sp-trackingIntegration';
