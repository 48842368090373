export const fr_leadEmails = {
  heading1: 'Les emails de plomb',
  leadEmailAddresses: {
    label: 'Adresses Emails plomb',
    info: 'Un ou plusieurs adresses emails qui recevront chaque fil HTML.Exemple: abc@exemple.com,xyz@exemple.com'
  },
  chatTranscriptEmailAddresses: {
    label: 'Transcription discuter des adresses email',
    info: 'Un ou plusieurs adresses emails qui recevront chacun relevés de notes de chat (non-leads).Exemple: abc@exemple.com,xyz@exemple.com'
  },
  classifyChatsAsSpam: {
    label: 'Classifier comme spam Causeries',
    info: "Si ensemble, l'entreprise va recevoir des pistes pour chaque conversation (non) conduit."
  },
  verified: {
    label: 'Vérifié',
    info: "Les adresses email de plomb qui ont été envoyés un verificaiton email pour confirmer qu'ils sont des emails recieiving. Cela ne peut être réglé en cliquant sur le lien dans l'email de vérification."
  },
  verificationEmailSent: {
    label: "L'email de vérification a été envoyé",
    info: "Si oui ou non l'adresse email Lead a un message de vérification envoyé à ce."
  },
  hasInvalidEmailAddresses: {
    label: 'A invalides adresses email',
    info: 'Ce drapeau sera activé si le compte a des adresses emails non valides qui ne sont pas recieiving les fils HTML dans le champ Adresse email Lead.'
  },
  invalidLeadEmailAddresses: {
    label: 'Adresses email non valide Lead',
    info: "Cela permet d'afficher toutes les adresses email invalides qui ont été signalés par nos serveurs de messagerie."
  },
  companyType: {
    label: 'Type de compagnie',
    info: 'Réglez cette option pour activer montrant des champs de concession dans le tableau de bord.'
  },
  secureChats: {
    label: 'Causeries sécurité',
    info: 'Si ensemble, chats forces pour exécuter sur SSL.'
  },
  secureLeads: {
    label: 'Leads sécurisés',
    info: 'Si ensemble, les notifications de plomb sont démontées et contiennent un lien qui nécessite une connexion.'
  }
};
export default fr_leadEmails;
