const { FieldsEnum, Enums } = require('services/dropdown-enums');

export const RemoveCharAndToCamelCase = (text = '', character = '_') => {
  let key = text.split(character).join('');
  key = key.charAt(0).toLocaleLowerCase() + key.slice(1);
  return key;
};

export const ReportTypes = relationship => {
  const filterFields = fields => {
    let removeItems = [
      FieldsEnum.Company_Keys,
      FieldsEnum.Hosts,
      FieldsEnum.Picked_Up_By_Username
    ];
    if (relationship && relationship === Enums.Relationship.Customer) {
      return fields.filter(f => !removeItems.includes(f));
    } else {
      return fields;
    }
  };

  return [
    {
      id: Enums.ReportType.Traffic_Report_By_Date,
      name: 'Traffic Report By Date',
      description:
        'Report of traffic, service leads, billable leads and other leads by company',
      codeName: 'trafficbydate',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Group_By_Company,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Traffic_Summary,
      name: 'Traffic Summary',
      description: 'Traffic Summary',
      codeName: 'trafficsummary',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Leads,
      name: 'Leads',
      description:
        'Shows all the lead details with the ability to request Lead Type change',
      codeName: 'leads',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Billable_Leads,
      name: 'Billable Leads',
      description:
        'Shows a total count of billable leads and a break up of whether they were sent by agents ot operators',
      codeName: 'billableleads',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Group,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Transcripts,
      name: 'Transcripts',
      description: 'Transcripts for past chats with chat details',
      codeName: 'transcripts',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Picked_Up_By_Username,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Stats,
      name: 'Stats',
      description:
        'System statistics regarding chats and agents for capacity planning',
      codeName: 'stats',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Start_Date,
        FieldsEnum.End_Date,
        FieldsEnum.Aggregate,
        FieldsEnum.Group
      ])
    },
    {
      id: Enums.ReportType.Calls_Summary,
      name: 'Calls Summary',
      description:
        'Shows the number of calls attempted and successful along with chats and leads',
      codeName: 'callssummary',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Leads_Delay,
      name: 'Leads Delay',
      description:
        'Time Taken for Sending a Lead where delay is greater than 15 minutes',
      codeName: 'leadsdelay',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Company_Keys
      ])
    },
    {
      id: Enums.ReportType.Agent_Response_Time,
      name: 'Agent Response Time',
      description: 'Shows the average response time during a chat',
      codeName: 'agentresponsetime',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Period,
        FieldsEnum.Agent_Id
      ])
    },
    {
      id: Enums.ReportType.Summary_Response_Time,
      name: 'Summary Response Time',
      description:
        'Summarizes the average and maximum response times for all agents over their chats',
      codeName: 'summaryagentresponsetime',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Agent_Operator_Filter,
        FieldsEnum.Company_Keys
      ])
    },
    {
      id: Enums.ReportType.Review_Ratings,
      name: 'Review Ratings',
      description: 'Average of review ratings grouped by agent',
      codeName: 'reviewratings',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Period
      ])
    },
    {
      id: Enums.ReportType.High_Volume_Accounts,
      name: 'High Volume Accounts',
      description: 'Report of high volume accounts',
      codeName: 'highvolumeaccounts',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Paused_Companies,
      name: 'Paused Companies',
      description:
        'Report of paused companies with budget details and the date they were paused',
      codeName: 'pausedcompanies',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Often_Over_Budget_Companies,
      name: 'Often Over Budget Companies',
      description:
        'Report of companies that exceed a certain number of times over budget; date filters by the last time the company was paused',
      codeName: 'oftenoverbudgetcompanies',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Limit,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Nearly_Over_Budget_Companies,
      name: 'Nearly Over Budget Companies',
      description: 'Report of companies by percentage of budget used',
      codeName: 'nearlyoverbudgetcompanies',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Minimum,
        FieldsEnum.Maximum,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Invalid_Email_Addresses,
      name: 'Invalid Email Addresses',
      description: 'Report of companies with invalid emails',
      codeName: 'invalidemailaddresses',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Performance,
      name: 'Performance',
      description: 'Details of Agent performance statistics',
      codeName: 'agentperformance',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Agent_Operator_Filter,
        FieldsEnum.Company_Keys
      ])
    },
    {
      id: Enums.ReportType.Profile_Response,
      name: 'Profile Response',
      description:
        'Report on chats/leads based on company profiles and appearances',
      codeName: 'profileResponse',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Group,
        FieldsEnum.Company_Keys
      ])
    },
    {
      id: Enums.ReportType.Active_Companies_With_Low_Traffic,
      name: 'Active Companies With Low Traffic',
      description:
        'Summary For Top 50 Active Companies With Low Traffic Created in the Last Six Months',
      codeName: 'activeCompaniesWithLowTraffic',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Account_Companies_Without_Pricing,
      name: 'Account Companies Without Pricing',
      description:
        'Report on companies which belong to accounts but have no pricing',
      codeName: 'accountCompaniesWithoutPricing',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Non_Account_Companies_Without_Pricing,
      name: 'Non Account Companies Without Pricing',
      description:
        'Report on companies which do not belong to accounts and have no pricing',
      codeName: 'nonAccountCompaniesWithoutPricing',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Active_Companies_Without_Invitation_Code,
      name: 'Active Companies Without Invitation Code',
      description:
        'Report on active companies which do not have invitation code installed',
      codeName: 'activeCompaniesWithoutInvitationCode',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Inactive_Companies_With_Invitation_Code,
      name: 'Inactive Companies With Invitation Code',
      description:
        'Report on inactive companies which have the invitation code',
      codeName: 'inactiveCompaniesWithInvitationCode',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Companies_With_Traffic_Pacing,
      name: 'Companies With Traffic Pacing',
      description: 'Companies with traffic pacing',
      codeName: 'trafficpacing',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Companies_With_Billing_Amount,
      name: 'Companies With Billing Amount',
      description: 'Companies With Billing Amount',
      codeName: 'companiesWithBillingAmount',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Invitation_Code_Installed_Report,
      name: 'Invitation Code Installed Report',
      description:
        'Companies that have Installed Invitation Code since the last visit',
      codeName: 'invitationCodeInstalled',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Start_Date
      ])
    },
    {
      id: Enums.ReportType.Monthly_Minimum_Report,
      name: 'Monthly Minimum Report',
      description: 'Extract the monthly minimum value of all active companies',
      codeName: 'monthlyMinimum',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Add_Report_Attachment
      ])
    },
    {
      id: Enums.ReportType.Agent_Response_Time_By_Threshold,
      name: 'Agent Response Time By Threshold',
      description: 'Agent Response Time By Threshold',
      codeName: 'agentresponsetimebythreshold',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Period,
        FieldsEnum.Threshold
      ])
    },
    {
      id: Enums.ReportType.De_activated_accounts,
      name: 'De-activated accounts',
      description: 'Report to determine which accounts have been de-activated',
      codeName: 'DeactivatedAccounts',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Hosts
      ])
    },
    {
      id: Enums.ReportType.Facebook_Usage,
      name: 'Facebook Usage',
      description: 'Report to get facebook usage data by company',
      codeName: 'facebookUsageReport',
      allowedFields: filterFields([
        FieldsEnum.Name,
        FieldsEnum.Email,
        FieldsEnum.Webhook,
        FieldsEnum.Add_Report_Attachment,
        FieldsEnum.Time_zone,
        FieldsEnum.Period,
        FieldsEnum.Company_Keys,
        FieldsEnum.Hosts
      ])
    }
  ];
};

export const PeriodOptions = [
  {
    value: '',
    name: 'Day',
    nestedOptions: [
      { value: 'C-1/C/C,C-1/C/C', name: 'Previous Day' },
      { value: 'C/C/C,C/C/C', name: 'Current Day' },
      { value: 'C+1/C/C,C+1/C/C', name: 'Next Day' }
    ]
  },
  {
    value: '',
    name: 'Week',
    nestedOptions: [
      { value: 'FW-7/C/C,LW-7/C/C', name: 'Previous Week' },
      { value: 'FW/C/C,LW/C/C', name: 'Current Week' },
      { value: 'FW+7/C/C,LW+7/C/C', name: 'Next Week' },
      { value: 'C-7/C/C,C/C/C', name: 'Last 7 Days' },
      { value: 'FW/C/C,C/C/C', name: 'Week to Date' }
    ]
  },
  {
    value: '',
    name: 'Month',
    nestedOptions: [
      { value: 'FM/C-1/C,LM/C-1/C', name: 'Previous Month' },
      { value: 'FM/C/C,LM/C/C', name: 'Current Month' },
      { value: 'FM/C+1/C,LM/C+1/C', name: 'Next Month' },
      { value: 'C-30/C/C,C/C/C', name: 'Last 30 Days' },
      { value: 'FM/C/C,C/C/C', name: 'Month to Date' }
    ]
  },
  {
    value: '',
    name: 'Year',
    nestedOptions: [
      { value: 'FM/FY/C-1,LM/LY/C-1', name: 'Previous Year' },
      { value: 'FM/FY/C,LM/LY/C', name: 'Current Year' },
      { value: 'FM/FY/C+1,LM/LY/C+1', name: 'Next Year' },
      { value: 'C-365/C/C,C/C/C', name: 'Last 365 Days' },
      { value: 'FM/FY/C,C/C/C', name: 'Year to Date' }
    ]
  },
  {
    value: 'date_range',
    name: 'Date Range'
  },
  {
    value: 'custom',
    name: 'Custom'
  }
];

export const AgentOperatorOption = [
  { id: 2, name: 'Agent' },
  { id: 3, name: 'Operator' }
];

export const ReportNotes = {
  trafficReportByDate: 'company.reportDetails.note',
  trafficSummary: 'company.reportDetails.note',
  leads: 'company.reportDetails.note',
  billableLeads: 'company.reportDetails.note',
  transcripts: 'company.reportDetails.note',
  stats: 'company.reportDetails.note',
  callsSummary: 'company.reportDetails.note',
  leadsDelay: 'company.reportDetails.note',
  agentResponseTime: 'company.reportDetails.note',
  summaryResponseTime: 'company.reportDetails.note',
  reviewRatings: 'company.reportDetails.note',
  highVolumeAccounts: 'company.reportDetails.note',
  pausedCompanies: `Note Dates start at 12:00 in the morning in the selected timezone and end at midnight the same day. To get the results for a specific day, use that day as both the start and end date.
  Pacing periods are typically 24 hours but can be changed by company, totals are for the company's budgeting period.
  Paused and resumed values reference the last paused date and last resumed date.`,
  oftenOverBudgetCompanies: `Note Dates start at 12:00 in the morning in the selected timezone and end at midnight the same day. To get the results for a specific day, use that day as both the start and end date
  The "Pause Count" is the number of times the company has been paused because the budget has been hit or exceeded for a given pacing sub-period (usually 24 hours).`,
  nearlyOverBudgetCompanies:
    'Note Budget percentages are inclusive and refer to the current company pacing cycle only.',
  invalidEmailAddresses:
    'Note By default, all hosts and companies are included',
  performance: 'company.reportDetails.note',
  profileResponse: 'company.reportDetails.note',
  activeCompaniesWithLowTraffic:
    'Note This report automatically covers 6 months of data',
  accountCompaniesWithoutPricing: 'company.reportDetails.note',
  nonAccountCompaniesWithoutPricing: 'company.reportDetails.note',
  activeCompaniesWithoutInvitationCode: 'company.reportDetails.note',
  inactiveCompaniesWithInvitationCode: 'company.reportDetails.note',
  companiesWithTrafficPacing:
    'Note By default, all hosts and companies are included',
  companiesWithBillingAmount: 'company.reportDetails.note',
  invitationCodeInstalledReport: 'company.reportDetails.note',
  monthlyMinimumReport: 'company.reportDetails.note',
  agentResponseTimeByThreshold: 'company.reportDetails.note',
  'de-activatedaccounts': 'company.reportDetails.note',
  facebookUsage: 'company.reportDetails.note'
};
