export const fr_trackingIntegration = {
  heading1: 'Suivi',
  heading2: 'Suivi Google Analytics',
  heading3: 'Suivi des pixels Facebook',
  heading4: 'Suivi personnalisé',
  heading5: 'Google Analytics 4',
  googleAnalyticsAccount: {
    label: 'Compte Google Analytics',
    info: "L'ID de Propriété Google Analytics où vous souhaitez recevoir des événements."
  },
  googleAnalyticsDomain: {
    label: 'Domaine Google Analytics',
    info: 'Ce nom de domaine associé à la propriété Google Analytics.'
  },
  clientSideTracking: {
    label: 'Suivi côté client',
    info: "Les événements de déclenchement google du côté de l'utilisateur final."
  },
  googleAnalyticsTrackingWithClientId: {
    label: 'Google Analytics Tracking Avec Id client',
    info: 'Envoyer le chat et les événements principaux du client en utilisant la méthode Id.'
  },
  googleAnalyticsCrossDomainTracking: {
    label: 'Suivi de plusieurs domaines Google Analytics',
    info: 'Envoyer le chat et les événements principaux en utilisant la méthode de suivi de plusieurs domaines.'
  },
  dashboardTracking: {
    label: 'Tableau de bord de suivi',
    info: 'Les événements de déclenchement google du côté serveur.'
  },
  salesLeadType: {
    label: 'Sales Lead Type',
    info: 'Plomb service Envoyer des événements.'
  },
  serviceLeadType: {
    label: 'Service Lead Type',
    info: 'Envoyer ventes donnent lieu des événements.'
  },
  otherLeadType: {
    label: 'Other Lead Type',
    info: "Envoyer d'autres événements de plomb."
  },
  chatStartNotification: {
    label: 'Chat Start Notification',
    info: 'Envoyer événements Lancer le chat.'
  },
  accountCode: {
    label: 'Code de compte',
    info: 'Le code de compte associé à votre pixel facebook.'
  },
  measurementId: {
    label: 'Identifiant de mesure',
    info: "L'identifiant de mesure associé à votre Google Analytics 4."
  },
  analyticsTrackingCodeForChats: {
    label: 'Analytics code de suivi pour Causeries',
    info: 'JavaScript pour utiliser les événements de déclenchement quand une conversation démarre.'
  },
  analyticsTrackingCodeForLeads: {
    label: 'Code de suivi pour les prospects',
    info: "JavaScript pour utiliser les événements de déclenchement lorsqu'un plomb est converti."
  }
};
export default fr_trackingIntegration;
