import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import { lazy } from 'react';

const SettingsCompanies = lazy(() => import('pages/portal/settings'));
const Operations = lazy(() => import('pages/portal/operations'));
const Home = lazy(() => import('pages/portal/home'));
const generalRoutes = [
  {
    path: '/portal/settings',
    name: 'settings',
    title: 'Settings',
    component: props => <SettingsCompanies {...props} />,
    icon: <UilSetting />,
    exact: true
  },
  {
    path: '/home',
    name: 'home',
    title: 'Home',
    component: props => <Home {...props} />,
    exact: true
  },
  {
    path: '/portal/operations',
    name: 'operations',
    title: 'Operations',
    component: props => <Operations {...props} />,
    exact: true
  }
];

export default generalRoutes;
