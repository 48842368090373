import React from 'react';
import LeftColumn from 'pub-components/Auth/LeftColumn';

const Pages = props => {
  return (
    <div className="main-bg">
      <div className="bg-theme-wave-container"></div>
      <div className="login-form-container">
        <div className="login-form-wrapper">
          <LeftColumn />
          {props.childComp({ ...props })}
        </div>
      </div>
    </div>
  );
};

export default Pages;
