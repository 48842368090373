export const fr_mobileSettings = {
  heading1: 'Mobile',
  enableLive: {
    label: 'Activer en direct',
    info: "Si elle est activée, les agents et les opérateurs seront en mesure de transférer l'utilisateur à une session Live."
  },
  recordLiveSessions: {
    label: 'Enregistrement Live Sessions',
    info: 'Si elle est définie, des sessions en direct seront enregistrées pour examen ultérieur.'
  },
  videoCallTimeout: {
    label: "Call Délai d'attente (secondes)",
    info: "Nombre de secondes d'attente avant la fin de l'appel vidéo."
  },
  mobileSuggestions: {
    label: 'Suggestions mobiles',
    info: 'Le script associé à la société'
  },
  workflow: {
    label: 'Flux de travail',
    info: 'Détermine flux de travail sera utilisé pour trouver des agents en direct.'
  },
  note: {
    label:
      'Note: Un thème skinnable doit être utilisé avec Live afin de fonctionner correctement'
  }
};
export default fr_mobileSettings;
