import Dexie from 'dexie';
import moment from 'moment';
import { getCookie, setCookie } from 'services/utils/generic.methods';

export const db = () => {
  let _apexChatDb = null;
  let _dbName = 'ApexChat_';
  const generateQuickGuid = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  if (!window.localStorage.Profile) {
    var dbName = getCookie('dbName');
    if (dbName) {
      _dbName = dbName;
    } else {
      _dbName += generateQuickGuid();
      setCookie('dbName', _dbName, 1);
    }
  } else {
    var profileData = JSON.parse(window.localStorage.Profile);
    _dbName += profileData.UserID + '_' + profileData.CompanyID;
  }
  const init = () => {
    _apexChatDb = new Dexie(_dbName).version(3).stores({
      optInLeads: '++id, chatId, contactId, leadId, jsonData',
      activeChats: '++id, chatId, jsonData',
      allChats: '++id, chatId, jsonData',
      chatLogs: '++id, chatId, chatLogId, messageJson'
    });

    // Define a migration to add the 'createdOn' index
    _apexChatDb.db
      .version(4)
      .stores({
        chatLogs: '++id, chatId, chatLogId, createdOn, messageJson'
      })
      .upgrade(async trans => {
        // If you have existing data, you may need to populate the 'createdOn' field
        await trans.chatLogs.toCollection().modify(log => {
          // Set 'createdOn' to some value based on your data
          log.createdOn = moment().toDate();
        });
      });
  };

  const _chatDbService = table => {
    const _getAll = async () => {
      return await table.toArray();
    };
    const _getByKey = async (key, value) => {
      return await table.where(key).equals(value).toArray();
    };

    const _insertRow = async (chatId, data) => {
      var exists = await _getByKey('chatId', chatId);
      if (!exists || !exists.length) {
        await table.add(data);
      }
    };
    const _removeRow = async chatId => {
      var record = await _getByKey('chatId', chatId);
      if (record && record.length) {
        await table.delete(record[0].id);
      }
    };

    const _updateColumnValue = async (chatId, obj) => {
      var record = await table.where('chatId').equals(chatId);
      if (record) {
        await record.modify(obj);
      }
    };
    const _updateColumnValueByKey = async (key, id, obj) => {
      if (id) {
        var record = await table.where(key).equals(id);
        if (record) {
          await record.modify(obj);
        }
      }
    };

    return {
      getAll: _getAll,
      getByKey: _getByKey,
      insertRow: _insertRow,
      removeRow: _removeRow,
      updateChatColumn: _updateColumnValue,
      updateColumnByKey: _updateColumnValueByKey
    };
  };

  const _chatLogsDbService = () => {
    var table = _apexChatDb.db.chatLogs;
    const _getChatLogs = async chatId => {
      return await table.where('chatId').equals(chatId).toArray();
    };
    const _insertLog = async (chatId, data) => {
      var exists = await table.where('chatLogId').equals(data.id).toArray();
      if (!exists || !exists.length) {
        await table.add({
          chatId: data.chatId,
          chatLogId: data.id,
          createdOn: data?.createdOn,
          messageJson: data
        });
      }
    };
    const _markLogsAsRead = async chatId => {
      await table
        .where('chatId')
        .equals(chatId)
        .modify(t => {
          t.messages?.forEach(x => {
            x.isUnread = false;
          });
        });
    };

    const _removeOldLogs = async days => {
      let newDate = moment().subtract(days, 'days').toDate();
      try {
        var logs = await table.where('createdOn').below(newDate).toArray();
        if (logs.length) {
          // filtered out logs with 'unique' chatId
          let tempLogs = logs.filter((item, index, context) => {
            return context.findIndex(i => i.chatId === item.chatId) === index;
          });

          if (tempLogs.length) {
            // delete all chat logs related to chatId inside tempLogs
            var allLogs = await tempLogs.map(
              async log =>
                await table.where('chatId').equals(log?.chatId).delete()
            );
            Promise.all(allLogs).then(res => {
              console.log('all logs deleted', res);
            });
          }
        }
        return true;
      } catch (error) {
        console.log('error deleting chatlogs', error);
        return false;
      }
    };

    return {
      getAllLogs: _getChatLogs,
      insertChatLog: _insertLog,
      markChatAsRead: _markLogsAsRead,
      removeOldLogs: _removeOldLogs
    };
  };
  const _deleteOnChatEnd = async chatId => {
    var exists = await _apexChatDb.db.activeChats
      .where('chatId')
      .equals(chatId)
      .toArray();
    if (!exists || !exists.length) {
      await _apexChatDb.db.activeChats.delete(exists[0].id);
    }
    exists = await _apexChatDb.db.allChats
      .where('chatId')
      .equals(chatId)
      .toArray();
    if (!exists || !exists.length) {
      await _apexChatDb.db.allChats.delete(exists[0].id);
    }
  };

  init();
  return {
    apexChatDb: _apexChatDb.db,
    optInLeadsDbService: _chatDbService(_apexChatDb.db.optInLeads),
    activeChatsDbService: _chatDbService(_apexChatDb.db.activeChats),
    allChatsDbService: _chatDbService(_apexChatDb.db.allChats),
    chatLogsDbService: _chatLogsDbService(),
    deleteChatOnEnd: _deleteOnChatEnd
  };
};
