export const en_basicDetails = {
  heading1: 'Basic Details',
  heading2: 'Primary details',
  heading3: 'Language Support',
  companyId: {
    label: 'Company ID',
    info: 'The Company ID is a numeric number to reference for when the account was created in our system'
  },
  hostName: {
    label: 'Host',
    info: 'The name of the reseller the account is under.'
  },
  relationship: {
    label: 'Relationship',
    info: 'This indicates the type of account (partner or customer).'
  },
  CDN: {
    label: 'CDN',
    info: 'This option enabled you to load the chat code from a CDN instead of our servers.'
  },
  website: {
    label: 'Website',
    info: 'The domain name associated with this account.'
  },
  languages: {
    label: 'Languages',
    info: 'Supported languages by this company'
  }
};
export default en_basicDetails;
export { default as fr_basicDetails } from '../fr-FR-Dictionary/fr-basicDetails';
export { default as sp_basicDetails } from '../sp-SP-Dictionary/sp-basicDetails';
