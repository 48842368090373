import React from 'react';
import Button from 'react-bootstrap/Button';
import {
  UilCheckCircle,
  UilClock,
  UilQuestionCircle,
  UilHourglass,
  UilInfoCircle
} from '@iconscout/react-unicons';

const MyReportStatus = ({ value }) => {
  const getButtonValue = statusId => {
    if (statusId === 0) {
      return <span>Queued</span>;
    } else if (statusId === 1) {
      return <span>Running</span>;
    } else if (statusId === 2) {
      return <span>Completed</span>;
    } else if (statusId === 3) {
      return <span>Completed Without Data</span>;
    } else if (statusId === 4) {
      return <span>Failed</span>;
    }
  };
  const getClass = statusCode => {
    if (statusCode === 0) {
      return 'text-warning';
    } else if (statusCode === 1) {
      return 'text-primary';
    } else if (statusCode === 2) {
      return 'text-success';
    } else if (statusCode === 3) {
      return 'text-success-2';
    } else if (statusCode === 4) {
      return 'text-danger';
    }
  };

  return <span className={getClass(value)}>{getButtonValue(value)}</span>;
};

export default MyReportStatus;
