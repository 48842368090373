import React from 'react';
import { ActiveLink } from 'pub-components/link/Link';

const SubMenu = props => {
  let subNav = props.item.subNav;
  return (
    <>
      <ul>
        {subNav.map(({ links }, key) => {
          return links.map((link, index) => {
            return (
              <li key={'sublink-' + key + index}>
                <ActiveLink activeClassName="active-link" href={link.path}>
                  <span className="collapsable-link">
                    {link.icon}
                    <span>{link.name}</span>
                  </span>
                </ActiveLink>
              </li>
            );
          });
        })}
      </ul>
    </>
  );
};

export default SubMenu;
