import { AppMetaProvider } from 'pub-components/Sidebar/helper';
import config from 'config';
import {
  addSubdomainInURL,
  ToastTemplate
} from 'services/utils/generic.methods';
import storage from 'services/utils/storage';
import { toast } from 'react-toastify';

const CRMflows_Redirection = (arg = {}) => {
  const { subDomain = '', profile, shouldOpenInSameWindow = false } = arg;
  const user = profile || JSON.parse(storage.getItem('Profile'));
  if (!!user.RefreshToken) {
    let host = window.location.host.replace('my.', 'crm.');
    host = !host.startsWith('https://') ? 'https://' + host : host;
    // Add subdomain "crm." before host url in case of Production/Beta Only
    if (config.environment === 'production' || config.environment === 'beta') {
      host = addSubdomainInURL(
        (user && user.HostDomain) || config.portalURL,
        'crm'
      );
    }

    let redirectionWithToken =
      `${host}/authorize?` +
      `sso=${user.RefreshToken}` +
      `&companyKey=${user.CompanyKey}`;
    // redirect to another link
    toast.success(ToastTemplate.success('Redirecting to the CRM...'));
    window.setTimeout(() => {
      window.location.assign(redirectionWithToken);
    }, 2000);
    // if (shouldOpenInSameWindow) {
    //   toast.success(ToastTemplate.success('Redirecting to the CRM...'));
    //   window.setTimeout(() => {
    //     window.location.assign(redirectionWithToken);
    //   }, 2000);
    // } else {
    //   window.open(redirectionWithToken, `_newtab_`);
    // }
  }
};
export default CRMflows_Redirection;
