export const fr_general = {
  saveTxt: 'Sauvegarder',
  editTxt: 'Éditer',
  uploadAttachments: 'Charger des pièces jointes',
  generalTxt: 'Général',
  analyticsInsights: 'Informations analytiques',
  detailView: 'vue détaillée',
  menuItem1: 'Détails de base',
  menuItem2: 'Coordonnées',
  menuItem3: 'Les emails de plomb',
  menuItem4: "Heure d'appel/connexion",
  menuItem5: 'Utilisateurs',
  budgetTxt: 'Budget',
  menuItem6: 'Pacing Pistes et trafic',
  menuItem11: 'Histoire',
  settingTxt: 'Paramètres',
  menuItem7: 'Mobile',
  integrationTxt: 'Intégrations',
  menuItem8: 'CRM',
  menuItem9: 'Facebook',
  menuItem10: 'Suivi',
  activeText: 'Actif',
  inActiveText: 'Inactif',
  goToOnboarding: "Aller à l'intégration",
  provisioningNote:
    "Pour apporter des modifications à ces données, veuillez vous rendre sur l'ancien portail.",
  provisioningErrorMsg:
    'Il y a quelques erreurs dans le formulaire. Veuillez les corriger avant de soumettre.',
  portallang: 'Langue du portail',
  profile: 'Profil',
  changePass: 'Changer le mot de passe',
  logout: 'Se déconnecter',
  messagingTag: 'Pour consulter le portail de messagerie.',
  oldPortalTag:
    'Pour toute visite de la fonctionnalité du portail existant pour Onboarding.',
  clickBtn: 'Cliquez ici',
  portalBtn: 'Portail existant'
};
export default fr_general;
