import React from 'react';
import UilSearchMinus from '@iconscout/react-unicons/icons/uil-search-minus';
import UilSearchPlus from '@iconscout/react-unicons/icons/uil-search-plus';
import { Flip, Refresh } from '@material-ui/icons';
import { Slider } from '@material-ui/core';
import { Button } from 'react-bootstrap';

function CropperControls({ zoom, setZoom, handleRotate, flipImage }) {
  return (
    <div className={'sliderContainer'}>
      <Button
        type="button"
        variant="outline-primary"
        className="btn-md"
        onClick={() => zoom > 1 && setZoom(zoom - 0.3)}
      >
        <UilSearchMinus size={24} />
      </Button>
      <Slider
        value={zoom}
        min={1}
        max={3}
        step={0.1}
        aria-labelledby="Zoom"
        className={'sliderC'}
        onChange={(e, zoom) => setZoom(zoom)}
      />
      <Button
        type="button"
        variant="outline-primary"
        className="btn-md"
        onClick={() => zoom < 3 && setZoom(zoom + 0.3)}
      >
        <UilSearchPlus size={24} />
      </Button>
      <Button
        type="button"
        variant="outline-primary"
        className="btn-md ml-1"
        onClick={handleRotate}
      >
        <Refresh />
      </Button>
      <Button
        type="button"
        variant="outline-primary"
        className="btn-md ml-1"
        onClick={flipImage}
      >
        <Flip />
      </Button>
    </div>
  );
}

export default CropperControls;
