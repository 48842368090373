import { Enums, enum_InitiatedBy } from 'services/dropdown-enums';
import { camelCaseStringToNormalText } from 'services/utils/generic.methods';
import { getPlainStringFromHTMLElementAsString } from 'services/utils/validate';
import store from 'store';

/**
 * @name @getSelectedText
 * @description get highlighted text
 * @requires string => optional and toTitleCase function
 * @returns highlighted text
 * */
export const getHighlightedText = (str = '') => {
  let highlightedText = '';

  if (window.getSelection) {
    highlightedText = window.getSelection()?.toString();
  } else if (document.getSelection) {
    highlightedText = document.getSelection()?.toString();
  } else if (document.selection) {
    highlightedText = document.selection.createRange().text;
  }
  if (!highlightedText?.trim()) {
    highlightedText = str;
  } else {
    highlightedText = highlightedText.trim();
  }
  return toTitleCase(highlightedText);
};

/**
 * @name @toTitleCase
 * @description string to title case
 * @requires phrase string
 * @returns title case text
 * */
export const toTitleCase = phrase => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * @name @copyToClipboard
 * @description copy string to clipboard
 * @requires phrase string
 * @returns N/A
 * */

export const copyToClipboard = phrase => {
  if (typeof phrase === 'string') {
    navigator.clipboard.writeText(phrase).then(
      function () {
        console.log('Async: Copying to clipboard was successful!');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  }
};

/**
 * @name @getCSSClassAccordingToMessage
 * @description CSS class as per the message type
 * @requires message object
 * @returns class name
 * */
export const getCSSClassAccordingToMessage = (msg, role = '') => {
  let _cssClass = '';
  let _type = getMessageSenderType(msg.userId, role);
  if (
    msg.userId >
      0 /*This includes normal agent message and simulated message*/ ||
    msg.userId === Enums.ChatMessageType.Prechat ||
    msg.userId === Enums.ChatMessageType.Predefined ||
    msg.userId === Enums.ChatMessageType.PokeMessage
  ) {
    _cssClass = 'chat_message_right';
  } else if (
    msg.userId < 0 ||
    msg.userId === Enums.ChatMessageType.Bot ||
    msg.userId === Enums.ChatMessageType.System ||
    msg.userId === Enums.ChatMessageType.NonVisitorSystem
  ) {
    _cssClass = 'chat_message_center';
  } else if (msg.userId === Enums.ChatMessageType.Visitor) {
    _cssClass = 'chat_message_left';
  } else {
    _cssClass = 'chat_message_left';
  }
  return {
    cssClass: _cssClass,
    type: _type
  };
};

/**
 * @name @getMessageSenderType
 * @description Resolve sender type as per the ID
 * @requires userId
 * @returns sender type
 * */
export const getMessageSenderType = (userId, role = '') => {
  if (userId === Enums.ChatMessageType.Bot) {
    return 'BOT';
  } else if (
    userId === Enums.ChatMessageType.Simulated ||
    userId === Enums.ChatMessageType.System ||
    userId === Enums.ChatMessageType.NonVisitorSystem
  ) {
    return 'SYSTEM';
  } else if (userId === Enums.ChatMessageType.Prechat) {
    return 'PRECHAT';
  } else if (userId === Enums.ChatMessageType.Predefined) {
    return 'PRE-DEFINED';
  } else if (userId === Enums.ChatMessageType.PokeMessage) {
    return 'POKE';
  } else if (userId > 0) {
    return role;
  }
  return false;
};

/**
 * @name @isCallConnectEnable
 * @description enable/disable call connect button
 * @requires selectedChat
 * @returns true/false
 * */
export const isCallConnectEnable = selectedChat => {
  let enabled = true;
  if (
    !selectedChat.data.quickConnectEnabled ||
    !selectedChat.data.quickConnectAvailable ||
    !selectedChat.data.quickConnectPhoneNumber ||
    selectedChat.data.status == Enums.ChatStatus.ClientHandling ||
    (selectedChat.data.isOptInConsentGiven &&
      selectedChat.data.status == Enums.ChatStatus.Inactive)
  ) {
    enabled = false;
  }
  return enabled;
};

/**
 * @name @renderComponent
 * @description render role base messaging component
 * @requires components
 * @requires componentType
 * @returns component
 * */

export const renderMessagingComponent = (
  MDWrapper,
  SignUpForm,
  SpecialMessageComp,
  componentType
) => {
  switch (componentType) {
    case Enums.MessagingComponentType.MDWrapper:
      return MDWrapper;
    case Enums.MessagingComponentType.SignUpForm:
      return SignUpForm;
    case Enums.MessagingComponentType.SpecialMessageComp:
      return SpecialMessageComp;
    default:
      break;
  }
};

/**
 * @name @encryptText
 * @description chat text messages will be encrypted for vendormax users those are not admins
 * @requires m (message data)
 * @returns m (updated message data)
 * */

export const encryptText = m => {
  let {
    roles: { roles }
  } = store.getState();
  if (roles?.enableEncryption && m.maskedText) {
    //Replace original message with the masked one.
    m.text = m.maskedText;
  }

  return m;
};

/**
 * @name @parseScriptText
 * @description remove html part from selected suggestion script text
 * @requires scriptText (text)
 * @returns text (parsedText)
 * */
export const parseScriptText = scriptText => {
  let parsedText = getPlainStringFromHTMLElementAsString(scriptText);
  let text = parsedText;
  if (parsedText.includes('::')) {
    if (parsedText.split('::')[1]) text = parsedText.split('::')[1].trim();
    else text = '';
  }
  return text;
};

export const getSuggestionIcon = QT => {
  let chatQuestionType = Enums.ChatQuestionType;
  let iconName = Object.keys(chatQuestionType)[QT];
  // console.log(iconName);
  if (iconName === 'None') return;
  let getIcon = require.context(
    'assets/app-images/user-suggestions-icons',
    true,
    /^\.\/.*\.svg$/
  );
  let icon = getIcon(`./${iconName}.svg`);
  return icon;
};
export const getSuggestionsByRole = data => {
  let {
    roles: { roles },
    auth: { profile }
  } = store.getState();
  let roleBaseQuestions = data?.questions
    ?.map(question => {
      let modifiedAnswers =
        question?.answers?.filter(
          answer =>
            answer.visibility === Enums.QTVisibility.Both ||
            (answer.visibility === Enums.QTVisibility.Agents &&
              roles?.isAgent) ||
            (answer.visibility === Enums.QTVisibility.Operators &&
              roles?.isOperator) ||
            (answer.visibility === Enums.QTVisibility.Self &&
              answer?.createdBy === profile?.Username) // username can be same in different companies
        ) || [];
      let modifiedQuestion = { ...question, answers: modifiedAnswers };
      // commented below line to show folder which have no answer in add statement modal
      // return modifiedAnswers.length ? modifiedQuestion : null;
      return modifiedQuestion;
    })
    .filter(Boolean);
  let modifiedData = { ...data, questions: roleBaseQuestions };
  return modifiedData;
};

/**
 * @name @chatInitiatedByPlatformObj
 * @description create initiatedBy object w.r.t platform i.e web/sms, social is also consider as web
 * @returns object
 * */
export const chatInitiatedByPlatformObj = () => {
  let chatInitiatedPlatformType = enum_InitiatedBy.reduce((acc, curr) => {
    let text = curr.selectorType === 'social' ? curr.name : curr.selectorType;
    let textMode = ['sms', 'GMB'].includes(text) ? 'uppercase' : 'sentence';
    text = camelCaseStringToNormalText(text, textMode, true);
    acc[curr.value] = text;
    return acc;
  }, {});
  return { ...chatInitiatedPlatformType, 0: 'Web' };
};

// get comma separated participants names,
// if chat picked by current user then hight user's name
export const getUserNameFromParticipants = participants => {
  let names = participants
    ?.map(
      participant =>
        participant.username &&
        (participant.userId === participant.currentlyAssigntoChat
          ? `<span class="outlined-box active">${participant.username}</span>`
          : `<span class="outlined-box">${participant.username}</span>`)
    )
    .filter(Boolean);
  return names?.join(' ');
};

export const restrictChatByRoles = (room, roles) => {
  let result = {
    username: room?.getPickedUpByUsername(),
    pickedupBy: room?.data?.pickedUpBy,
    pickedupTime: room?.data?.pickedUpOn,
    shouldSubcribeChat: true,
    pickupByUser: room?.data?.pickedUpByOperator ? 'Operator' : 'Agent'
  };
  // if the user is an operator, and the chat has already been picked up by an agent, don't show anything
  if (
    roles?.isOperator &&
    room?.isRunByAgent() &&
    (room?.getTransferType() ===
      Enums.TransferChatType.ScriptBotTransferToLiveAgent ||
      room?.getCurrentUserId() !== room?.data?.transferredTo) &&
    room?.getCurrentUserId() !== room?.data?.requestTransferTo &&
    !room?.isAwaitingPickup() &&
    !(room?.isSMSTransferred() && room?.data?.transferredTo <= 0) &&
    !room?.isAssignedToCurrentUser() &&
    !room?.isOptInChat()
    // &&
    // room?.data?.pickedUpBy === room?.data?.currentlyAssignedTo
  ) {
    // chat already picked by agent
    return preventModalPayload(result);
    // if the user is an agent, and the chat has already been picked up by an operator, don't show anything
  } else if (
    roles?.isAgent &&
    !room?.isRunByAgent() &&
    !room?.isAwaitingPickup() &&
    (room?.data?.pickedUpBy === room?.data?.currentlyAssignedTo ||
      (room?.getTransferType() ===
        Enums.TransferChatType.ScriptBotTransferToLiveAgent &&
        !room.isCurrentlyAssignedTo()))
  ) {
    // modified payload for optin chat scenario
    result = room?.isOptInChat() ? preventModalDataForOptIn(result) : result;
    // chat already picked by operator
    return preventModalPayload({ ...result });
  }
  return result;
};

const preventModalPayload = (payload, isAllow = false) => {
  let data = {
    displayMessage:
      payload?.displayMessage ||
      'This chat has been picked up already, and you can no longer pick it up.',
    title: payload?.title || `${payload?.pickupByUser} Handled Chat`
  };

  return { ...payload, ...data, shouldSubcribeChat: isAllow };
};

export const preventModalDataForOptIn = result => {
  let displayMessage =
    'You are not allowed to open the chat handled by the client.';
  let title = 'Client Handled Chat';
  return { ...result, displayMessage, title };
};

export const upsertChatRoom = (array = [], newValue) => {
  let index = array.findIndex(item => item?.getId() === newValue?.getId());
  if (index !== -1) {
    array[index] = newValue;
  } else {
    // unshift to add item to the beginning
    array.unshift(newValue);
  }
  return array;
};
// add visitor response messages in reason field if reason field is empty and side pane is open
export const createLeadNotes = allVisitorMessages => {
  let reason = '';
  if (allVisitorMessages.length) {
    allVisitorMessages.map(msg => {
      let tempMsg = getPlainStringFromHTMLElementAsString(msg.text);
      reason = reason ? reason + '\n' + tempMsg : tempMsg;
    });
  }
  return reason;
};

export const mapVisitorResponseAnswer = (
  visitorInfo,
  responseType,
  contactInfo,
  msgText
) => {
  if (
    responseType === Enums?.VisitorResponseType?.Name ||
    contactInfo['name']
  ) {
    visitorInfo = {
      visitorInfoName: contactInfo?.name || msgText,
      ...visitorInfo
    };
  }
  if (
    responseType === Enums?.VisitorResponseType?.Email ||
    contactInfo['email']
  ) {
    visitorInfo = {
      visitorInfoEmail: contactInfo?.email || msgText,
      ...visitorInfo
    };
  }
  if (
    responseType === Enums?.VisitorResponseType?.Phone ||
    (contactInfo['phone'] && contactInfo['phone'].length > 3)
  ) {
    visitorInfo = {
      visitorInfoPhone: contactInfo?.phone || msgText,
      ...visitorInfo
    };
  }

  return visitorInfo;
};

export const getVisitorResponseTypeFromOperatorMsg = (
  messages = [],
  responseType
) => {
  let index = messages.reverse().findIndex(msg => msg?.userId === 0);
  if (index !== -1) {
    let lastMsg = messages[index + 1];
    responseType = lastMsg?.visitorResponseType;
  }
  return responseType;
};
