import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { ChatAPI, CompanyAPI, StatementAPI } from 'api/endpoints';
import { updateViewedUser } from 'services/utils/generic.methods';
import { getSuggestionsByRole } from 'services/utils/messaging/message.methods';
import { success } from 'services/utils/variables';
import { toast } from 'react-toastify';

const initialState = {
  selectedSuggestion: {},
  suggestions: {},
  isLoading: false,
  autoSuggestEnable: true,
  assignedCompanies: [],
  isCompanyLoading: false,
  isFolderLoading: false,

  // add statement states to access globally
  openAddStatementModal: false,
  statementText: ''
};
/**
 * @name @createSlice
 * @description create redux store slice for Messaging Chats
 **/

export const SuggestionSlice = createSlice({
  name: 'Suggestions',
  initialState: { ...initialState },

  reducers: {
    /**
     * @description set Messaging Chats instance into store
     **/

    setSuggestion: (state, action) => {
      //  add chats in incomingChats State
      let tempSuggestion = { ...state.suggestions };
      let { companyId, threeLetterISOLanguageName } = action.payload;

      tempSuggestion[threeLetterISOLanguageName] = {
        ...tempSuggestion[threeLetterISOLanguageName],
        [companyId]: action.payload
      };
      state.suggestions = tempSuggestion;
    },

    removeSuggestion: (state, action) => {
      //  remove suggestion from suggestions state
      let tempSuggestion = { ...state.suggestions };
      let { companyId, threeLetterISOLanguageName } = action.payload;
      if (
        tempSuggestion[threeLetterISOLanguageName] &&
        tempSuggestion[threeLetterISOLanguageName][companyId]
      ) {
        delete tempSuggestion[threeLetterISOLanguageName][companyId];
        state.suggestions = tempSuggestion;
      }
    },

    setAssignedCompanies: (state, action) => {
      state.assignedCompanies = action.payload;
    },

    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },

    setCompanyLoading: (state, action) => {
      state.isCompanyLoading = action.payload;
    },
    setFolderLoading: (state, action) => {
      state.isFolderLoading = action.payload;
    },

    setAutoSuggestEnable: (state, action) => {
      state.autoSuggestEnable = action.payload;
      updateViewedUser({ autoSuggestEnable: action.payload });
    },

    setOpenSuggestionModal: (state, action) => {
      state.openAddStatementModal = action.payload;
    },
    setStatementText: (state, action) => {
      state.statementText = action.payload;
    }
  }
});

export const {
  setSuggestion,
  removeSuggestion,
  setLoading,
  setAutoSuggestEnable,
  setCompanyLoading,
  setAssignedCompanies,
  setFolderLoading,
  setOpenSuggestionModal,
  setStatementText
} = SuggestionSlice.actions;

/* called a selector and allows to select values from the state */

export const getSuggestionsByCompany = payload => state => {
  let tempSuggestion = state.Suggestions.suggestions;
  let { companyId, threeLetterISOLanguageName } = payload;
  let selected = null;
  if (tempSuggestion[threeLetterISOLanguageName]) {
    selected = tempSuggestion[threeLetterISOLanguageName][companyId];
  }
  return selected;
};

export const getAllSuggestions = state => {
  return state.Suggestions.suggestions;
};

export const getOpenAddSuggestionModalState = state => {
  return state.Suggestions.openAddStatementModal;
};

export const getStatementText = state => {
  return state.Suggestions.statementText;
};

export const getAutoSuggestEnable = state => {
  return state.Suggestions.autoSuggestEnable;
};

export const removeSuggestionAsync = payload => async dispatch => {
  return ApiServices.post(`${StatementAPI.removeStatement}`, payload)
    .then(async response => {
      if (response.data.success && response.data.data) {
        toast.success(success.deleteSuccess('Statement'));
        return Promise.resolve(response.data.data);
      }
    })
    .catch(async err => {
      return Promise.resolve('Error:' + err);
    });
};

export const getSuggestionAsync = payload => async dispatch => {
  dispatch(setLoading(true));
  let { companyId, threeLetterISOLanguageName } = payload;
  return ApiServices.get(
    ChatAPI.getSuggestions(companyId, threeLetterISOLanguageName)
  )
    .then(async response => {
      if (response.data.success && response.data.data) {
        return Promise.resolve(response.data.data);
      }
    })
    .catch(async err => {
      dispatch(setLoading(false));
      return Promise.resolve('Error:' + err);
    });
};

export const getFoldersAsync = payload => async dispatch => {
  dispatch(setFolderLoading(true));
  let { companyId, threeLetterISOLanguageName } = payload;
  return ApiServices.WCF_get(
    StatementAPI.getFolders(companyId, threeLetterISOLanguageName)
  )
    .then(async response => {
      if (response.data.success && response.data.data) {
        let modifiedData = getSuggestionsByRole(response.data.data);
        dispatch(setSuggestion(modifiedData));
        dispatch(setFolderLoading(false));
        return Promise.resolve(true);
      }
      return Promise.reject(false);
    })
    .catch(async err => {
      dispatch(setFolderLoading(false));
      return Promise.reject('Error:' + err);
    });
};

export const getAssignedCompaniesAsync = payload => async dispatch => {
  dispatch(setCompanyLoading(true));
  return ApiServices.WCF_get(CompanyAPI.getAssignedCompanies)
    .then(async response => {
      if (response.data.success && response.data.data) {
        let companies = response.data.data;
        companies = companies.map(company => ({
          id: company.id,
          name: company.companyName
        }));
        dispatch(setCompanyLoading(false));
        dispatch(setAssignedCompanies(companies));
        return Promise.resolve(true);
      }
    })
    .catch(async err => {
      dispatch(setCompanyLoading(false));
      return Promise.resolve('Error:' + err);
    });
};

export const getCompanyLoading = state => {
  return state.Suggestions.isCompanyLoading;
};

export const getFolderLoading = state => {
  return state.Suggestions.isFolderLoading;
};

export const getAllAssignedCompanies = state => {
  return state.Suggestions.assignedCompanies;
};

export const getFolderByCompany = state => payload => {
  let tempSuggestion = state.Suggestions.suggestions;
  let { companyId, threeLetterISOLanguageName } = payload;
  let selected = null;
  if (
    tempSuggestion[threeLetterISOLanguageName] &&
    tempSuggestion[threeLetterISOLanguageName][companyId]
  ) {
    let allQuestions =
      tempSuggestion[threeLetterISOLanguageName][companyId]?.questions;
    selected = allQuestions.map(
      quest =>
        quest.id > 0 && {
          id: quest.id,
          text: quest.text,
          answers: quest.answers,
          ...quest
        }
    );
  }
  return selected;
};

export const getAgentScriptIdByCompany = state => payload => {
  let tempSuggestion = state.Suggestions.suggestions;
  let { companyId, threeLetterISOLanguageName } = payload;
  let selectedId = null;
  if (
    tempSuggestion[threeLetterISOLanguageName] &&
    tempSuggestion[threeLetterISOLanguageName][companyId]
  ) {
    selectedId = tempSuggestion[threeLetterISOLanguageName][companyId]?.id;
  }
  return selectedId;
};

export const addFolder = payload => {
  return ApiServices.post(StatementAPI.addFolder, payload);
  // .then(async response => {
  //   if (response?.data?.data) {
  //     return Promise.resolve(response.data.data);
  //   }
  //   return Promise.reject(response);
  // })
  // .catch(async err => {
  //   return Promise.reject(err);
  // });
};

export const addStatement = payload => {
  return ApiServices.post(StatementAPI.addStatement, payload);
  // .then(async response => {
  //   if (response?.data?.data) {
  //     return Promise.resolve(response.data.data);
  //   }
  //   return Promise.reject(response);
  // })
  // .catch(async err => {
  //   return Promise.reject(err);
  // });
};

export default SuggestionSlice.reducer;
