import { Enums } from 'services/dropdown-enums';

/**
 * @name @mapLeadFormData
 * @description transform data as per server requirements
 * @requires object of the form
 * @returns mapped object
 * */
const mapLeadFormData = formData => {
  return {
    categoryId: formData['categoryId'],
    chatId: formData.chatId,
    companyId: formData.companyId,
    dealershipDepartment: null,
    email: formData['email'],
    keyfactQuestions: [],
    leadType: Enums.LeadType[formData['type']],
    name: formData['name'],
    notes: formData['reason'],
    phone: formData['phoneNumber'],
    piplSearchId: null,
    regionId: formData['regionId'],
    used: false,
    year: new Date().getFullYear(),
    agentSpecifiedCity: formData['city'],
    agentSpecifiedState: formData['state'],
    agentSpecifiedZip: formData['zip']
  };
};
export default mapLeadFormData;
