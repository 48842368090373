import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { WidgetEditor } from 'api/endpoints';
import storage from 'services/utils/storage';
import { dataKey } from 'features/settings/pvt-components/companies';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const widgetEditorSlice = createSlice({
  name: 'widgetEditor',
  initialState: {
    isSidebarOpen: true,
    developerEmails: [],
    selectedCompanies: []
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setSelectedCompaniesToState: (state, action) => {
      state.selectedCompanies = action.payload;
      storage.setItem(dataKey, action.payload);
    },
    setUpdateSelectedCompanyToState: (state, action) => {
      if (action.payload) {
        let companies = [...state.selectedCompanies];
        let index = companies.findIndex(com => com.id === action.payload.id);
        companies[index] = action.payload;
        state.selectedCompanies = companies;
        storage.setItem(dataKey, companies);
      }
    },

    removeSelectedCompaniesFromState: (state, action) => {
      let companies = [...state.selectedCompanies];
      let index = companies.findIndex(com => com.id === action.payload.id);
      companies.splice(index, 1);
      state.selectedCompanies = companies;
    },

    setDeveloperEmail: (state, action) => {
      state.developerEmails = [...action.payload];
    },

    removeAllSelectedCompaniesFromState: (state, action) => {
      state.selectedCompanies = [];
    },

    setSidebarOpen: (state, action) => {
      state.isSidebarOpen = action.payload;
    }
  }
});

export const {
  setSelectedCompaniesToState,
  removeSelectedCompaniesFromState,
  removeAllSelectedCompaniesFromState,
  setUpdateSelectedCompanyToState,
  setSidebarOpen,
  setDeveloperEmail
} = widgetEditorSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/

/* called a selector and allows to select values from the state */
export const getSelectedCompanies = state => {
  return state.widgetEditor.selectedCompanies;
};

export const getDeveloperEmails = state => {
  return state.widgetEditor.developerEmails;
};

export const getSidebarOpenState = state => {
  return state.widgetEditor.isSidebarOpen;
};

export default widgetEditorSlice.reducer;

export const sendCode = payload => {
  let endpoint = WidgetEditor.sendEmail;
  return ApiServices.WCF_post(endpoint, payload)
    .then(async response => {
      if (response?.data?.data) {
        return Promise.resolve(response.data.data);
      }
      return Promise.reject(response);
    })
    .catch(async err => {
      return Promise.reject(err);
    });
};
