import { Enums } from 'services/dropdown-enums';

export const CreateTracker = function (config, tracker) {
  var trackerConfig;
  var hasTrackerConfigured = false;
  if (tracker.type === Enums.TrackerType.GoogleAnalytics) {
    if (!hasTrackerConfigured) {
      // initializing GA4
      var debug =
        (config.environment === 'test' ||
          config.environment === 'development') &&
        true;

      tracker.client.initialize(config.GATrackingId, {
        debug,
        titleCase: true
      });

      const _customEventPublisher = (
        category,
        action,
        label,
        customObject = {}
      ) => {
        let publishData = {
          category,
          action,
          label,
          nonInteraction: true, // optional, true/false
          transport: 'xhr',
          ...tracker.customDataCallBack(),
          ...customObject
        };
        tracker.client.event(action, publishData);
      };
      const _customPageRoute = eventInfo => {
        let publishData = {
          ...eventInfo,
          ...tracker.customDataCallBack()
        };
        tracker.client.send(publishData);
      };
      // Transform ReactGA Method name with generic method names
      trackerConfig = {
        client: tracker.client,
        pageRoute: _customPageRoute,
        publish: tracker.client.event,
        applicationEvents: tracker.applicationEvents(_customEventPublisher)
      };
      hasTrackerConfigured = true;
      return TrackerClient(trackerConfig);
    }
  }
  if (tracker.type === Enums.TrackerType.AzureAppInsight) {
    // Azure AppInsight defination here..
    // initialize appInsight
    const appInsight = new tracker.client({
      config: {
        connectionString: config.instrumentationConnectionString,
        instrumentationKey: config.instrumentationConnectionString
        /* ...Other Configuration Options... */
      }
    });
    appInsight.loadAppInsights();

    //  custom page route
    const _customPageRoute = eventInfo => {
      let publishData = {
        name: eventInfo.hitType + '_custom',
        properties: {
          ...eventInfo,
          ...tracker.customDataCallBack()
        }
      };
      appInsight.trackEvent(publishData);
    };
    // custom method for publishing event
    const _customEventPublisher = (
      category,
      action,
      label,
      customObject = {}
    ) => {
      let publishData = {
        name: action,
        properties: {
          category,
          action,
          label,
          ...tracker.customDataCallBack(),
          ...customObject
        }
      };
      appInsight.trackEvent(publishData);
    };

    // Transform ReactGA Method name with generic method names
    trackerConfig = {
      client: tracker.client,
      pageRoute: _customPageRoute,
      publish: appInsight.trackEvent,
      applicationEvents: tracker.applicationEvents(_customEventPublisher)
    };
    hasTrackerConfigured = true;
    return TrackerClient(trackerConfig);
  }
};
var TrackerClient = function (client) {
  if (!client) {
    throw new Error('The tracker is not valid.');
  }
  var _init = function (options) {
    // If any generic initialization
  };
  //public data: methods and properties
  var PUBLIC_METHODS = {
    Publish: client.publish,
    PageRoute: client.pageRoute,
    ApplicationEvents: client.applicationEvents
  };
  //constructor function used to instantiate objects
  function constr(options) {
    _init.call(this, options);
  }
  constr.prototype = PUBLIC_METHODS; //expose methods for caller
  return constr;
};
