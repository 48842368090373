import {
  password,
  requiredText,
  passwordMinChar,
  matchPassword,
  newPassword
} from './genericSchemas';
import * as Yup from 'yup';

/**
 * description Setup schema for login
 * requires password and requiredText schema from genericSchemas
 **/

export const loginSchema = Yup.object().shape({
  'apexchat-password': password,
  'apexchat-company': requiredText,
  'apexchat-username': requiredText
});

/**
 * description initial values for the form
 **/
export const loginInitialValues = {
  'apexchat-username': '',
  'apexchat-company': '',
  'apexchat-password': '',
  rememberMe: false
};

/**
 * description Setup schema for forgot password
 * requires requiredText schema from genericSchemas
 **/

export const forgotPasswordSchema = Yup.object().shape({
  companyKey: requiredText,
  userName: requiredText
});

/**
 * description initial values for the form
 **/
export const forgotPasswordInitialValues = {
  companyKey: '',
  userName: ''
};

/**
 * description Setup schema for  change password
 * requires password schema from genericSchemas
 **/
export const changePasswordSchema = Yup.object().shape({
  oldPassword: requiredText,
  newPassword: newPassword,
  confirmNewPassword: matchPassword
});

/**
 * description initial values for the form
 **/
export const changePasswordInitialValues = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: ''
};
