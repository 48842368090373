export const en_chatDetails = {
  heading: 'Conversation details',
  chatTooltip: 'The portal displays up to 6 months of data',
  createdOn: {
    label: 'Created',
    info: 'When the chat was initiated in our system.'
  },
  appointmentStatus: {
    label: 'Appointment Status',
    info: 'Appointment Status'
  },
  appointmentStartTime: {
    label: 'Start Time',
    info: 'Start Time'
  },
  appointmentEndTime: {
    label: 'End Time',
    info: 'End Time'
  },
  pickedUpOn: {
    label: 'Picked Up On',
    info: 'When the chat was picked up on.'
  },
  pickedUpBy: {
    label: 'Picked Up By',
    info: 'Who picked up the chat.'
  },
  endedOn: {
    label: 'Ended',
    info: 'When the chat ended in our system.'
  },
  leadFlag: {
    label: 'Lead Flag',
    info: 'If the chat conversation turned into a valid lead.'
  },
  ip: {
    label: 'IP',
    info: 'The IP where the chat was started.'
  },
  location: {
    label: 'Location',
    info: 'The location of the visitor.'
  },
  referrer: {
    label: 'Referrer',
    info: 'The referrer where the chat was initiated.'
  },
  chatTranscript: {
    label: 'Conversation Transcript',
    info: 'Conversation Transcript'
  }
};
export default en_chatDetails;
export { default as fr_chatDetails } from '../fr-FR-ChatDictionary/fr-chatDetails';
export { default as sp_chatDetails } from '../sp-SP-ChatDictionary/sp-chatDetails';
