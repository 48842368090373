export const sp_general = {
  saveTxt: 'Ahorrar',
  editTxt: 'Editar',
  uploadAttachments: 'Subir archivos adjuntos',
  generalTxt: 'General',
  analyticsInsights: 'Estadísticas analíticas',
  detailView: 'Vista de detalles',
  menuItem1: 'Detalles básicos',
  menuItem2: 'Información del contacto',
  menuItem3: 'Los correos electrónicos de plomo',
  menuItem4: 'Hora de llamada / conexión',
  menuItem5: 'Usuarios',
  budgetTxt: 'Presupuesto',
  menuItem6: 'Estimulación, Terminales y Tráfico',
  menuItem11: 'Historia',
  settingTxt: 'Ajustes',
  menuItem7: 'Móvil',
  integrationTxt: 'Integraciones',
  menuItem8: 'CRM',
  menuItem9: 'Facebook',
  menuItem10: 'Seguimiento',
  activeText: 'Activo',
  inActiveText: 'Enactivo',
  goToOnboarding: 'Ir a Incorporación',
  provisioningNote:
    'Para realizar cambios en estos datos, vaya al portal anterior.',
  provisioningErrorMsg:
    'Hay algunos errores en el formulario. Corríjalos antes de enviarlos.',
  portallang: 'Idioma del portal',
  profile: 'Perfil',
  changePass: 'Cambiar la contraseña',
  logout: 'Cerrar sesión',
  messagingTag: 'Para ver el portal de mensajería.',
  oldPortalTag:
    'Para la visita funcionalidad completa del portal existente para la incorporación.',
  clickBtn: 'haga clic aquí',
  portalBtn: 'Portal existente'
};
export default sp_general;
