import React, { useState, useEffect } from 'react';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { filterTypes } from './helper';
import { useGridFilters } from './useGridFilters';

const gridStyle = {
  minHeight: 'calc(100vh - 180px)',
  marginTop: 0, // UIUX-1733
  boxShadow: '0px 3px 10px 0 rgba(0, 0, 0, 0.08)',
  overflow: 'hidden'
};

export const GridWrapper = ({
  columns,
  setLoading,
  totalRecords,
  dataSource,
  loadingMessage,
  renderColumnContextMenu,
  selectedData,
  handleSelect,
  gridClass,
  vissibleColumns,
  refetch = false,
  customGridStyle = {},
  rowHeight = 40,
  recordsLimit = 50,
  enableSelection = true,
  activateRowOnFocus = true,
  showActiveRowIndicator = false,
  checkboxOnlyRowSelect = false,
  checkboxColumn = false,
  staticColumns = ['actions'],
  storageKey = null,
  strippedRows = false,
  showCellBorders = 'horizontal',
  bodyScroll = true
}) => {
  const [limit, setLimit] = useState(recordsLimit);
  const [dynamicColumns, setDynamicColumns] = useState(columns);
  const {
    filterValue,
    sortInfo,
    defaultFilterValue,
    defaultSortInfo,
    onFilterValueChange,
    onSortInfoChange
  } = useGridFilters(storageKey, columns);

  const getVissibleColumns = () => {
    let filteredColumns = columns.map(dc => ({
      ...dc,
      visible: staticColumns.includes(dc.name)
        ? true
        : !!vissibleColumns[dc.name]
    }));
    return filteredColumns;
  };

  useEffect(() => {
    if (vissibleColumns && Object.keys(vissibleColumns).length) {
      setDynamicColumns(getVissibleColumns());
    }
  }, [vissibleColumns]);

  useEffect(() => {
    if (bodyScroll) removeScroll('overflow:hidden');
    return () => {
      removeScroll('');
    };
  }, []);

  const removeScroll = styles => {
    document.getElementsByTagName('body')[0].style.cssText = styles;
  };

  const [gridRef, setGridRef] = useState(null);
  const onReload = () => {
    if (gridRef && gridRef.current) {
      gridRef.current.reload();
    }
  };

  useEffect(() => {
    if (refetch) {
      onReload();
    }
  }, [refetch]);

  return (
    <>
      <ReactDataGrid
        checkboxColumn={checkboxColumn}
        checkboxOnlyRowSelect={checkboxOnlyRowSelect}
        onReady={setGridRef}
        idProperty="id"
        style={{ ...gridStyle, ...customGridStyle }}
        className={gridClass ? gridClass : ''}
        columns={dynamicColumns}
        limit={limit}
        onLimitChange={setLimit}
        pagination={true}
        rowHeight={rowHeight}
        dataSource={dataSource}
        onLoadingChange={setLoading}
        onSortInfoChange={onSortInfoChange}
        sortInfo={sortInfo || defaultSortInfo}
        defaultSortInfo={defaultSortInfo}
        onFilterValueChange={onFilterValueChange}
        filterValue={filterValue || defaultFilterValue}
        defaultFilterValue={defaultFilterValue}
        filterTypes={filterTypes}
        showHoverRows={true}
        showCellBorders={showCellBorders}
        showColumnMenuTool={true}
        loadingText={loadingMessage}
        renderColumnContextMenu={renderColumnContextMenu}
        selected={selectedData}
        onSelectionChange={handleSelect}
        enableSelection={enableSelection}
        showZebraRows={strippedRows}
        activateRowOnFocus={activateRowOnFocus}
        showActiveRowIndicator={showActiveRowIndicator}
      />
    </>
  );
};
