import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useCallback
} from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectLanguage } from 'store/slices/userPreferenceSettings/userPreferenceSlice';
import './tour.scss';
import {
  getUserFromViewedUser,
  RandomNumber,
  updateViewedUser
} from 'services/utils/generic.methods';
import CustomCarousel from 'pub-components/CustomCarousel';
import { useTranslation } from 'react-i18next';
import { semverGreaterThan } from 'CacheBuster';
import { filterTourBasedOnPermission } from './helper';
import UilAngleLeft from '@iconscout/react-unicons/icons/uil-angle-left';
import { MediaComp } from './mediaComp';
import FeatureBackgroundBlue2 from 'assets/app-images/newFeaturePortal_03.png';
import FeatureBackgroundGray2 from 'assets/app-images/newFeaturePortalgrey_03.png';
import { AppMetaProvider } from 'pub-components/Sidebar/helper';

const FeatureTourModal = forwardRef((props, ref) => {
  const [releases, setReleases] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataArray, setDataArray] = useState([]);
  const [splashScreenArray, setSplashScreenArray] = useState([]);
  const [startTour, setStartTour] = useState(false);
  const language = useSelector(selectLanguage);
  const { t } = useTranslation();
  let theme = AppMetaProvider().GetCurrentTheme() || 'blue';
  // let theme = 'blue';
  // const userDetails = JSON.parse(storage.getItem('Profile'));
  // const userDisplayName = userDetails?.DisplayName;
  // const userName = t('company.tour.featureTourWelcome').replace(
  //   '<user>',
  //   userDisplayName
  // );

  useImperativeHandle(ref, () => ({ dataArray }), [dataArray]);

  const exitTour = () => {
    props.onHide(false);
    setReleases([global.AppInfo.version]);
    setSelectedIndex(0);
    setTimeout(() => {
      setStartTour(false);
    }, 500);
  };

  useEffect(() => {
    fetchReleases();
  }, []);

  useEffect(() => {
    if (selectedIndex) {
      document.removeEventListener('keydown', onKeyDown);
    } else {
      document.addEventListener('keydown', onKeyDown);
    }
  }, [selectedIndex]);

  const getIndexes = (current, previous) => {
    setSelectedIndex(current);
  };

  const fetchReleases = () => {
    let { lastSeenVersion } = getUserFromViewedUser() || {};
    fetch(`/tour/releases.json?id=${RandomNumber()}`)
      .then(response => response.json())
      .then(data => {
        if (data) {
          let filtered = lastSeenVersion
            ? data.releases.filter((rl, index) =>
                semverGreaterThan(rl, lastSeenVersion)
              )
            : [global.AppInfo.version];
          if (!filtered.length) {
            setReleases([global.AppInfo.version]);
          } else setReleases(filtered);
        }
      })
      .catch(err => {
        setReleases([global.AppInfo.version]);
        console.log(err);
      });
  };
  useEffect(() => {
    if (releases.length) {
      fetchTourJSON();
    }
  }, [releases]);

  const checkData = data => {
    let urls = [];
    data.forEach(dt => {
      urls.push(
        fetch(`/tour/${dt}/tour.json?id=${RandomNumber()}`)
          .then(response => response.json())
          .catch(response => null)
      );
    });
    let temp = [];
    Promise.all(urls)
      .then(ress => {
        ress.forEach(i => {
          if (i) {
            let filteredData = filterTourBasedOnPermission(i);
            temp = [...temp, ...generateArray(i.releaseName, filteredData)];
          }
        });
        setSplashScreenArray(temp.filter(i => !i.releaseScreen));
        setDataArray(
          temp.filter(i => !('releaseScreen' in i) || i.releaseScreen)
        );
        // auto-open Modal
        openModal(ress);
      })
      .catch(err => {
        console.log('fetch tour json', err);
      });
  };
  const openModal = data => {
    let { lastSeenVersion } = getUserFromViewedUser() || {};
    if (data.some(re => re)) {
      if (lastSeenVersion) {
        if (
          semverGreaterThan(global.AppInfo.version, lastSeenVersion || '0.0.0')
        ) {
          setTimeout(() => {
            props.onHide(true);
          }, 1000);
        }
      } else {
        setTimeout(() => {
          props.onHide(true);
        }, 1000);
      }
    }
    updateViewedUser({ lastSeenVersion: global.AppInfo.version });
  };

  const fetchTourJSON = () => {
    setDataArray([]);
    let data = props.currentRelease
      ? [props.currentRelease]
      : releases.reverse();
    checkData(data);
  };

  const generateArray = (release, data) => {
    let temp = [];
    if (data?.['features']) {
      temp.push(
        ...data?.['features'].map(dt => ({
          ...dt,
          release
        }))
      );
    }
    return temp;
  };

  const onKeyDown = useCallback(event => {
    if (event.key === 'ArrowLeft' && !event.shiftKey) {
      console.log('enter press here!');
      setStartTour(false);
      // return false;
    }
  }, []);

  const BackgroundImage = {
    blue: FeatureBackgroundBlue2,
    gray: FeatureBackgroundGray2
  };
  return (
    <div className="feature-modal-container">
      <Modal
        show={props.show}
        onHide={exitTour}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        scrollable={true}
        className={'feature-modal-container'}
      >
        <Modal.Body style={{ padding: '0px' }}>
          <div
            className="main-modal-body"
            style={{
              background:
                theme === 'blue'
                  ? 'linear-gradient(65deg, rgba(51,13,105,1) 0%, rgba(48,201,205,1) 100%)'
                  : 'linear-gradient(65deg, rgba(47,57,68,1) 0%, rgba(84,99,116,1) 100%)'
            }}
          >
            <div className="">
              {startTour && dataArray.length ? (
                <div className="">
                  <div className="d-flex justify-content-between align-items-center px-3 pt-3">
                    <div className="">
                      <span className="badge badge-success custom-badge">
                        Version: {dataArray[selectedIndex]?.release || ''}
                      </span>
                    </div>
                    <label className="m-0 cursor-pointer" onClick={exitTour}>
                      {t('company.tour.btn7')}
                    </label>
                  </div>
                  <div className="slider-container">
                    <CustomCarousel
                      getIndexes={getIndexes}
                      data={dataArray}
                      ChildComp={({ data }) => {
                        return (
                          <>
                            <MediaComp path={data.mediaPath} />
                            <div
                              className="mt-3 d-flex align-items-center justify-content-center"
                              style={{ height: '80px' }}
                            >
                              <div className="text-center">
                                <div className="info-container">
                                  {data[language].heading}
                                </div>
                                {data[language]?.text
                                  ? data[language].text?.map((val, ind) => (
                                      <p key={ind} className="mb-0 px-5">
                                        {val}
                                      </p>
                                    ))
                                  : null}
                              </div>
                            </div>
                          </>
                        );
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className="whats-new-section">
                  <div className="d-flex whats-new-container align-items-center">
                    <div className={'main-container2'}>
                      <div className="info-container">
                        {t('company.tour.weHaveAnUpdate')}
                        <div className="info-container-detail">
                          {t('company.tour.tourSubHeading')}
                        </div>
                      </div>
                      <ul className={'bullet-text2'}>
                        {splashScreenArray?.map((val, ind) => (
                          <li key={ind}>{val[language]?.heading}</li>
                        ))}
                      </ul>
                    </div>
                    <div className={'title-image-container2'}>
                      <div
                        className="bg-image"
                        style={{
                          background: `url(${BackgroundImage[theme]})`,
                          backgroundSize: 'cover'
                        }}
                      ></div>
                    </div>
                  </div>
                  {/* {!hideTourWizard ? (
                    <div className="text-container">
                      <div className="heading">{userName}</div>
                      <p className="info mb-0 mt-1">
                        {t('company.tour.featureTourWelcomeText')}
                      </p>
                    </div>
                  ) : null} */}
                </div>
              )}
            </div>
            {!startTour ? (
              <div
                className={`d-flex align-items-center justify-content-end`}
                style={{ padding: '12px 8px' }}
              >
                <div>
                  <Button
                    variant={'outline'}
                    className="mx-1 button-padding closeBtn"
                    onClick={exitTour}
                  >
                    {t('company.tour.btn6')}
                  </Button>
                  {dataArray.length && releases.length > 1 ? (
                    <Button
                      variant="primary"
                      className="mx-1 button-padding"
                      // onClick={() => updateSelectedIndex(0)}
                      onClick={() => setStartTour(true)}
                    >
                      {t('company.tour.btn2')}
                    </Button>
                  ) : null}
                </div>
              </div>
            ) : selectedIndex === dataArray.length - 1 ? (
              <div className="done-btn">
                <Button
                  variant="primary"
                  className="mx-1 button-padding"
                  onClick={exitTour}
                >
                  {t('company.tour.btn8')}
                </Button>
              </div>
            ) : selectedIndex === 0 && startTour ? (
              <div
                className="back-btn cursor-pointer"
                id="tour-back-to-home"
                // onKeyDown={this.onKeyDown}
              >
                <UilAngleLeft onClick={() => setStartTour(false)} size={'40'} />
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
});

export default FeatureTourModal;
