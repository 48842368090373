import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import 'assets/app-styles/scss/change-password.scss';
import { errors } from 'services/utils/variables';
import { setErrorClass } from 'services/utils/generic.methods';
import UILEyeShow from '@iconscout/react-unicons/icons/uil-eye-slash';
import UILEye from '@iconscout/react-unicons/icons/uil-eye';
import { useEffect } from 'react';
import {
  FormGroup,
  IconButton,
  InputAdornment,
  TextField
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import SimpleLabelTextBox from 'pub-components/ui-controls/ui-components/SimpleLabelTextbox';

const ChangePasswordForm = ({
  requirePasswordReset,
  changePasswordFormIk,
  oldPasswordError
}) => {
  const [oldPasswordShow, setOldPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmNewPasswordShow, setConfirmNewPasswordShow] = useState(false);
  const [fieldErrorOnSubmit, setFieldErrorOnSubmit] =
    useState(oldPasswordError);

  /**
   * @name @handleOnChange
   * @description do something on field onChange
   * @requires html input element
   * */
  const handleOnChange = e => {
    changePasswordFormIk.handleChange(e);
    setFieldErrorOnSubmit(false);
  };

  useEffect(() => {
    setFieldErrorOnSubmit(oldPasswordError);
  }, [oldPasswordError]);

  /**
   * hide and show password
   */
  const toggleOldPassword = () => {
    setOldPasswordShow(oldPasswordShow ? false : true);
  };
  const toggleNewPassword = () => {
    setNewPasswordShow(newPasswordShow ? false : true);
  };
  const toggleConfirmNewPassword = () => {
    setConfirmNewPasswordShow(confirmNewPasswordShow ? false : true);
  };

  return (
    <>
      <Form
        className="form form-class white-background p-0"
        onSubmit={changePasswordFormIk.handleSubmit}
        autoComplete="off"
      >
        {requirePasswordReset ? (
          <div className="warning-msg">
            As you previously forgot the password, The change password is
            mandatory, closing this window will redirect you to the login page
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            gap: '24px',
            flexDirection: 'column'
          }}
        >
          <SimpleLabelTextBox
            type={oldPasswordShow ? 'text' : 'password'}
            heading={
              requirePasswordReset ? 'Temporary password' : 'Old password'
            }
            field="oldPassword"
            dataProperty={changePasswordFormIk.values['oldPassword']}
            titleText={
              requirePasswordReset ? 'Temporary password' : 'Old password'
            }
            error={
              changePasswordFormIk.touched.oldPassword &&
              changePasswordFormIk.errors.oldPassword
            }
            editable={true}
            handleBlur={changePasswordFormIk.handleBlur}
            handleChange={handleOnChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={toggleOldPassword}>
                  {oldPasswordShow ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <SimpleLabelTextBox
            type={newPasswordShow ? 'text' : 'password'}
            heading={'New Password'}
            field="newPassword"
            dataProperty={changePasswordFormIk.values['newPassword']}
            titleText={'New Password'}
            error={
              changePasswordFormIk.touched.newPassword &&
              changePasswordFormIk.errors.newPassword
            }
            editable={true}
            handleBlur={changePasswordFormIk.handleBlur}
            handleChange={handleOnChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={toggleNewPassword}>
                  {newPasswordShow ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <SimpleLabelTextBox
            type={confirmNewPasswordShow ? 'text' : 'password'}
            heading={'Confirm new Password'}
            field="confirmNewPassword"
            dataProperty={changePasswordFormIk.values['confirmNewPassword']}
            titleText={'Confirm new Password'}
            error={
              changePasswordFormIk.touched.confirmNewPassword &&
              changePasswordFormIk.errors.confirmNewPassword
            }
            editable={true}
            handleBlur={changePasswordFormIk.handleBlur}
            handleChange={handleOnChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={toggleConfirmNewPassword}>
                  {confirmNewPasswordShow ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </div>
      </Form>
    </>
  );
};

export default ChangePasswordForm;
