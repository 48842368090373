import store from 'store';
import i18n from 'app/i18n';
import { logout } from 'store/slices/auth/authSlice';
import { setRolesToState } from 'store/slices/roles/rolesSlice';
import connectionService from 'services/utils/connectionIdentifier';
import { setScript } from 'store/slices/script-load/scriptLoadSlice';
import { resetChatsOnLogout } from 'store/slices/messagingChats/messagingChats';
import { UnSubscribeAllChannels } from 'app/communication/services/chat/pub_sub_chat';
import { addSubdomainInURL } from './generic.methods';
import config from 'config';
import storage from './storage';

export const globalLogout = (shouldRedirect = false) => {
  i18n.changeLanguage('en-US');
  store.dispatch(
    setScript({
      isLoaded: false,
      src: ''
    })
  );
  CRMLogout();
  store.dispatch(logout());
  store.dispatch(setRolesToState({}));

  // if (!storage.getItem('return-url')) {
  //   storage.setItem('return-url', window.location.pathname);
  // }

  // if (shouldRedirect) {
  //   setTimeout(() => {
  //     window.location.href = window.location.origin + '/login';
  //   }, 1000);
  // }

  //Reset MD States & Channels
  clearInterval(window.inter);

  //Reset Tab title timer
  if (window?.tabTitleTimer) {
    clearInterval(window.tabTitleTimer);
  }

  store.dispatch(resetChatsOnLogout());
  if (window.RTC.status && window.RTC.subscribedChannels.length > 0) {
    UnSubscribeAllChannels();
    connectionService.ClearAll();
    setTimeout(() => {
      window.RTC.client.disconnect();
      window.RTC.init = false;
      window.RTC.status = false;
      window.RTC.hasLoaded = false;
    }, 1000);
  }
};

export const CRMLogout = () => {
  const _createIframeElement = (url = '') => {
    // Creating iframe element.
    var el = document.createElement('iframe');
    // setting the values for the attributes.
    el.src = url;
    el.id = 'crm-iframe';
    // Adding the created iframe to root as a child element
    document.getElementById('root').appendChild(el);
  };

  const _removeIframeElement = () => {
    // Remove iframe element.
    document.getElementById('crm-iframe').remove();
  };

  const user = JSON.parse(storage.getItem('Profile'));
  if (user && !!user?.RefreshToken) {
    let host = window.location.host.replace('my.', 'crm.');
    host = !host.startsWith('https://') ? 'https://' + host : host;
    // Add subdomain "crm." before host url in case of Production/Beta Only
    if (config.environment === 'production' || config.environment === 'beta') {
      host = addSubdomainInURL(
        (user && user.HostDomain) || config.portalURL,
        'crm'
      );
    }

    let redirectionUrl = `${host}/logout`;
    _createIframeElement(redirectionUrl);
    setTimeout(() => {
      _removeIframeElement();
    }, 2000);
  }
};
