import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { changeTourGuideFlag } from 'services/utils/generic.methods';
import storage from 'services/utils/storage';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { selectRoles } from 'store/slices/roles/rolesSlice';
import { onTourModalSetTourState } from 'features/introJS-tour/tour-helper';
import './styles.scss';
import useViewport from 'services/viewport-hook';
import { Spinner } from 'react-bootstrap';
const TourModal = props => {
  const [imgLoading, setImgLoading] = React.useState(true);
  const { t } = useTranslation();
  const screen = useViewport();
  const history = useHistory();
  const { roles } = useSelector(selectRoles);

  const userDetails = JSON.parse(storage.getItem('Profile'));
  const userDisplayName = userDetails?.DisplayName;

  const userName = t('company.tour.tourWelcome').replace(
    '<user>',
    userDisplayName
  );
  const startTour = () => {
    onTourModalSetTourState(true);
    props.toggelTourHandler(true);
    props.onHide();
    if (roles && roles.analytics && roles.analytics.view) {
      history.push('/analytics');
    }
    changeTourGuideFlag();
  };

  const exitTour = () => {
    changeTourGuideFlag();
    onTourModalSetTourState(false);
    props.toggelTourHandler(false);
    props.onHide();
  };

  const onStartFeature = () => {
    props.startFeatureTour(true);
    props.onHide();
  };

  // added loading when image is not loaded an onload set imgLoading state to false
  // now modal size will be same if image loaded or not
  const onImgLoad = () => {
    setImgLoading(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="tour-greeting-modal"
    >
      {/* <button className="modal-cancel-btn" onClick={props.onHide}>
        <UilTimes />
      </button> */}
      <Modal.Body>
        <h4 style={{ marginTop: '15px' }}>{userName}</h4>
        <p>{t('company.tour.tourWelcomeText')}</p>
        <div
          style={{
            minHeight: screen.xs ? '164px' : '360px',
            position: 'relative'
          }}
        >
          <img
            className={
              imgLoading ? 'greeting-img-hidden' : 'greeting-img-visible'
            }
            src={'/undraw_Hello_re_3evm.png'}
            style={{ width: '100%' }}
            alt="greeting"
            loading="lazy"
            onLoad={onImgLoad}
          />
          {imgLoading && (
            <span className="load-img-spinner">
              <Spinner animation="border" variant="secondary" size="lg" />
            </span>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between tour-modal-footer">
          {props.startFeatureTour ? (
            <Button
              variant="outline-primary"
              className="mx-1 button-padding"
              onClick={onStartFeature}
            >
              {t('company.tour.btn9')}
            </Button>
          ) : (
            <div></div>
          )}
          <div>
            <Button
              variant="outline-primary"
              className="mx-1 button-padding"
              onClick={exitTour}
            >
              {t('company.tour.btn1')}
            </Button>
            <Button
              variant="primary"
              className="mx-1 button-padding"
              onClick={startTour}
            >
              {t('company.tour.btn2')}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TourModal;
