import renderToolTip from 'pub-components/ui-controls/renderToolTip';
import { Badge, OverlayTrigger } from 'react-bootstrap';
import './styles.scss';

const CustomBadge = ({
  variant = 'secondary',
  count = 0,
  toolTipLabel = '',
  style = {},
  showToolTip = true
}) => {
  return (
    <OverlayTrigger
      placement={'auto'}
      overlay={renderToolTip(`${toolTipLabel}: ${count}`)}
      show={showToolTip ? undefined : false}
    >
      <Badge pill bg={variant} className={`badge-container`} style={style}>
        {count}
      </Badge>
    </OverlayTrigger>
  );
};

export default CustomBadge;
