import { RTCClient } from './chat-connection';

var rtcClient;

const RTC = () => {
  if (!window.RTC.init) {
    rtcClient = RTCClient();
  }
  const _subscribe = options => {
    let subscribedChannelsList = rtcClient.client.getSubscribedChannels();
    // it is to check the requested current channel is already subscribed or not
    let checkingAlreadySubscribedChannels = subscribedChannelsList.filter(
      element => options?.channels?.includes(element)
    );

    if (rtcClient?.status && checkingAlreadySubscribedChannels.length === 0) {
      let args = {
        channels: options.channels,
        ...options.callbacks
      };
      if (options.meta) {
        args.meta = options.meta;
      }
      rtcClient.client.Subscribe(args);

      rtcClient.pushSubscribedChannels(options.channels);
    }

    // Note: recall subscribe function if status is false to resubscrbe the channel
    if (!rtcClient?.status) {
      setTimeout(() => {
        if (checkingAlreadySubscribedChannels.length === 0) {
          _subscribe(options);
        }
      }, 1000);
    }
  };

  const _publish = options => {
    options.retries = options.retries || 0;
    options.meta = options.meta || {};
    if (options.channels) {
      options.channels = options.channels;
    }
    if (options.channel) {
      options.channel = options.channel;
    }
    options.data = options.data || {};
    let args = {
      ...options,
      ...options.callbacks
    };

    rtcClient.client.Publish(args);
  };
  const _unsubscribe = channels => {
    rtcClient.client.UnSubscribe({
      channels: channels
    });
  };
  return {
    Subscribe: _subscribe,
    Publish: _publish,
    UnSubscribe: _unsubscribe
  };
};

export default RTC;
