import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { HostAPI } from 'api/endpoints';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const hostSlice = createSlice({
  name: 'hosts',
  initialState: {
    hosts: [],
    companiesHost: {}
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/

    setHostsToState: (state, action) => {
      state.hosts = action.payload;
    },
    setCompnaiesHostToState: (state, action) => {
      state.companiesHost = { ...state.companiesHost, ...action.payload };
    }
  }
});
export const { setHostsToState, setCompnaiesHostToState } = hostSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/

/* called a selector and allows to select values from the state */

export const selectHosts = state => {
  return state.hosts;
};
export const selectCompaniesHosts = state => {
  return state.hosts.companiesHost;
};
export const selectHostById = state => id => {
  return state.hosts.companiesHost[id] || null;
};

export const getHosts = payload => {
  let hostAPI = HostAPI.getOwnedHosts;
  return ApiServices.get(hostAPI);
};

export const getHostById = hostId => {
  let hostAPI = HostAPI.getHost(hostId);
  return ApiServices.get(hostAPI);
};

export default hostSlice.reducer;
