import { useState, forwardRef } from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import * as _ from 'lodash.debounce';
import './styles.scss';
import UilTimes from '@iconscout/react-unicons/icons/uil-times';
import { useEffect } from 'react';

const GridIdField = forwardRef((props, ref) => {
  const [value, setValue] = useState('');

  const debounce = _(value => {
    props.onChange(value);
  }, 1000);

  useEffect(() => {
    let val = props.filterValue?.value;
    const re = /^[0-9\b]+$/;

    if (val === '' || re.test(val)) {
      setValue(val);
    }
  }, []);

  const handleChange = e => {
    let val = { ...props.filterValue };
    const re = /^[0-9\b]+$/;

    if (e === '' || re.test(e)) {
      let temp = e;
      val.value = temp;
      setValue(temp);
      debounce(val);
    }
  };

  return (
    <TextField
      //   className={classes.root}
      className={`grid-id-field`}
      ref={ref}
      id="grid-number-id"
      placeholder={''}
      value={value}
      variant="outlined"
      name="id"
      type={'string'}
      size="small"
      onChange={e => handleChange(e.target.value)}
      inputProps={{
        style: { paddingRight: '4px' }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" style={{ marginLeft: '0px' }}>
            {value ? (
              <UilTimes
                className="cursor-pointer"
                size={'19'}
                color="#99a1aa"
                onClick={e => handleChange('')}
              />
            ) : (
              <></>
            )}
          </InputAdornment>
        )
      }}
    />
  );
});

export default GridIdField;
