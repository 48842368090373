import {
  emailRegx,
  regexValidateEmail,
  regexValidateExactPhone,
  regexValidatePhone
} from '../schemas/genericSchemas';

/**
 * @name @validateEmail
 * @description validate email address
 * @requires string email or anchor element where email in textContent
 * @returns Boolean (True if matched)
 * */
export const validateEmail = string => {
  let email = getPlainStringFromHTMLElementAsString(string);
  return emailRegx.test(String(email).toLowerCase());
};

export const decodeHTML = html => {
  var txt = document.createElement('span');
  txt.innerHTML = html;
  return txt.textContent || txt.innerText;
};

export const encodeHTML = html => {
  var txt = document.createElement('textarea');
  txt.innerText = html;
  return txt.innerHTML;
};

const escapeHtml = str => {
  return str
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/\|\|/g, '');
};

/**
 * @name @getPlainStringFromHTMLElementAsString
 * @description check and extract the string from the stringified HTML element
 * @requires string email or stringified HTML element
 * @returns String
 * */
export const getPlainStringFromHTMLElementAsString = string => {
  let innerContent = string;
  let htmlObject = document.createElement('div');
  htmlObject.innerHTML = string;
  if (
    htmlObject.children.length &&
    htmlObject.children[0] &&
    htmlObject.children[0].nodeType === 1
  ) {
    // innerContent = htmlObject.textContent;
    innerContent = decodeHTML(string);

    // remove html invalid code
    if (innerContent.indexOf('href=') !== -1) {
      innerContent = decodeHTML(innerContent);
    }

    // remove html if email address in message and only print email address as a string
    innerContent = removeHTMLfromEmailAddressInMessage(
      innerContent,
      htmlObject
    );

    // remove html if url in message and only print url as a string
    innerContent = removeHTMLfromUrlInMessage(innerContent, htmlObject);

    // remove BR tag and double pipes from string if exist
    innerContent = removeBRTagFromString(innerContent);
  } else {
    innerContent = encodeHTML(string);
    innerContent = escapeHtml(innerContent);
    if (typeof innerContent === 'string' && innerContent.includes('&#')) {
      // resolved emoji issue on sms chat
      innerContent = decodeHTML(innerContent);
    }

    innerContent = removeBRTagFromString(innerContent);
  }

  return innerContent;
};

export const removeHTMLfromEmailAddressInMessage = (string, html) => {
  let email = validateEmailFromSentence(string);
  if (email.hasEmailAddress) {
    return html.innerText;
  }
  return string;
};
export const removeHTMLfromUrlInMessage = (string, html) => {
  let url = validateUrlFromSentence(string);
  if (url.hasUrl) {
    return html.innerText;
  }
  return string;
};

export const removeBRTagFromString = str => {
  return str.replace(/<br\s*\/?>/gi, ' ');
};

/**
 * @name @validatePhone
 * @description validate phone number https://regex101.com/r/HBt0JN/1
 * @requires numbers
 * @returns Boolean (True if matched)
 * */
export const validatePhone = number => {
  return regexValidateExactPhone.test(Number(number));
};
/**
 * @name @validatePhoneFromSentence
 * @description validate phone number from a sentence
 * @requires string
 * @returns phone number
 * */
export const validatePhoneFromSentence = string => {
  let phoneNumber =
    typeof string === 'string' ? string.match(regexValidatePhone) : null;
  let hasPhoneNumber = regexValidatePhone.test(string);
  phoneNumber = phoneNumber ? phoneNumber[0]?.trim() : null;
  return {
    hasPhoneNumber,
    phoneNumber
  };
};
/**
 * @name @validateEmailFromSentence
 * @description validate phone number from a sentence
 * @requires string
 * @returns phone number
 * */
export const validateEmailFromSentence = string => {
  let email =
    typeof string === 'string' ? string.match(regexValidateEmail) : null;
  let hasEmailAddress = regexValidateEmail.test(string);
  email = email ? email[0]?.trim() : null;
  return {
    email,
    hasEmailAddress
  };
};

/**
 * @name @validateUrlFromSentence
 * @description validate URL from a sentence
 * @requires string
 * @returns string
 * */
export const validateUrlFromSentence = string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  let url = typeof string === 'string' ? string.match(urlRegex) : null;
  let hasUrl = urlRegex.test(string);
  url = url ? url[0]?.trim() : null;
  return {
    url,
    hasUrl
  };
};
