export const fr_widget = {
  // widget editor
  widget_heading: 'Editor de widgets',
  sidebar_item1: 'Configuración de la apariencia',
  item1_child1: 'Editor de widgets',
  widget_companies: 'Compañías',
  widget_save: 'Save',
  no_companies_msg:
    'Actualmente no hay empresas abiertas, haga clic en "Buscar empresas" para cambiar la configuración',
  collapse_item1: 'Integrar widget',
  collapse_item1_tag:
    'Copie o instale el código del widget para publicar su widget de chat',
  collapse_item2: 'Apariencia',
  collapse_item2_tag:
    'Elija de una lista de avatares de chat, colores o agregue los suyos propios',
  customize_mobile_Check:
    'Personalizar la configuración para dispositivos móviles',
  tab_desktop: 'Escritorio',
  tab_mobile: 'Móvil',
  select_type: 'Seleccionar tipo de widget',
  theme_color: 'Color del tema',
  chat_avatar: 'Avatar de chat',
  upload_image: 'Cargar',
  enable_avatar_name: 'Personalizar nombre de avatar',
  avatar_name: 'Nombre del avatar',
  avatar_name_tooltip:
    'Cambiar este nombre reemplazará el título del avatar existente.',
  chat_avatar_tooltip:
    'La selección de varios avatares dará como resultado una imagen diferente cada vez que se cargue el widget de chat en su sitio web',
  custom_images: 'Mis subidas',
  default: 'Imágenes predeterminadas',
  widget_script: 'Guión de widget',
  copy_snippet: 'Copiar fragmento',
  widget_or: 'O',
  easy_install: 'Instalar fácilmente en',
  wordpress_plugin: 'Wordpress a través de un complemento',
  send_developer: 'Enviar a tu desarrolladora',
  designer_email: 'Correo electrónico del diseñador',
  send_instruction: 'Enviar instrucciones',
  widget_error_info:
    'Presiona enter para agregar la dirección de correo electrónico.'
};

export default fr_widget;
