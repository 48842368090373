import React from 'react';
import { useHistory } from 'react-router-dom';
import Tracker from 'app/tracking/tracking-service';
import { Enums } from 'services/dropdown-enums';

const RouteChangeTracker = () => {
  const history = useHistory();
  history.listen((location, action) => {
    console.log('route change tracking', location.pathname, action);
    Tracker.PageRoute(
      {
        hitType: 'pageview',
        page: location.pathname
      },
      Enums.TrackerType.GoogleAnalytics
    );
  });

  return <div></div>;
};

export default RouteChangeTracker;
