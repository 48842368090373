import config from 'config';
import { Enums, TimeIntervalsEnum } from 'services/dropdown-enums';
import {
  generateDurationOptions,
  getCookie
} from 'services/utils/generic.methods';
import storage from 'services/utils/storage';
import { errors } from 'services/utils/variables';
import { initializeVissibleCards } from 'pages/portal/dashboard/filters/constants';
import { ReportTypes } from 'pages/portal/modal/CreateReport/constant';
import store from 'store';
import { CRMRoles } from 'services/utils/specific-conditions';
const RoleEngine = (companyRelationship, userType, userRoles, user = null) => {
  switch (companyRelationship) {
    case Enums.Relationship.Administrator:
      return handleAdministrator(userType, userRoles, user);
    case Enums.Relationship.Partner:
      return handlePartner(userType, userRoles, user);
    case Enums.Relationship.Customer:
      return handleCustomer(userType, userRoles, user);
    default:
      return handlePartner(userType, userRoles, user);
  }
};
const attachUserSpecificRoleInfo = (
  roles,
  userType,
  hasRelationshipAdministrator,
  user
) => {
  // attaching isOperator Info.
  roles.isOperator = userType === Enums.UserType.Operator;
  // attaching isAgent Info.
  roles.isAgent = userType === Enums.UserType.Agent;
  roles.isAdminCompany = !!hasRelationshipAdministrator;
  roles.isDefaultUser = userType === Enums.UserType.Default;

  // added script editor permission
  if (
    user &&
    user?.UserRoles &&
    user?.UserRoles.includes(Enums.Roles.ScriptEditor)
  ) {
    roles = allowGrantedRolesForEdit(roles, ['script']);
  }
  if (
    user &&
    user?.UserRoles &&
    roles.isOperator &&
    user?.UserRoles.includes(Enums.Roles.OpsManager)
  ) {
    roles = allowGrantedRolesForView(roles, ['operations']);
  }

  roles = handleCRMSwitchTo(roles, user);

  return roles;
};

const handleCRMSwitchTo = (roles, user) => {
  if (
    user?.RefreshToken &&
    CRMRoles.some(role => user?.UserRoles.includes(role))
  ) {
    roles.switch.allowCRM = true;
  }
  return roles;
};

const handleAdministrator = (userType, userRoles, user) => {
  let roles = getDefaultRoles();
  roles.messaging.allowMessaging = true;
  roles.report.reportTypes = ReportTypes();
  roles = attachUserSpecificRoleInfo(roles, userType, true, user);

  switch (userType) {
    case Enums.UserType.Agent:
      roles.analytics.view = false;
      roles.messaging.allowMessagingAccess =
        !!user?.NewMessagingDashboardEnabled;
      roles.messaging.selectedComponentType = user?.NewMessagingDashboardEnabled
        ? Enums.MessagingComponentType.MDWrapper
        : Enums.MessagingComponentType.SpecialMessageComp;
      roles = showAndEncryptColumns(roles, userRoles);
      roles.messaging.showIncomingMessagesTab = true;
      roles.messaging.defaultChatRoomTab = 0;
      roles.messaging.showActiveInactiveToggle = true;
      roles.messaging.showBottomNavigationSection = true;
      break;
    case Enums.UserType.Operator:
      // roles.analytics.view = false;
      roles.messaging.allowMessagingAccess =
        !!user?.NewMessagingDashboardEnabled;
      roles.messaging.selectedComponentType = user?.NewMessagingDashboardEnabled
        ? Enums.MessagingComponentType.MDWrapper
        : Enums.MessagingComponentType.SpecialMessageComp;
      roles.messaging.showIncomingMessagesTab = true;
      roles.messaging.defaultChatRoomTab = 0;
      roles.messaging.showActiveInactiveToggle = true;
      roles.messaging.showBottomNavigationSection = true;
      roles.showAddStatements = true;
      break;
    case Enums.UserType.Default:
      // roles.analytics.view = false;
      roles.messaging.allowMessagingAccess = true;
      roles.messaging.selectedComponentType =
        Enums.MessagingComponentType.MDWrapper;
      roles.messaging.showIncomingMessagesTab = false;
      roles.messaging.defaultChatRoomTab = 0;
      roles.messaging.showActiveInactiveToggle = true;
      roles.messaging.showBottomNavigationSection = true;
      roles.showAddStatements = false;
      roles.messaging.chatOperations = {
        status: false,
        error: errors.defaultUserMD
      };
      break;
    default:
      break;
  }

  roles = AdministratorPermissions.Get(roles, userRoles);
  roles = hideSidebarMenuItems(roles, user, ['messaging', 'settings']);
  return roles;
};
const handlePartner = (userType, userRoles, user) => {
  let roles = getDefaultRoles();
  roles.report.reportTypes = filterReportTypes(
    userRoles,
    Enums.Relationship.Partner
  );
  roles = attachUserSpecificRoleInfo(roles, userType, false, user);

  switch (userType) {
    case Enums.UserType.Agent:
      roles.analytics.view = false;
      roles.messaging.allowMessaging = true;
      roles.messaging.allowMessagingAccess =
        !!user?.NewMessagingDashboardEnabled;
      roles.messaging.selectedComponentType = user?.NewMessagingDashboardEnabled
        ? Enums.MessagingComponentType.MDWrapper
        : Enums.MessagingComponentType.SpecialMessageComp;
      roles.messaging.showIncomingMessagesTab = true;
      roles.messaging.showMessageLeadsTab = user?.OptInFeatureEnabled;
      roles.messaging.defaultChatRoomTab = 0;
      roles.messaging.showActiveInactiveToggle = true;
      roles.messaging.showBottomNavigationSection = true;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles = PartnerPermissions.Agent(roles, userRoles);
      roles.showAddStatements = true;
      break;
    case Enums.UserType.Operator:
      roles.messaging.allowMessaging = true;
      roles.messaging.allowMessagingAccess =
        !!user?.NewMessagingDashboardEnabled;
      roles.messaging.selectedComponentType = user?.NewMessagingDashboardEnabled
        ? Enums.MessagingComponentType.MDWrapper
        : Enums.MessagingComponentType.SpecialMessageComp;
      roles.messaging.showActiveInactiveToggle = true;
      roles.messaging.showBottomNavigationSection = true;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles.messaging.showMessageLeadsTab = user?.OptInFeatureEnabled;
      roles.messaging.defaultChatRoomTab = 0;
      roles.messaging.showIncomingMessagesTab = true;
      roles = PartnerPermissions.Operator(roles, userRoles);
      roles.showAddStatements = true;
      break;
    case Enums.UserType.Default:
      // Dashboard is not for Default users of Customers & Partners
      roles.messaging.allowMessaging = true;
      roles.messaging.allowMessagingAccess =
        !!user?.NewMessagingDashboardEnabled;
      roles.messaging.selectedComponentType = user?.NewMessagingDashboardEnabled
        ? Enums.MessagingComponentType.MDWrapper
        : Enums.MessagingComponentType.SignUpForm;
      roles.messaging.chatOperations = {
        status: !user?.NewMessagingDashboardEnabled,
        error: errors.defaultUserMD
      };
      roles.messaging.showBottomNavigationSection = true;
      roles.messaging.showIncomingMessagesTab = false;
      roles.messaging.showMessageLeadsTab = user?.OptInFeatureEnabled;
      roles.messaging.defaultChatRoomTab = 1;
      roles.messaging.showActiveInactiveToggle = false;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Day,
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles.showAddStatements = false;
      roles = PartnerPermissions.Default(roles, userRoles);
      break;
    default:
      break;
  }
  roles = hideSidebarMenuItems(roles, user, ['messaging', 'settings']);
  return roles;
};
const handleCustomer = (userType, userRoles, user) => {
  let roles = getDefaultRoles();
  roles.report.reportTypes = filterReportTypes(
    userRoles,
    Enums.Relationship.Customer
  );
  roles.analytics.filters.allowCompanyKey = false;
  roles.analytics.filters.allowBusinessType = false;
  roles = attachUserSpecificRoleInfo(roles, userType, false, user);

  switch (userType) {
    case Enums.UserType.Agent:
      roles.analytics.view = false;
      roles.messaging.allowMessaging = true;
      roles.messaging.allowMessagingAccess =
        !!user?.NewMessagingDashboardEnabled;
      roles.messaging.selectedComponentType = user?.NewMessagingDashboardEnabled
        ? Enums.MessagingComponentType.MDWrapper
        : Enums.MessagingComponentType.SpecialMessageComp;
      roles.messaging.showIncomingMessagesTab = true;
      roles.messaging.showMessageLeadsTab = user?.OptInFeatureEnabled;
      roles.messaging.showActiveInactiveToggle = true;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles.messaging.showBottomNavigationSection = true;
      roles = CustomerPermissions.Agent(roles, userRoles);
      roles.showAddStatements = true;
      break;
    case Enums.UserType.Operator:
      roles.messaging.allowMessaging = true;
      roles.messaging.allowMessagingAccess =
        !!user?.NewMessagingDashboardEnabled;
      roles.messaging.selectedComponentType = user?.NewMessagingDashboardEnabled
        ? Enums.MessagingComponentType.MDWrapper
        : Enums.MessagingComponentType.SpecialMessageComp;
      roles.messaging.showIncomingMessagesTab = true;
      roles.messaging.showActiveInactiveToggle = true;
      roles.messaging.showBottomNavigationSection = true;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles.messaging.showMessageLeadsTab = user?.OptInFeatureEnabled;
      roles.showAddStatements = true;
      roles = CustomerPermissions.Operator(roles, userRoles);
      break;
    case Enums.UserType.Default:
      // Dashboard is not for Default users of Customers & Partners
      roles.messaging.allowMessaging = true;
      roles.messaging.allowMessagingAccess =
        !!user?.NewMessagingDashboardEnabled;
      roles.messaging.selectedComponentType = user?.NewMessagingDashboardEnabled
        ? Enums.MessagingComponentType.MDWrapper
        : Enums.MessagingComponentType.SignUpForm;
      roles.messaging.chatOperations = {
        status: true,
        error: errors.defaultUserMD
      };
      roles.messaging.showIncomingMessagesTab = false;
      roles.messaging.showBottomNavigationSection = false;
      roles.messaging.defaultChatRoomTab = 1;
      roles.messaging.showActiveInactiveToggle = false;
      roles.analytics.durationOptions = generateDurationOptions([
        TimeIntervalsEnum.Day,
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ]);
      roles.messaging.showMessageLeadsTab = user?.OptInFeatureEnabled;
      roles.showAddStatements = false;
      roles = CustomerPermissions.Default(roles, userRoles);
      break;
    default:
      break;
  }
  if (userRoles.includes('MessagingLeadsAccessOnly')) {
    //areas that will just be displayed
    var areas = [
      'home',
      'analytics',
      'chat',
      'company',
      'lead',
      'onBoarding',
      'report',
      'script',
      'switch',
      'operations'
    ];
    areas.forEach(t => {
      roles[t].displayOnly = true;
    });
    roles.messaging.allowMessaging = true;
    roles.messaging.allowMessagingAccess = !!user?.NewMessagingDashboardEnabled;
    roles.messaging.selectedComponentType = user?.NewMessagingDashboardEnabled
      ? Enums.MessagingComponentType.MDWrapper
      : Enums.MessagingComponentType.SignUpForm;
    roles.messaging.chatOperations = {
      status: true,
      error: errors.defaultUserMD
    };
    roles.messaging.showIncomingMessagesTab = false;
    roles.messaging.defaultChatRoomTab = 1;
    roles.messaging.showActiveInactiveToggle = false;
    roles.messaging.showMessageLeadsTab = user?.OptInFeatureEnabled;
    roles.settings.view = false;
    roles.switch.allowPortal = false;
    roles.profileNav.changePassword = false;
    roles.profileNav.profileView = false;
    roles.profileNav.portalLanguage = false;
    roles.profileNav.theme = false;
    roles.showLimitedAccessWarning = true;
    roles.greyOutNonAccessibleChats = true;
    roles.showAddStatements = false;
  }

  roles = attachUserSpecificRoleInfo(roles, userType, false, user);
  roles = hideSidebarMenuItems(roles, user, ['messaging', 'settings']);
  return roles;
};
const AdministratorPermissions = {
  Get: (roles, userRoles) => {
    roles.switch.allowPortal = true;
    if (userRoles.includes(Enums.Roles.SuperAdministrator)) {
      roles = applySuperAdminRoles(roles);
    }
    if (userRoles.includes(Enums.Roles.Administrator)) {
      const adminComponents = superAdministratorGrantedRoles;
      roles = allowGrantedRolesForView(roles, adminComponents);
      roles = allowGrantedRolesForEdit(roles, adminComponents);
      // Administrator is allowed to pick others chat
      roles.messaging.canPickOthersChat = true;
    }
    roles = applySpecialFieldsPermissions(roles, userRoles);
    // UIUX-610 - If the userType is Agent and he has role among anyone of these i.e. Admin, SuperAdmin, Limited Admin, then we will show the Analytics
    if (userRoles.includes(Enums.Roles.LimitedAdministrator)) {
      const limitedAdminComponents = [
        'analytics',
        'lead',
        'report',
        'onBoarding'
      ];
      roles = allowGrantedRolesForView(roles, limitedAdminComponents);
      roles = allowGrantedRolesForEdit(roles, limitedAdminComponents);
    }
    if (
      userRoles.includes(Enums.Roles.PartnerAdministrator) ||
      userRoles.includes(Enums.Roles.LimitedAdministrator)
    ) {
      roles = allowGrantedRolesForView(roles, []);
      roles = allowGrantedRolesForEdit(roles, []);
    }
    if (checkIfAnySupportedRoleApplied(userRoles)) {
      roles = setupLegacyRoles(roles, userRoles);
    }

    // MD-250 encrypt data when admin company and user type is not admin
    // if (
    //   !userRoles.includes(Enums.Roles.Administrator) &&
    //   !userRoles.includes(Enums.Roles.LimitedAdministrator)
    // ) {
    //   console.log('123');
    //   roles.enableEncryption = true;
    // }
    return roles;
  }
};
const PartnerPermissions = {
  Get: (roles, userRoles, allowGrantedRolesByDefault) => {
    const grantedViewRoles = ['company', 'chat', 'lead', 'onBoarding'];
    // Admin can edit company
    const grantedEditRolesForAdministrator = ['company'];
    let grantedEditRoles = ['onBoarding'];
    //add company editor for operator and default roles.
    // UIUX-786 condition inplemented
    if (!userRoles.includes(Enums.Roles.CompanyViewer)) {
      if (allowGrantedRolesByDefault) {
        grantedEditRoles.push('company');
      }
    }
    // UIUX-786 end

    if (userRoles.includes(Enums.Roles.SuperAdministrator)) {
      roles = applySuperAdminRoles(roles);
    }

    if (userRoles.includes(Enums.Roles.Administrator)) {
      // Administrator is allowed to pick others chat
      roles.messaging.canPickOthersChat = true;
    }
    roles = applySpecialFieldsPermissions(roles, userRoles);
    if (userRoles.includes(Enums.Roles.PartnerSupervisor)) {
      roles = allowGrantedRolesForView(roles, [...grantedViewRoles]);
      roles = allowGrantedRolesForEdit(roles, ['company', ...grantedEditRoles]);
      roles.switch.allowPortal = true;
    }
    // UIUX-610 - If the userType is Agent and he has role among anyone of these i.e. Admin, SuperAdmin, Limited Admin, then we will show the Analytics
    if (
      userRoles.includes(Enums.Roles.LimitedAdministrator) ||
      userRoles.includes(Enums.Roles.Administrator)
    ) {
      roles = allowGrantedRolesForView(roles, ['analytics']);
      roles = allowGrantedRolesForEdit(roles, [
        ...grantedEditRolesForAdministrator,
        ...grantedEditRoles
      ]);
      roles.switch.allowPortal = true;
    }
    if (
      userRoles.includes(Enums.Roles.PartnerAdministrator) ||
      userRoles.includes(Enums.Roles.LimitedAdministrator)
    ) {
      roles = allowGrantedRolesForView(roles, [...grantedViewRoles]);
      roles = allowGrantedRolesForEdit(roles, [...grantedEditRoles]);
      roles.switch.allowPortal = true;
    }
    if (
      allowGrantedRolesByDefault ||
      checkIfAnySupportedRoleApplied(userRoles)
    ) {
      roles.switch.allowPortal = true;
      roles = allowGrantedRolesForView(roles, [...grantedViewRoles]);
      roles = allowGrantedRolesForEdit(roles, [...grantedEditRoles]);
    }
    roles = setupLegacyRoles(roles, userRoles);
    return roles;
  },
  Agent: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return PartnerPermissions.Get(roles, userRoles, false);
  },
  Operator: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return PartnerPermissions.Get(roles, userRoles, true);
  },
  Default: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return PartnerPermissions.Get(roles, userRoles, true);
  }
};
const CustomerPermissions = {
  Get: (roles, userRoles, allowGrantedRolesByDefault) => {
    roles.analytics.graphs = {
      ...roles.analytics.graphs,
      // 'Chats vs Leads': false,    // comented Charts will be vissible
      // 'Features Usage': false,
      // 'Chat Referrals': false,
      // 'Chat Stats By': false,
      'Accounts Activation': false,
      'Top Inactive Billers': false,
      'Top/Bottom Lead Accounts': false,
      // 'Budget/Pacing': false,
      // 'Agent Images CTR': false,
      Accounts: false
    };

    roles.analytics.filters = {
      ...roles.analytics.filters,
      allowCompanyKey: false,
      allowBusinessType: false
    };
    //add company editor for operator and default roles.
    const grantedViewRoles = ['chat', 'lead'];
    const grantedEditRoles = [];
    // Admin can edit company
    const grantedEditRolesForAdministrator = ['company'];
    // UIUX-786 condition inplemented
    if (!userRoles.includes(Enums.Roles.CompanyViewer)) {
      if (allowGrantedRolesByDefault) {
        grantedViewRoles.push('company');
        grantedEditRoles.push('company');
      }
    }
    // UIUX-786 end--
    roles = applySpecialFieldsPermissions(roles, userRoles);
    if (userRoles.includes(Enums.Roles.SuperAdministrator)) {
      roles = applySuperAdminRoles(roles);
    }
    // UIUX-610 - If the userType is Agent and he has role among anyone of these i.e. Admin, SuperAdmin, Limited Admin, then we will show the Analytics
    if (
      userRoles.includes(Enums.Roles.LimitedAdministrator) ||
      userRoles.includes(Enums.Roles.Administrator)
    ) {
      roles = allowGrantedRolesForView(roles, ['analytics']);
      roles = allowGrantedRolesForEdit(roles, [
        ...grantedEditRolesForAdministrator,
        ...grantedEditRoles
      ]);
      roles.switch.allowPortal = true;
    }
    if (
      userRoles.includes(Enums.Roles.PartnerSupervisor) ||
      userRoles.includes(Enums.Roles.PartnerAdministrator) ||
      userRoles.includes(Enums.Roles.LimitedAdministrator)
    ) {
      roles = allowGrantedRolesForView(roles, grantedViewRoles);
      roles = allowGrantedRolesForEdit(roles, grantedEditRoles);
      roles.switch.allowPortal = true;
    }
    if (userRoles.includes(Enums.Roles.Administrator)) {
      roles = allowGrantedRolesForView(roles, [
        'onBoarding',
        ...grantedViewRoles
      ]);
      roles = allowGrantedRolesForEdit(roles, [
        'onBoarding',
        ...grantedEditRoles
      ]);
      roles.switch.allowPortal = true;
      // Administrator is allowed to pick others chat
      roles.messaging.canPickOthersChat = true;
    }

    if (
      allowGrantedRolesByDefault ||
      checkIfAnySupportedRoleApplied(userRoles)
    ) {
      roles.switch.allowPortal = true;
      roles = allowGrantedRolesForView(roles, [...grantedViewRoles]);
      roles = allowGrantedRolesForEdit(roles, [...grantedEditRoles]);
    }
    roles = setupLegacyRoles(roles, userRoles);
    return roles;
  },
  Agent: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return CustomerPermissions.Get(roles, userRoles, false);
  },
  Operator: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return CustomerPermissions.Get(roles, userRoles, true);
  },
  Default: (roles, userRoles) => {
    // Same role as agent just allow granted roles by default
    return CustomerPermissions.Get(roles, userRoles, true);
  }
};
const checkIfAnySupportedRoleApplied = userRoles => {
  let appliedRoles = supportedRoles.filter(element =>
    userRoles.includes(element)
  );
  if (appliedRoles.length > 0) {
    return true;
  }
  return false;
};

const customRoles = [
  Enums.Roles.CompanyViewer,
  Enums.Roles.ChatViewer,
  Enums.Roles.LeadViewer,
  Enums.Roles.ProvisioningRequestViewer,
  Enums.Roles.Reporter // Reporter is not Legacy
];
const legacyRoles = [Enums.Roles.Reporter];
const supportedRoles = [...customRoles, ...legacyRoles];
// UIUX-610 - If the userType is Agent and he has role among anyone of these i.e. Admin, SuperAdmin, Limited Admin, then we will show the Analytics
const superAdministratorGrantedRoles = [
  'analytics',
  'company',
  'lead',
  'chat',
  'report',
  'onBoarding'
];
const applySuperAdminRoles = roles => {
  roles = allowGrantedRolesForView(roles, superAdministratorGrantedRoles);
  roles = allowGrantedRolesForEdit(roles, superAdministratorGrantedRoles);
  roles.switch.allowPortal = true;
  return roles;
};
const applySpecialFieldsPermissions = (roles, userRoles) => {
  for (let entity in roles) {
    if (roles[entity].specialFields) {
      let keyArray = Object.keys(roles[entity].specialFields);
      keyArray.forEach(key => {
        switch (key) {
          case 'billingEmailAddress':
          case 'invoiceEmail':
            if (
              // case 1
              // if company relation administrator &&
              // BM, Admin , superAdmin
              // can view and edit
              (roles.isAdminCompany &&
                (userRoles.includes(Enums.Roles.BillingManager) ||
                  userRoles.includes(Enums.Roles.Administrator) ||
                  userRoles.includes(Enums.Roles.SuperAdministrator))) ||
              // case 2
              // company relation Customer/ partner &&
              // userType default
              // can view and edit
              (!roles.isAdminCompany && roles.isDefaultUser)
            ) {
              roles[entity].specialFields[key].view = true;
              roles[entity].specialFields[key].edit = true;
            }
            break;
          case 'provisionedCompanyId':
            if (
              // case 1
              // if company relation administrator &&
              // have a role  Admin/SuperAdmin/LimitedAdmin
              // can edit
              roles.isAdminCompany &&
              (userRoles.includes(Enums.Roles.LimitedAdministrator) ||
                userRoles.includes(Enums.Roles.Administrator) ||
                userRoles.includes(Enums.Roles.SuperAdministrator))
            ) {
              roles[entity].specialFields[key].view = true;
              roles[entity].specialFields[key].edit = true;
            }
            break;
          default:
            break;
        }
      });
    }
  }
  return roles;
};
const setupLegacyRoles = (roles, userRoles) => {
  if (userRoles.includes(Enums.Roles.CompanyViewer)) {
    roles = allowGrantedRolesForView(roles, ['company']);
    let writePermissions = [];
    userRoles.includes(Enums.Roles.CompanyEditor) &&
      writePermissions.push('company');
    roles = allowGrantedRolesForEdit(roles, writePermissions);
  }
  if (userRoles.includes(Enums.Roles.ChatViewer)) {
    roles = allowGrantedRolesForView(roles, ['chat']);
    let writePermissions = [];
    userRoles.includes(Enums.Roles.ChatEditor) && writePermissions.push('chat');
    roles = allowGrantedRolesForEdit(roles, writePermissions);
  }
  if (userRoles.includes(Enums.Roles.LeadViewer)) {
    roles = allowGrantedRolesForView(roles, ['lead']);
    let writePermissions = [];
    userRoles.includes(Enums.Roles.LeadEditor) && writePermissions.push('lead');
    roles = allowGrantedRolesForEdit(roles, writePermissions);
  }
  if (userRoles.includes(Enums.Roles.ProvisioningRequestViewer)) {
    roles = allowGrantedRolesForView(roles, ['onBoarding']);
    if (!roles.isAdminCompany)
      roles = denyGrantedRolesForEdit(roles, ['onBoarding']);
    let writePermissions = [];
    userRoles.includes(Enums.Roles.ProvisioningRequestEditor) &&
      writePermissions.push('onBoarding');
    roles = allowGrantedRolesForEdit(roles, writePermissions);
  }
  if (userRoles.includes(Enums.Roles.Reporter)) {
    roles = allowGrantedRolesForView(roles, ['report']);
    roles = allowGrantedRolesForEdit(roles, ['report']);
    roles.switch.allowPortal = true;
  }
  if (userRoles.includes(Enums.Roles.ContactInformationEditor)) {
    roles.onBoarding.contactInformationEditor = true;
    roles.switch.allowPortal = true;
  }
  return roles;
};
const allowGrantedRolesForView = (roles, allowedRoleArray) => {
  allowedRoleArray.forEach(roleName => {
    roles[roleName].view = true;
  });
  return roles;
};
const allowGrantedRolesForEdit = (roles, allowedRoleArray) => {
  allowedRoleArray.forEach(roleName => {
    roles[roleName].edit = true;
  });
  return roles;
};
const denyGrantedRolesForEdit = (roles, allowedRoleArray) => {
  allowedRoleArray.forEach(roleName => {
    roles[roleName].edit = false;
  });
  return roles;
};

// hide sidebar menu items base on conditions
const hideSidebarMenuItems = (roles, user, menuItems = []) => {
  menuItems.forEach(menu => {
    // hide messaging menu if HideMessagingDashboard is true
    if (menu === 'messaging') {
      roles.messaging.allowMessaging = user?.NewMessagingDashboardEnabled
        ? user?.NewMessagingDashboardEnabled
        : !!!user?.HideMessagingDashboard;
    }

    // hide settings menu
    if (menu === 'settings') {
      roles = hideSettingsSection(roles, user);
    }
  });

  return roles;
};

//  hide settings menu from sidebar if owner company exist in our config companies array
const hideSettingsSection = (roles, user = null) => {
  if (user) {
    if (user?.OwnerCompanyKey) {
      let ownerCompanyKeys =
        config.hideSectionSettingsForOwnerCompanyKeys.split(',');
      if (ownerCompanyKeys.includes(user?.OwnerCompanyKey)) {
        roles.settings.view = false;
      }
    }
    let dynamicMeta = getCookie('DynamicMeta')
      ? JSON.parse(getCookie('DynamicMeta'))
      : null;
    if (dynamicMeta?.isDirectHost || dynamicMeta?.isReferrerHost) {
      roles.settings.widgetEditorPluginLink.view = true;
    }
  }

  return roles;
};

// TODO: refactoring required here
const filterReportTypes = (roles = [], relationship) => {
  let CommonTypes = [
    Enums.ReportType.Traffic_Report_By_Date,
    Enums.ReportType.Traffic_Summary,
    Enums.ReportType.Leads,
    Enums.ReportType.Billable_Leads,
    Enums.ReportType.Transcripts
  ];
  let Administrator = [
    ...CommonTypes,
    Enums.ReportType.Calls_Summary,
    Enums.ReportType.Paused_Companies,
    Enums.ReportType.Often_Over_Budget_Companies,
    Enums.ReportType.Nearly_Over_Budget_Companies,
    Enums.ReportType.Invalid_Email_Addresses,
    Enums.ReportType.Companies_With_Traffic_Pacing
  ];
  let Partner_Administrator_Supervisor = [
    ...CommonTypes,
    ...Administrator,
    Enums.ReportType.Active_Companies_With_Low_Traffic
  ];
  let types = [];

  if (relationship === Enums.Relationship.Partner) {
    if (
      roles.includes(Enums.Roles.Administrator) ||
      roles.includes(Enums.Roles.LimitedAdministrator) ||
      roles.includes(Enums.Roles.Reviewer) ||
      roles.includes(Enums.Roles.ManagementDashboard) ||
      roles.includes(Enums.Roles.BasicAccountsManager) ||
      roles.includes(Enums.Roles.Designer) ||
      roles.includes(Enums.Roles.IsrTrainer) ||
      roles.includes(Enums.Roles.Reporter)
    ) {
      types = Administrator;
    }
    if (roles.includes(Enums.Roles.OpsManager)) {
      types = CommonTypes;
    }
    if (
      roles.includes(Enums.Roles.PartnerAdministrator) ||
      roles.includes(Enums.Roles.PartnerSupervisor)
    ) {
      types = Partner_Administrator_Supervisor;
    }
  } else if (relationship === Enums.Relationship.Customer) {
    types = [...CommonTypes, Enums.ReportType.Calls_Summary];
  }
  return ReportTypes(relationship).filter(rt => types.includes(rt.id));
};

const showAndEncryptColumns = (roles, userRoles) => {
  if (
    !userRoles.includes(Enums.Roles.Administrator) &&
    !userRoles.includes(Enums.Roles.LimitedAdministrator)
  ) {
    if (!userRoles.includes(Enums.Roles.ChatContactInformationViewer)) {
      roles.enableEncryption = true;
    } else {
      roles.enableEncryption = false;
    }
  }
  return roles;
};

const getDefaultRoles = () => {
  return {
    enableEncryption: false,
    isAppointmentScheduler: false,
    operatorsEnabled: false,
    switch: {
      allowPortal: false,
      allowCRM: false
    },
    messaging: {
      allowMessaging: false,
      allowMessagingAccess: false,
      selectedComponentType: Enums.MessagingComponentType.MDWrapper,
      chatOperations: { error: '', status: true },
      showSuggestion: true,
      canPickOthersChat: false,
      showIncomingMessagesTab: true,
      showMessageLeadsTab: false
    },
    operations: {
      view: false
    },
    home: {
      view: true
    },
    analytics: {
      view: true,
      quickStats: true,
      graphs: {
        ...initializeVissibleCards(true)
      },
      filters: {
        allowFeatures: true,
        allowDevices: true,
        allowUser: true,
        allowCompanyKey: true,
        //  UIUX-942 - Temporary hide from filter panel
        allowBusinessType: false,
        allowHours: true
      },
      durationOptions: generateDurationOptions([
        TimeIntervalsEnum.Day,
        TimeIntervalsEnum.Month,
        TimeIntervalsEnum.SixMonths
      ])
    },
    company: {
      view: false,
      edit: false,
      specialFields: {
        invoiceEmail: {
          view: false,
          edit: false
        }
      }
    },
    chat: {
      view: false,
      edit: false
    },
    lead: {
      view: false,
      edit: false
    },
    report: {
      view: false,
      edit: false,
      requestLeadChange: false,
      reportTypes: ReportTypes()
    },
    onBoarding: {
      view: false,
      edit: false,
      contactInformationEditor: false,
      specialFields: {
        billingEmailAddress: {
          view: false,
          edit: false
        },
        provisionedCompanyId: {
          view: false,
          edit: false
        }
      }
    },
    script: {
      view: true, // default true unit not in use
      edit: false
    },
    settings: {
      view: true,
      edit: false,
      widgetEditorPluginLink: { view: false }
    },
    profileNav: {
      changePassword: true,
      profileView: true,
      theme: true,
      portalLanguage: true
    }
  };
};

export const getReportViewColumnByRole = (columnName, reportType) => {
  let user = JSON.parse(storage.getItem('Profile'));
  const {
    general: { host }
  } = store.getState();
  user.host = host;
  return reportType
    ? reportColumnData[columnName](user, reportType)
    : reportColumnData[columnName](user);
};
const reportColumnData = {
  // Report Columns that hides as per roles/users
  clickRate: user => {
    return user.CompanyRelationship !== Enums.Relationship.Administrator;
  },
  blankChats: user => {
    return (
      user.CompanyRelationship !== Enums.Relationship.Administrator ||
      (!user.UserRoles.includes(Enums.Roles.OpsManager) &&
        user.UserRoles.includes(Enums.Roles.Reporter))
    );
  },
  leadConversion: user => {
    return user.CompanyRelationship !== Enums.Relationship.Administrator;
  },
  conversionToSales: user =>
    user.CompanyRelationship !== Enums.Relationship.Administrator,
  companyKey: user => user.CompanyRelationship === Enums.Relationship.Customer,
  companyName: user => user.CompanyRelationship === Enums.Relationship.Customer,
  smsChats: user => !canRead(user, 'report'),
  smsLeads: user => !canRead(user, 'report'),
  smsBillableLeads: user => !canRead(user, 'report'),
  exitPopupChats: user => !canRead(user, 'report'),
  exitPopupLeads: user => !canRead(user, 'report'),
  exitPopupBillableLeads: user => !canRead(user, 'report'),
  change: user => !canRead(user, 'report'),
  referrer: user => user.host.redactReferrer, //redact
  originalReferrer: user => user.host.redactReferrer, //redact
  landingReferrer: user => user.host.redactReferrer, //redact
  keywords: user => user.host.redactReferrer, //redact
  smsLead: user => !canRead(user, 'report'), // duplicate name with 's'
  exitPopupLead: user => !canRead(user, 'report'), // duplicate name with 's'
  attemptedCalls: user => !canRead(user, 'report'),
  completedCalls: user => !canRead(user, 'report'),
  agentChatCountAtAcceptance: user =>
    user.CompanyRelationship !== Enums.Relationship.Administrator
};

const canRead = (user, key = '') => {
  var roles = RoleEngine(
    user.CompanyRelationship,
    user.UserType,
    user.UserRoles
  );
  return roles[key].view;
};

export default RoleEngine;
