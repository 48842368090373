import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import getCroppedImg from './CropImage';
import './styles.scss';
import Cropper from 'react-easy-crop';
import { toast } from 'react-toastify';
import { uploadUserImage } from 'store/slices/users/userSlice';
import UilSpinner from '@iconscout/react-unicons/icons/uil-spinner';
import CropperControls from './CropperControls';
import { ToastTemplate } from 'services/utils/generic.methods';

const ImageCropper = ({
  eventHideModal,
  showModal,
  imageUrl,
  eventSetImage,
  userId,
  eventSetNewImage,
  imageType,
  allowApi = true,
  size = 'lg',
  customStyles = {}
}) => {
  const [crop, setCrop] = useState({ x: 1, y: 1 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [loading, setLoading] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const handleOnModalClose = () => {
    eventHideModal();
  };
  let flipped = false;
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const saveCroppedImage = useCallback(async () => {
    setLoading(true);
    try {
      const cropImage = await getCroppedImg(
        imageUrl,
        croppedAreaPixels,
        rotation,
        { horizontal: false, vertical: false },
        imageType
      );
      let img = new File([cropImage], 'userimage', {
        type: imageType,
        lastModified: new Date().getTime()
      });

      var form = new FormData();
      form.append(`file`, img);

      let imgUrl = URL.createObjectURL(cropImage);

      if (allowApi) {
        uploadUserImage(form, userId)
          .then(res => {
            if (res.data.success) {
              setCroppedImage(imgUrl);
              eventSetImage(res.data.data.imageCloudSecureUrl);
              handleOnModalClose();
              toast.success(
                ToastTemplate.success('User image uploaded successfully!')
              );
              setLoading(false);
            } else {
              toast.error(res.data.error);
              setLoading(false);
            }
          })
          .catch(err => {
            console.log(err);
            setLoading(false);
          });
      } else {
        eventSetImage(imgUrl, form, cropImage);
        setLoading(false);
        // handleOnModalClose();
      }
    } catch (ex) {
      toast.error(ex);
      setLoading(false);
    }
  }, [croppedAreaPixels, rotation]);
  const handleRotate = () => {
    setRotation(rotation + 90);
  };
  const onEnterHandler = () => {
    setRotation(0);
    setZoom(1);
    setCroppedImage(null);
    setCroppedAreaPixels(null);
  };
  const flipImage = async () => {
    flipped = !flipped;
    try {
      const mirrorImage = await getCroppedImg(
        imageUrl,
        null,
        rotation,
        {
          horizontal: flipped
        },
        imageType
      );
      eventSetNewImage(URL.createObjectURL(mirrorImage));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      show={showModal}
      onEnter={onEnterHandler}
      onHide={eventHideModal}
      id="cropperModal"
      keyboard={false}
      size={size}
      dialogClassName="cropperModal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="cropped-modal-header">
        <p>Edit Photo</p>
        <Button
          type="button"
          variant="outline-primary"
          className="passBtn btn-md btn-flex float-right"
          onClick={eventHideModal}
        >
          <span>Cancel</span>
        </Button>
      </Modal.Header>

      <Modal.Body className="cropped-modal-body">
        <div className={'cropContainer'} style={{ ...customStyles }}>
          <Cropper
            image={imageUrl}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            cropShape="round"
            showGrid={false}
            aspect={1}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="cropped-modal-footer">
        <div className="row" style={{ width: '100%' }}>
          <div className="col-md-6">
            <CropperControls
              handleRotate={handleRotate}
              flipImage={flipImage}
              zoom={zoom}
              setZoom={setZoom}
            />
          </div>
          <div className="col-md-6">
            <Button
              type="button"
              variant="primary"
              disabled={!showModal || loading}
              className="passBtn btn-md btn-flex float-right"
              onClick={saveCroppedImage}
            >
              <span>Save Photo</span>
              {loading && <UilSpinner className="spinner" />}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageCropper;
