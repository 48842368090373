export const fr_leadDetails = {
  heading1: 'Détails Lead',
  heading2: 'Notes de tête',
  heading3: 'DocuSign',
  heading4: 'Prise de rendez-vous',
  leadTooltip: "Le portail affiche jusqu'à 6 mois de données",
  name: {
    label: 'Nom',
    info: 'Le nom du client.'
  },
  phoneNo: {
    label: 'Pas de téléphone',
    info: 'Le numéro de téléphone du client.'
  },
  email: {
    label: 'Email',
    info: "L'adresse e-mail du client."
  },
  createdOn: {
    label: 'Créé sur',
    info: 'Lorsque le fil a été créé dans notre système.'
  },
  leadType: {
    label: 'Type de',
    info: "Le type de plomb par l'utilisateur CLASSIFIÉS manipulation le chat."
  },
  initiatedBy: {
    label: 'Initié par',
    info: 'Là où le chat a commencé à partir.'
  },
  username: {
    label: 'Envoyée par'
    //info: 'Where the chat was started from.'
  },
  notificationState: {
    label: 'Statut'
    //info: 'Where the chat was started from.'
  },
  sentBy: {
    label: 'Sent By'
    //info: 'Where the chat was started from.'
  },
  appointmentStartTime: {
    label: 'Heure de début'
    //info: 'Where the chat was started from.'
  },
  appointmentEndTime: {
    label: 'Heure de fin'
    // info: 'Where the chat was started from.'
  },
  appointmentStatus: {
    label: 'Statut du rendez-vous'
    //info: 'Where the chat was started from.'
  },
  documentVerified: {
    label: 'Document vérifié'
    //info: 'Where the chat was started from.'
  },
  docuSignNotificationState: {
    label: 'Statut du document DocuSign'
    //info: 'Where the chat was started from.'
  },
  called: {
    label: 'Appelé',
    info: "Est-ce que l'utilisateur tente de se connecter au visiteur et d'affaires."
  },
  attemptedCalls: {
    label: "Les tentatives d'appel",
    info: 'Nombre de tentatives de connexion appel.'
  },
  completedCalls: {
    label: 'Appels terminés',
    info: "Nombre de tentatives de connexion d'appel terminée."
  },
  keywords: {
    label: 'Mots clés',
    info: 'Fournir les mots clés si le système a pu capturer.'
  },
  flagAsProcessed: {
    label: 'Signaler comme traité',
    info: 'Signaler comme traité'
  },
  flagAsUnProcessed: {
    label: 'Signaler comme non traité',
    info: 'Signaler comme non traité'
  },
  forwardLead: {
    label: "vers l'avant",
    info: "vers l'avant"
  },
  replyToVisitor: {
    label: 'Répondre au visiteur',
    info: 'Répondre au visiteur'
  },
  viewChat: {
    label: 'Afficher la conversation',
    info: 'Afficher la conversation'
  },
  viewCompany: {
    label: "Voir l'entreprise",
    info: "Voir l'entreprise"
  },
  region: { label: 'Région', info: 'Région' },
  leadCategory: {
    label: 'Catégorie de prospect',
    info: 'Catégorie de prospect'
  },
  appliedRule: { label: 'Règle appliquée', info: 'Règle appliquée' },
  forwardLeadModal: {
    title: "vers l'avant",
    email_label: 'Adresse e-mail',
    email_placeholder: "Entrez l'e-mail",
    email_info: 'Appuyez sur Entrée pour ajouter une adresse e-mail',
    comma_seperated: '(séparée par des virgules)',
    note_label: 'Notes complémentaires',
    note_placeholder: 'Écrire une note',
    saveBtn: 'sauvegarder',
    sendBtn: 'envoyer',
    closeBtn: 'proche'
  }
};
export default fr_leadDetails;
