export const sp_hoursCallConnect = {
  heading1: 'Hora de llamada / conexión',
  heading2: 'Conectar llamada',
  heading3: 'Horario de conexión de llamadas',
  heading4: 'Horas de oficina',
  timeZone: {
    label: 'Zona horaria',
    info: 'La zona horaria del negocio y el número de conexión de llamada.'
  },
  quickConnectPhoneNumber: {
    label: 'Número de teléfono',
    info: 'La llamada de conexión es el número son sistema se conectará al visitante durante o después de las horas de trabajo.'
  },
  transcriptOverride: {
    label: 'Transcripción de anulación',
    info: 'Si se establece, el "Call Connect Transcripción" será entregado únicamente a "Los correos electrónicos Call Connect Transcripción".'
  },
  transcriptEmail: {
    label: 'Transcripción de correo electrónico',
    info: 'Insertar direcciones de correo electrónico para enviar Call Connect para Transcripción.'
  },
  useCallConnectNumberforCallerID: {
    label:
      'Utilizar la llamada en el número de conexión para el identificador de llamadas',
    info: 'Si se activa, utiliza el número de Call Connect para el identificador de llamadas; esto requiere que el número de ser validado en Twilio).'
  },
  companyNumberFirst: {
    label: 'Número de la compañía primero',
    info: 'Si se establece, Call Connect llamará automáticamente el número de la empresa primero.'
  },
  override: {
    label: 'Override',
    info: 'Si se establece, un número será seleccionado de la piscina nivel de la compañía de los números Twilio.'
  },
  officeHourMonitoring: {
    label: 'Supervisión de la hora de oficina',
    info: 'Si se activa, y se establecen las horas de oficina, la invitación se reducirá durante o después de las horas de oficina, según proceda; Si no se establecen las horas de oficina, la oficina se supone que es abierta y la invitación caerá de todos modos; Si está desactivado, se tendrán en cuenta las horas de oficina y simplemente siempre mostrarán la invitación. Esto aún está sujeta a la bandera "activa la monitorización".'
  },
  smsChatNumber: {
    label: 'Número de chat sms',
    info: 'Un número único de SMS comprado para la cuenta para habilitar SMS de texto-a-charla.'
  },
  visitorgreeting: {
    label: 'Saludo visitante',
    info: 'Mensaje para decir al visitante a través del teléfono cuando se conecta a través de la llamada connect [en blanco permiso para utilizar la configuración de host]; puede utilizar "<# = companyName #>" y "<# = # hostTitle>" en esta plantilla.'
  },
  visitorgreetingdelay: {
    label: 'Visitante retraso saludo',
    info: 'Número de segundos de espera antes de Visitantes saludo se reproduce. Muchas veces, una parte de este saludo se corta a medida que comienza a reproducirse antes de que el visitante empieza a escuchar.'
  },
  businessgreeting: {
    label: 'Saludo bussiness',
    info: 'Mensaje para decir a la empresa a través del teléfono cuando se conecta a través de llamada conectar [en blanco permiso para utilizar la configuración de host]; puede utilizar "<# = companyName #>" y "<# = # hostTitle>" en esta plantilla.'
  },
  businessgreetingdelay: {
    label: 'Bussiness saludo retardo',
    info: 'Número de segundos de espera antes de conectar la llamada al negocio. Esto es útil cuando la llamada es recogido por una máquina antes de ser transferido a un ser humano. Este retraso dependerá de cuánto tiempo el mensaje automático es. El propósito de tener este retraso es entregar el negocio saludo a un ser humano y no a la operadora automática de llamadas.'
  },
  monday: {
    label: 'Lunes'
  },
  tuesday: {
    label: 'Martes'
  },
  wednesday: {
    label: 'Miércolesy'
  },
  thursday: {
    label: 'Jueves'
  },
  friday: {
    label: 'Viernes'
  },
  saturday: {
    label: 'Sábado'
  },
  sunday: {
    label: 'Domingoy'
  },
  copyToAll: {
    label: 'Copiar a todos'
  }
};
export default sp_hoursCallConnect;
