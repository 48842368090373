import Tracker from 'app/tracking/tracking-service';
import {
  getSplashScreenMeta,
  pushSplashScreenMeta
} from 'store/slices/messagingChats/messagingChats';
import { Enums, getObjectKeyByValue } from 'services/dropdown-enums';
import storage from 'services/utils/storage';
import React from 'react';
import { useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import store from 'store';
import RestrictionModal from '../RestrictionModal';
import './SplashScreen.scss';

export const IncreaseProgress = meta => {
  setTimeout(() => {
    if (meta.hasCompleted) {
      meta.count = 100;
    }
    store.dispatch(pushSplashScreenMeta(meta));
  }, 1500);
};

const SplashScreen = ({ chatOperations }) => {
  let splashScreenMeta = useSelector(getSplashScreenMeta);
  let user = JSON.parse(storage.getItem('Profile'));
  if (splashScreenMeta.count >= 100) {
    setTimeout(() => {
      window.RTC.hasLoaded = true;
    }, 2000);
  }

  useEffect(() => {
    return () => {
      let CompanyRelationship = getObjectKeyByValue(
        Enums.Relationship,
        user?.CompanyRelationship
      );
      Tracker.EventsProvider(
        Enums.TrackerType.GoogleAnalytics
      ).Messaging.ChatConnectionEstablished(CompanyRelationship, '');
    };
  }, []);
  return (
    <>
      <div className="splash-screen" style={{ position: 'relative' }}>
        <RestrictionModal
          status={!chatOperations.status}
          message={chatOperations.error.split('!!')[1]}
          heading={chatOperations.error.split('!!')[0]}
        />
        <p style={{ textAlign: 'center' }}>
          {/* Please wait a moment while Messaging Dashboard is loading... */}
          Messaging Dashboard is loading, please wait.
        </p>
        {/* <p>{splashScreenMeta.text}</p> */}
        <p>{splashScreenMeta.count < 100 ? splashScreenMeta.count : 100} %</p>

        <ProgressBar
          animated
          now={splashScreenMeta.count}
          style={{ width: '50%' }}
        />
      </div>
    </>
  );
};

export default SplashScreen;
