import { createSlice } from '@reduxjs/toolkit';
import storage from 'services/utils/storage';
import ApiServices from 'api/apexchat.api.services';
import { general } from 'api/endpoints';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const fetchGeneralDataSlice = createSlice({
  name: 'fetchGeneralData',
  initialState: {
    mobileSuggestions: undefined
  },

  reducers: {
    /**
     * @name @mobileSuggestions
     * @description mobileSuggestions reducer to call mobileSuggestions async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    fetchMobileSuggestions: (state, action) => {
      state.mobileSuggestions = action.payload;
      // storage.setItem('mobileSuggestions', action.payload);
    },
    setMobileSuggestionsToState: state => {
      let mobileSuggestions = storage.getItem('mobileSuggestions');
      if (mobileSuggestions)
        state.mobileSuggestions = JSON.parse(mobileSuggestions);
    }
  }
});
export const { fetchMobileSuggestions, setMobileSuggestionsToState } =
  fetchGeneralDataSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/
export const fetchMobileSuggestionsAsync = payload => async dispatch => {
  return ApiServices.get(general.getMobileSuggestions, payload)
    .then(async ({ data }) => {
      if (data?.success) {
        await dispatch(fetchMobileSuggestions(data.data));
        return Promise.resolve('resolved');
      }
      return Promise.reject('rejected');
    })
    .catch(error => {
      return Promise.reject('rejected');
    });
};
/* called a selector and allows to select values from the state */
export const selectMobileSuggestions = state => {
  return state?.fetchGeneralData?.mobileSuggestions;
};

export default fetchGeneralDataSlice.reducer;
