export const en_mobileSettings = {
  heading1: 'Mobile',
  enableLive: {
    label: 'Enable Live',
    info: 'If Enabled, agents and operators will be able to transfer the user to a Live session.'
  },
  recordLiveSessions: {
    label: 'Record Live Sessions',
    info: 'If set, Live sessions will be recorded for later review.'
  },
  videoCallTimeout: {
    label: 'Video Call Timeout (Seconds)',
    info: 'Number of seconds to wait before video call ends.'
  },
  mobileSuggestions: {
    label: 'Mobile Suggestions',
    info: 'The script associated with the company.'
  },
  workflow: {
    label: 'Workflow',
    info: 'Determines which workflow will be used for finding Live agents.'
  },
  note: {
    label:
      'Note: A skinnable theme must be used with Live in order to work properly'
  }
};
export default en_mobileSettings;
export { default as fr_mobileSettings } from '../fr-FR-Dictionary/fr-mobileSettings';
export { default as sp_mobileSettings } from '../sp-SP-Dictionary/sp-mobileSettings';
