import storage from 'services/utils/storage';
import config from 'config';
import { IncreaseProgress } from 'pages/portal/messaging/ChatSplashScreen/SplashScreen';
import { logIt } from 'services/utils/logger';

const ChatLibraries = { Websync: 0, SingnalR: 1, Firebase: 2, SocketIO: 3 };

var CreateClient = function (lib, connectionUrl) {
  var clientConfig;
  if (ChatLibraries.Websync === lib) {
    var client = null;
    const user = JSON.parse(storage.getItem('Profile'));
    if (!client) {
      window.fm.websync.client.enableMultiple = true;
      client = new window.fm.websync.client(connectionUrl);
      client.addOnHttpRequestCreated(function (x) {
        var xhrRequest = x?.getRequest();
        xhrRequest?.setRequestHeader('Authorization', `bearer ${user?.JWT}`);
      });

      client.setAutoDisconnect({
        synchronous: true
      });
    }

    clientConfig = {
      client: client,
      connect: client.connect,
      disconnect: client.disconnect,
      connectArgs: {
        requestUrl: connectionUrl,

        stream: {
          requestUrl: connectionUrl
        },
        retries: 2, // always fail immediately, so we ensure lost messages get re-picked
        meta: 'dashboard',
        autoDisconnect: false,
        quiet: true
      },
      subscribe: client.subscribe,
      publish: client.publish,
      unsubscribe: client.unsubscribe,
      getSubscribedChannels: client.getSubscribedChannels,
      removeSubscribedChannels: client.removeSubscribedChannels,
      getClientId: client.getClientId
    };
  }
  return ChatClient(console.log, clientConfig);
};

var ChatClient = function (log, client) {
  if (!client > 0 && client < 4) {
    throw new Error(
      'The component cannot be created without mandatory client type specified'
    );
  }
  /**
   * with the specified client, wire up pub/sub calls
   */

  var _subscribe = function (payload) {
    var me = this;
    payload[me.onSubscribeSuccess] =
      payload[me.onSubscribeSuccess] ||
      function (arg) {
        log(me.tag + '-sub-' + me.onSubscribeSuccess);
      };
    payload[me.onSubscribeFailure] =
      payload[me.onSubscribeFailure] ||
      function (arg) {
        log(me.tag + '-sub-' + me.onSubscribeFailure);
      };
    payload[me.onSubscribeReceive] =
      payload[me.onSubscribeReceive] ||
      function (arg) {
        log(me.tag + '-sub-' + me.onSubscribeReceive);
      };
    me.subscribe.call(me.client, payload);
  };
  var _publish = function (payload) {
    var me = this;
    payload[me.onPublishSuccess] =
      payload[me.onPublishSuccess] ||
      function (arg) {
        log(me.tag + '-pub-' + me.onPublishSuccess);
      };
    payload[me.onPublishFailure] =
      payload[me.onPublishFailure] ||
      function (arg) {
        log(me.tag + '-pub-' + me.onPublishFailure);
      };

    me.publish.call(me.client, payload);
  };
  var _unsubscribe = function (payload) {
    var me = this;
    payload[me.onUnSubscribeFailure] =
      payload[me.onUnSubscribeFailure] ||
      function (arg) {
        log(me.tag + '-pub-' + me.onUnSubscribeFailure, arg.getErrorMessage());
      };

    me.unsubscribe.call(me.client, payload);
  };
  /**
   * initialize component with options
   */
  var _init = function (options) {
    var me = this;
    me.client = client.chatClient;
    me.subscribe = client.subscribe;
    me.unsubscribe = client.unsubscribe;
    me.publish = client.publish;
    me.connect = client.connect;
    me.disconnect = client.disconnect;
    me.connectArgs = client.connectArgs || {};
    me.getSubscribedChannels = client.getSubscribedChannels;
    me.removeSubscribedChannels = client.removeSubscribedChannels;
    me.getClientId = client.getClientId;

    if (options) {
      me.tag = options.tag || 'CC';

      var onConnectSuccess = options.onConnectSuccess || 'onSuccess';
      var onConnectFailure = options.onConnectFailure || 'onFailure';

      //me.connectArgs = { ...me.connectArgs, ...options.connectArgs };

      me.connectArgs[onConnectSuccess] = me.connectArgs[onConnectSuccess]
        ? me.connectArgs[onConnectSuccess]
        : function (args) {
            window.RTC.status = true;
            IncreaseProgress({
              text: 'Connected RTC Client...',
              count: 10
            });
            let timer = parseInt(
              (args.getTimestamp()._date.getTime() - new Date().getTime()) /
                1000,
              10
            );
            window.RTC.serverOffset = timer;
            log(me.tag + '-con- Chat Client connected successfully');
          };
      me.connectArgs[onConnectFailure] = me.connectArgs[onConnectFailure]
        ? me.connectArgs[onConnectFailure]
        : function (args) {
            log(args);
            window.RTC.init = false;
            window.RTC.status = false;
            log(me.tag + '-con- Chat Client failed to connect');
          };

      me.onSubscribeSuccess = options.onSubscribeSuccess || 'onSuccess';
      me.onSubscribeFailure = options.onSubscribeFailure || 'onFailure';
      me.onSubscribeReceive = options.onSubscribeReceive || 'onReceive';

      me.onPublishSuccess = options.onPublishSuccess || 'onSuccess';
      me.onPublishFailure = options.onPublishFailure || 'onFailure';

      me.onUnSubscribeFailure = options.onUnSubscribeFailure || 'onFailure';
    }
    me.connect(me.connectArgs);
  };
  //public data: methods and properties
  var PUBLIC_METHODS = {
    Publish: _publish,
    Subscribe: _subscribe,
    UnSubscribe: _unsubscribe
  };
  //constructor function used to instantiate objects
  function constr(options) {
    _init.call(this, options);
  }
  constr.prototype = PUBLIC_METHODS; //expose methods for caller

  return constr;
};

// const WebSyncCient = await CreateClient(
//   ChatLibraries.Websync,
//   'http://test.apexchat.com/' + 'request.ashx'
// );

const connect_callbacks = {
  onSuccess: function (arg) {
    logIt('log', 'on-connect-success');
  },
  onFailure: function (e) {
    logIt('log', 'on-connect-failure');
  }
};

const pushSubscribedChannels = channels => {
  let arr = window.RTC.subscribedChannels;
  window.RTC.subscribedChannels = [...arr, ...channels];
};

window.RTC = {
  status: false,
  subscribedChannels: [],
  pushSubscribedChannels: pushSubscribedChannels
};
const checkForInstance = () => {
  // const user = JSON.parse(storage.getItem('Profile'));
  IncreaseProgress({
    text: 'Creating RTC Client...',
    count: 10
  });
  const client = CreateClient(
    ChatLibraries?.Websync,
    config?.apiWCFUrl?.replace('Services/ApexChatService.svc/', '') +
      'request.ashx'
  );

  const ClientRTC = new client({
    tag: 'websync::',
    connectArgs: connect_callbacks
  });

  window.RTC.client = ClientRTC;
  return window.RTC;
};

export const RTCClient = function () {
  if (window.RTC?.status) {
    return window.RTC;
  } else if (!window.RTC.init) {
    window.RTC.init = true;
    return checkForInstance();
  } else {
    return window.RTC;
  }
};
