import {
  animateTabTitleOnMessageReceived,
  setTotalUnreadMessagesCount,
  setUnreadMessage,
  sortChatsByActivity
} from 'store/slices/messagingChats/messagingChats';
import { logIt } from 'services/utils/logger';
import { SystemMsgTypes } from 'services/utils/specific-conditions';
import storage from 'services/utils/storage';
import store from 'store';
import RTC from '../rtc';

const Subscribe_Unread_Messages_Count = callbacks => {
  const user = JSON.parse(storage.getItem('Profile'));
  RTC().Subscribe({
    channels: ['/unreadMessagesCount/' + user?.CompanyID],
    callbacks: callbacks
  });
};

export const Init_Unread_Messages_Count = () => {
  Subscribe_Unread_Messages_Count({
    onSuccess: function (args) {
      var data = args.getMeta();
      store.dispatch(setTotalUnreadMessagesCount(data));
      logIt('log', 'onSuccess::UnreadMessagesCount', args);
    },
    onFailure: function (args) {
      logIt('log', 'onFailure::UnreadMessagesCount', args);
    },
    onReceive: function (args) {
      var data = args.getData();
      console.log('UnRead Messages', data);
      if (data) {
        if (!SystemMsgTypes.includes(data.userId)) {
          store.dispatch(setUnreadMessage(data.ChatId));
        }
        //sort the active chats list by latest chat message
        store.dispatch(sortChatsByActivity(data.ChatId));
        // logIt('log', 'onReceive::', args.getData().text);
      }
    }
  });
};
