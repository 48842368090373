export const sp_leadEmails = {
  heading1: 'Los correos electrónicos de plomo',
  leadEmailAddresses: {
    label: 'Direcciones de correo electrónico de plomo',
    info: 'Uno o varios correos electrónicos direcciones que recibirán cada conductor HTML.Ejemplo: abc@ejemplo.com,xyz@ejemplo.com'
  },
  chatTranscriptEmailAddresses: {
    label: 'Transcripción del chat direcciones de correo electrónico',
    info: 'Uno o varios correos electrónicos direcciones que recibirán cada uno de transcripciones de conversación (no conductores).Ejemplo: abc@ejemplo.com,xyz@ejemplo.com'
  },
  classifyChatsAsSpam: {
    label: 'Clasifica chats como spam',
    info: 'Si se establece, el negocio va a recibir clientes potenciales para cada uno de chat (no conduce).'
  },
  verified: {
    label: 'Verified',
    info: 'Las direcciones de correo electrónico de plomo que han sido enviados verificaiton un correo electrónico para confirmar que están recieiving correos electrónicos. Esto sólo se puede ajustar haciendo clic en el enlace del correo electrónico de verificación'
  },
  verificationEmailSent: {
    label: 'El mensaje de verificación ha sido enviado',
    info: 'Sea o no el plomo dirección de correo electrónico ha tenido un correo electrónico de verificación enviado a ella'
  },
  hasInvalidEmailAddresses: {
    label: 'Tiene direcciones de correo electrónico no válidos',
    info: 'Este indicador se activará si la cuenta tiene ninguna dirección de correos electrónicos no válidos que no están recieiving los cables HTML en el campo Dirección de correo electrónico de plomo'
  },
  invalidLeadEmailAddresses: {
    label: 'Direcciones de correo electrónico no válidas de plomo',
    info: 'Esto mostrará las direcciones de correo electrónico no válidas que se han marcado por nuestros servidores de correo.'
  },
  companyType: {
    label: 'Tipo de compañía',
    info: 'Ajuste este para permitir mostrar los campos de la representación en el tablero de instrumentos.'
  },
  secureChats: {
    label: 'Chats seguros',
    info: 'Si se activa, las fuerzas de los chats que se ejecuten a través de SSL.'
  },
  secureLeads: {
    label: 'Leads seguras',
    info: 'Si se establece, notificaciones de plomo se desnudaron y contienen un enlace que requiere inicio de sesión.'
  }
};
export default sp_leadEmails;
