export const sp_trackingIntegration = {
  heading1: 'Seguimiento',
  heading2: 'Seguimiento de Google Analytics',
  heading3: 'Seguimiento de píxeles de Facebook',
  heading4: 'seguimiento personalizado',
  heading5: 'Google analitico 4',
  googleAnalyticsAccount: {
    label: 'Cuenta de Google Analytics',
    info: 'El ID de propiedad de Google Analytics en la que desea recibir eventos.'
  },
  googleAnalyticsDomain: {
    label: 'Analítica dominio de Google',
    info: 'El nombre de dominio asociado con la propiedad de Google Analytics.'
  },
  clientSideTracking: {
    label: 'Seguimiento del lado del cliente',
    info: 'Disparador de eventos de Google Analytics desde el lado del usuario final.'
  },
  googleAnalyticsTrackingWithClientId: {
    label: 'El ID de seguimiento de Google Analytics Cliente Con',
    info: 'Enviar charla y eventos de plomo utilizando el método de identificación de cliente.'
  },
  googleAnalyticsCrossDomainTracking: {
    label: 'Seguimiento multidominio de Google Analytics',
    info: 'Enviar charla y eventos de plomo utilizando el método de seguimiento en varios dominios.'
  },
  dashboardTracking: {
    label: 'Seguimiento del tablero de instrumentos',
    info: 'Disparador de eventos de Google Analytics desde el lado del servidor.'
  },
  salesLeadType: {
    label: 'Sales Lead Type',
    info: 'Enviar ventas conducen eventos.'
  },
  serviceLeadType: {
    label: 'Service Lead Type',
    info: 'Eventos de plomo de servicios Enviar.'
  },
  otherLeadType: {
    label: 'Other Lead Type',
    info: 'Enviar otros eventos de plomo.'
  },
  chatStartNotification: {
    label: 'Chat Start Notification',
    info: 'Enviar eventos de inicio de chat.'
  },
  accountCode: {
    label: 'Código de cuenta',
    info: 'El código de cuenta asociada con su facebook píxeles.'
  },
  measurementId: {
    label: 'Identificación de la medida',
    info: 'La identificación de la medida asociada con su análisis de Google 4.'
  },
  analyticsTrackingCodeForChats: {
    label: 'Código de seguimiento analítico de los chats',
    info: 'JavaScript utilizar para eventos de disparo cuando se inicia una conversación.'
  },
  analyticsTrackingCodeForLeads: {
    label: 'Leads Código de análisis de seguimiento para',
    info: 'JavaScript utilizar para eventos de disparo cuando un cliente potencial se convierte.'
  }
};
export default sp_trackingIntegration;
