import React from 'react';
import moment from 'moment';
import CopyButton from 'pub-components/CellRenderer/CopyButton';

const TimeStamp = ({ value, time = false, isDuration = false }) => {
  let date = value ? moment(new Date(value)).format('DD-MMMM-YYYY') : 'N/A';
  if (time && value) {
    const timestamp = parseInt(value.match(/\d+/)[0]);
    const dateObject = moment(timestamp);
    const formattedDate = dateObject.format('hh:mm:ss A');
    date = formattedDate;
  } else if (isDuration) {
    value = value ?? '0';
    const duration = moment.duration(value, 'minutes');
    const hours = duration.hours();
    const minutesRemaining = duration.minutes();
    const seconds = duration.seconds();
    date = moment({ hours, minutes: minutesRemaining, seconds }).format(
      'HH:mm:ss'
    );
  }
  return <CopyButton value={date} />;
};

export default TimeStamp;
