import React from 'react';
import 'assets/app-styles/scss/auth.scss';
import { Link } from 'react-router-dom';
import Logo from '../Sidebar/Logo';

const LeftColumn = () => {
  return (
    <Link to="/" className="link-logo">
      <Logo login />
    </Link>
  );
};

export default LeftColumn;
