// import Dashboard from 'pages/portal/dashboard/index';
// import Companies from 'pages/portal/companies';
// import Chat from 'pages/portal/chat';
// import Lead from 'pages/portal/lead';
// import Provision from 'pages/portal/provision';
// import Report from 'pages/portal/report';
// import Messaging from 'pages/portal/messaging';

// import BasicDetails from 'pages/portal/companies/general/basic-details';
// import ContactInformation from 'pages/portal/companies/general/contact-information';
// import UilSuitcaseAlt from '@iconscout/react-unicons/icons/uil-suitcase-alt';
import UilChartPieAlt from '@iconscout/react-unicons/icons/uil-chart-pie-alt';
import UilBuilding from '@iconscout/react-unicons/icons/uil-building';
import {
  UilUsersAlt,
  UilFocusAdd,
  UilCommentAltDownload,
  UilChart,
  UilTransaction
} from '@iconscout/react-unicons';
import { ReactComponent as Conversations } from 'assets/app-images/messages.svg';
import React from 'react';

const Dashboard = React.lazy(() => import('pages/portal/dashboard/index'));
const Companies = React.lazy(() => import('pages/portal/companies'));
const Messaging = React.lazy(() => import('pages/portal/messaging'));
const Chat = React.lazy(() => import('pages/portal/conversation'));
const Lead = React.lazy(() => import('pages/portal/lead'));
const Provision = React.lazy(() => import('pages/portal/provision'));
const Report = React.lazy(() => import('pages/portal/report'));

const portalRoutes = [
  {
    path: '/analytics',
    name: 'analytics',
    title: 'Analytics',
    component: props => <Dashboard />,
    // component: props => <Portal />,
    icon: <UilChartPieAlt />,
    exact: true
  },
  {
    path: '/portal/messaging',
    name: 'messaging',
    title: 'Messaging',
    component: props => <Messaging />,
    icon: <UilCommentAltDownload />,
    iconRightSide: <UilTransaction size="17" />,
    // redirect: 'http://test.apexchat.com/dashboard.aspx',
    exact: true,
    secondKey: 'allowMessaging'
  },
  {
    path: '/portal/companies',
    name: 'company',
    title: 'Companies',
    component: props => <Companies {...props} />,
    icon: <UilBuilding />,
    exact: true
  },
  {
    path: '/portal/conversations',
    name: 'chat',
    title: 'Conversations',
    component: props => <Chat {...props} />,
    icon: <Conversations style={{ width: '28px' }} />,
    exact: true
  },
  {
    path: '/portal/leads',
    name: 'lead',
    title: 'Leads',
    component: props => <Lead {...props} />,
    icon: <UilUsersAlt />,
    exact: true
  },
  {
    path: '/portal/report',
    name: 'report',
    title: 'Reports',
    component: props => <Report {...props} />,
    icon: <UilChart />,
    exact: true
  },
  {
    path: '/portal/onboarding',
    name: 'onBoarding',
    title: 'Onboarding',
    component: props => <Provision {...props} />,
    icon: <UilFocusAdd />,
    exact: true
  }
];

export default portalRoutes;
