import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    width: '100%'
  },
  tab: {
    width: '100%',
    color: 'rgba(0, 0, 0, 0.7)',
    borderRadius: '5px',
    textTransform: 'unset',
    background: '#F4F6F8',
    lineHeight: 1,
    border: 'none',
    '&.Mui-selected': {
      background: 'var(--primary-5)',
      color: '#fff',
      '&:hover': {
        backgroundColor: 'var(--primary) !important',
        color: '#fff'
      }
    },
    '&:hover': {
      backgroundColor: '#d9d9d9 !important',
      color: '#000'
    },
    '&.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.4)'
    }
  },
  firstTab: {
    width: '50%',
    '&.Mui-selected': {
      zIndex: 2,
      'border-top-right-radius': '5px !important',
      'border-bottom-right-radius': '5px !important'
    }
  },
  middleTab: {
    width: 'calc(50% + 12px)',
    marginLeft: '-12px !important',
    '&.Mui-selected': {
      zIndex: 3,
      'border-radius': '5px !important'
    }
  },
  secondTab: {
    width: 'calc(50% + 12px)',
    marginLeft: '-12px !important',
    '&.Mui-selected': {
      'border-top-left-radius': '5px !important',
      'border-bottom-left-radius': '5px !important'
    }
  },
  tabPanel: {
    padding: 0
  }
}));

export default useStyles;
