import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGridFilters,
  setGridFilters
} from 'store/slices/userPreferenceSettings/userPreferenceSlice';
import { generateFilterColumns } from './helper';
import { useEffect } from 'react';

export const useGridFilters = (storageKey, columns) => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState(null);
  const [sortInfo, setSortInfo] = useState(null);
  const gridFilters = useSelector(e => selectGridFilters(e, storageKey));

  useEffect(() => {
    if (gridFilters?.filterInfo?.length) {
      setFilterValue(gridFilters?.filterInfo);
    }
    if (gridFilters?.sortInfo) {
      setSortInfo(gridFilters?.sortInfo);
    }
  }, [gridFilters]);

  const defaultValues = useCallback(() => {
    return {
      defaultFilterValue:
        gridFilters?.filterInfo ?? generateFilterColumns(columns),
      defaultSortInfo: gridFilters?.sortInfo ?? null
    };
  }, [gridFilters]);

  const { defaultFilterValue, defaultSortInfo } = defaultValues();

  const saveGridFilters = e => {
    if (JSON.stringify(e) !== JSON.stringify(gridFilters?.filterInfo))
      dispatch(
        setGridFilters({
          key: storageKey,
          filters: { ...gridFilters, filterInfo: e }
        })
      );
  };

  const saveSortInfo = e => {
    if (JSON.stringify(e) !== JSON.stringify(gridFilters?.sortInfo))
      dispatch(
        setGridFilters({
          key: storageKey,
          filters: { ...gridFilters, sortInfo: e }
        })
      );
  };

  const onFilterValueChange = e => {
    if (storageKey) saveGridFilters(e);
    setFilterValue(e);
  };

  const onSortInfoChange = e => {
    if (storageKey) saveSortInfo(e);
    setSortInfo(e);
  };

  return {
    filterValue,
    sortInfo,
    defaultFilterValue,
    defaultSortInfo,
    onFilterValueChange,
    onSortInfoChange
  };
};
