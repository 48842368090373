import storage from 'services/utils/storage';
import React, { useRef } from 'react';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilCamera from '@iconscout/react-unicons/icons/uil-camera';
import { Avatar, Badge } from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { stopPropogation } from 'store/slices/general/generalSlice';
import { useDispatch } from 'react-redux';

function ProfileImage({
  hiddenFileInput,
  handleImageUpload,
  show,
  setShow,
  clickImageUpload,
  userFormik,
  imageUrl,
  onErrorImageUrl,
  setOpenDeleteModal
}) {
  const dispatch = useDispatch();
  const profile = JSON.parse(storage.getItem('Profile'));
  const target = useRef(null);
  const useCloseOnClickedOutside = async ref => {
    await dispatch(stopPropogation(ref)).then(res => {
      if (res) setShow(false);
    });
  };

  const wrapper = useRef(null);
  // calling the method to check if the clicked event
  // happened inside / outside the wrapper
  // and pass the wrapper ref
  useCloseOnClickedOutside(wrapper);
  return (
    <div className="profile-image">
      <input
        id="uploadImage"
        type="file"
        accept="image/*"
        name="myImage"
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        onChange={handleImageUpload}
      />
      <div className="imageWrapper" ref={wrapper}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            userFormik.values.userId === profile?.UserID &&
            !profile?.RequirePasswordReset ? (
              <Button
                type="button"
                className="custom-radius"
                style={{ borderRadius: '50%', padding: '5px' }}
                variant="primary"
                onClick={() => setShow(!show)}
                ref={target}
              >
                <UilCamera />
              </Button>
            ) : (
              0
            )
          }
        >
          {imageUrl ? (
            <Avatar
              key={imageUrl}
              className="avatar-img"
              alt="user"
              src={imageUrl}
              onError={onErrorImageUrl}
            />
          ) : (
            <UilUser size="88" color="lightgrey" className="avatar-icon" />
          )}
        </Badge>
        {show && (
          <div className={`change-profile-menu`}>
            <ul className="dropdow-list">
              <li className="notification-list-item">
                <a onClick={clickImageUpload} htmlFor="uploadImage">
                  Change Profile Photo
                </a>
              </li>
              {imageUrl ? (
                <li className="notification-list-item">
                  <a onClick={() => setOpenDeleteModal(true)}>Remove</a>
                </li>
              ) : null}
            </ul>
          </div>
        )}
      </div>
      <p className="username">{userFormik.values.displayName}</p>
    </div>
  );
}

export default ProfileImage;
