export const sp_crm = {
  heading1: 'CRM',
  heading2: 'Detalles básicos',
  crmProviders: {
    label: 'Los proveedores de CRM',
    info: 'Seleccione el CRM de esta lista que le gustaría enviar a clientes potenciales.'
  },
  crmUniqueID: {
    label: 'ID de Steam de CRM',
    info: 'Para encontrar el CRM Steam ID: <br /> <br /> en Salesforce: Configuración => Configuración de Administración => perfil de empresa => Actualización de información de su empresa => Salesforce.com Organización ID <br /> <br /> en InfusionSoft : http://kb.infusionsoft.com/index.php?/article/AA-00442/0/How-do-I-enable-the-Infusionsoft-API.html <br /> <br /> en Leads360: el parámetro "cliente" para obtener la url de integración (y Cliente = {} y este valor) <br /> <br /> en i360: la dirección de correo electrónico proporcionada por i360 <br /> <br /> en RL Convert: esto no es necesario que las empresas que utilizan RL RL Convertir porque ya tenemos la clave de la empresa; sólo hay que poner un "0" aquí. Si de alguna manera que la cuenta no es el caso en el futuro, hablar con Jerod <br /> <br /> en el consumo legal: establece este campo en "0" <br /> <br /> en inteligente Abogado: Conjunto este campo al Smart correo electrónico Abogado dirección de destino <br/> <br/> Este campo es necesario para la integración con CRMs.'
  },
  target_dbid: {
    label: 'DBID de destino',
    info: 'Este es el ID de base de datos de destino único.'
  },
  url: {
    label: 'Url',
    info: 'Esta es la URL única para la autenticación.'
  },
  consumerKey: {
    label: 'Clave del consumidor',
    info: 'Este es el ID de cliente único.'
  },
  consumerSecret: {
    label: 'Secreto del consumidor',
    info: 'Este es el código secreto exclusivo del cliente.'
  },
  securityToken: {
    label: 'Token de seguridad',
    info: 'Este es el token de seguridad único.'
  },
  username: {
    label: 'Nombre de usuario',
    info: 'Este es el nombre de usuario.'
  },
  password: {
    label: 'Contraseña',
    info: 'Esta es la contraseña.'
  },
  target_domain: {
    label: 'Dominio de destino',
    info: 'Esta es la dirección de dominio de destino única.'
  },
  app_token: {
    label: 'Token de aplicación',
    info: 'Este es el token de la aplicación.'
  },
  intakeSource: {
    label: 'Fuente ingesta',
    info: 'Este valor será variable según el sitio del que provenga el chat.'
  },
  leadTypeForCrm: {
    label: 'El plomo Para CRM',
    info: 'El tipo de cables para enviar a la CRM.'
  },
  lidogeneratorId: {
    label: 'ID del generador de Lido',
    info: 'Este es un número de identificación único que identifica la organización del cliente de Captorra a la que está publicando.'
  },
  endPointUrl: {
    label: 'URL de punto final',
    info: 'Esta es la URL de punto final única para cada cliente que desee utilizar General.'
  },
  hAPIKey: {
    label: 'Clave de API de HubSpot',
    info: 'Esta es la clave API de HubSpot única para cada cliente.'
  },
  hubSpotUrl: { label: 'URL de HubSpot', info: 'La URL para publicar datos.' },
  _entityForSales: {
    label: 'Entidad para ventas',
    info: 'Ingrese el nombre de la entidad donde desea recibir el cliente potencial de ventas. Déjelo en blanco si no aplica.'
  },
  _entityForService: {
    label: 'Entidad para el servicio',
    info: 'Ingrese el nombre de la entidad donde desea recibir el cliente potencial de servicio. Déjelo en blanco si no aplica.'
  },
  _entityForOthers: {
    label: 'Entidad para otros',
    info: 'Ingrese el nombre de la entidad donde desea recibir Otro cliente potencial. Déjelo en blanco si no aplica.'
  },
  contentType: {
    label: 'Tipo de contenido',
    info: 'Seleccione JSON o URL codificado como tipo de contenido.'
  },
  requestMethod: {
    label: 'Método de solicitud',
    info: 'Seleccione OBTENER o PUBLICAR como tipo de método.'
  },
  addHeaders: {
    label: 'Agregar encabezados',
    info: 'Habilite esta opción para agregar varios encabezados para la empresa específica.'
  },
  campaignId: {
    label: 'ID de campaña',
    info: 'El ID de la campaña en la que se crearán los clientes potenciales.'
  },
  provider: {
    label: 'Proveedor',
    info: 'Tel proveedor de integración para Leads360; algo como ReachLocal EDU.'
  },
  appname: {
    label: 'Nombre de la aplicación',
    info: 'La primera parte de la URL de la empresa de destino (http: // {nombre} .infusionsoft.com).'
  },
  actionsequence: {
    label: 'ID de secuencia de acción',
    info: 'El ID de la secuencia de acción que se ejecutará cuando se haya creado el contacto.'
  },
  publicKey: {
    label: 'Llave pública',
    info: 'La clave pública proporcionada por Legal Intake.'
  },
  privateKey: {
    label: 'Llave privada',
    info: 'La clave privada proporcionada por Legal Intake.'
  },
  enableOAuth2: { label: 'Habilitar OAuth2', info: 'Habilitar OAuth2.' },
  referrer: {
    label: 'Referente',
    info: 'Un único GUID (Identificador único global) que identifica al grupo o sitio.'
  },
  serviceUrl: {
    label: 'URL de servicio',
    info: 'Obtenga la URL de servicio de su organización en Configuración> Personalización> Recursos de desarrollo.'
  },
  domainName: { label: 'Nombre de dominio', info: 'Este es un dominio local.' },
  entity: { label: 'Entidad', info: 'Este es un dominio local.' },
  crmTemplate: {
    label: 'Plantilla de crm',
    info: 'Aquí puede configurar la plantilla Lead Xml / Json.'
  },
  sendTemplateAsEmailBody: {
    label: 'Enviar plantilla como cuerpo de correo electrónico',
    info: 'Esta bandera permitirá enviar un cliente potencial en formato Xml / Json como cuerpo del correo electrónico.'
  },
  crmEmailSubject: {
    label: 'Asunto del email',
    info: 'La línea de asunto del correo electrónico, si está en blanco, el valor predeterminado es Correo electrónico principal <Tipo de contenido>.'
  }
};
export default sp_crm;
