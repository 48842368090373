import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  FormGroupField,
  FormGroupToggleField
} from '../../FormGroupInputFields';

function UserForm({
  userFormIk,
  fieldErrorOnSubmit,
  handleOnChange,
  ownProfile
}) {
  return (
    <Form
      className="form form-class white-background p-0"
      onSubmit={userFormIk.handleSubmit}
      autoComplete="off"
    >
      <div className="editable-fields-lists py-0" style={{ gridGap: '22px' }}>
        <FormGroupField
          controlId="userId"
          label="User ID"
          handleOnChange={handleOnChange}
          dataFormIk={userFormIk}
          fieldErrorOnSubmit={fieldErrorOnSubmit}
        />
        <FormGroupField
          controlId="userName"
          label="User Name"
          handleOnChange={handleOnChange}
          dataFormIk={userFormIk}
          fieldErrorOnSubmit={fieldErrorOnSubmit}
        />
        <FormGroupField
          controlId="displayName"
          label="Display Name"
          handleOnChange={handleOnChange}
          dataFormIk={userFormIk}
          fieldErrorOnSubmit={fieldErrorOnSubmit}
          disabled={!ownProfile}
        />
        <FormGroupField
          controlId="email"
          label="Email"
          handleOnChange={handleOnChange}
          dataFormIk={userFormIk}
          fieldErrorOnSubmit={fieldErrorOnSubmit}
          disabled={!ownProfile}
        />
        <FormGroupField
          controlId="userType"
          label="User Type"
          handleOnChange={handleOnChange}
          dataFormIk={userFormIk}
          fieldErrorOnSubmit={fieldErrorOnSubmit}
        />
        <FormGroupToggleField
          controlId="lockedOut"
          label="Locked Out"
          handleChecked={handleOnChange}
          dataFormIk={userFormIk}
          fieldErrorOnSubmit={fieldErrorOnSubmit}
          disabled
        />
      </div>
      {/* <Row>
        <Col md={6}>
          <FormGroupToggleField
            controlId="preferMobile"
            label="Prefer Mobile"
            handleChecked={handleOnChange}
            dataFormIk={userFormIk}
            fieldErrorOnSubmit={fieldErrorOnSubmit}
            disabled
          />  
        </Col>
      </Row> */}
    </Form>
  );
}

export default UserForm;
