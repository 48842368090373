import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectRoles } from 'store/slices/roles/rolesSlice';
import { insertInArray } from 'services/utils/generic.methods';
import storage from 'services/utils/storage';
import { hasStepExpired, TourEnum } from '../tour-helper';
import portalRoutes from 'routes/portalRoutes';

const TourQuickStatsData = props => {
  const { t } = useTranslation();
  const { roles } = useSelector(selectRoles);
  const analyticGraphs = [
    {
      key: 'analytics',
      subKey: 'quickStats',
      element: '.introJS-analytics-quickstats',
      intro: t('company.tour.tourAnalytics'),
      position: 'right',
      path: '/analytics'
    }
  ];
  const messaging = [
    // {
    //   key: 'messaging',
    //   subKey: 'allowMessaging',
    //   element: '#introJS-item-1',
    //   intro: t('company.tour.tourMessaging'),
    //   position: 'right',
    //   className: 'neww'
    // }
  ];
  const tour = [
    {
      key: 'company',
      element: '#introJS-item-2',
      intro: t('company.tour.tourCompanies'),
      position: 'right'
    },
    {
      key: 'chat',
      element: '#introJS-item-3',
      intro: t('company.tour.tourChats'),
      position: 'right'
    },
    {
      key: 'lead',
      element: '#introJS-item-4',
      intro: t('company.tour.tourLeads'),
      position: 'right'
    }
    // {
    //   key: 'report',
    //   element: '#introJS-item-5',
    //   intro: t('company.tour.tourReports'),
    //   position: 'right'
    // },
    // {
    //   key: 'onBoarding',
    //   element: '#introJS-item-6',
    //   intro: t('company.tour.tourOnboarding'),
    //   position: 'right'
    // }
  ];
  const defaultItems = [
    {
      // subKey: 'default',
      element: '#introJS-item-setting',
      intro: t('company.tour.tourSettings'),
      position: 'right'
    },
    {
      // subKey: 'default',
      element: '#introJS-item-help',
      intro: t('company.tour.tourHelp'),
      position: 'right'
    },
    // {
    //   subKey: 'allowPortal',
    //   element: '#introJS-item-switch',
    //   intro: t('company.tour.tourSwitch'),
    //   position: 'right'
    // },
    {
      // subKey: 'default',
      title: t('company.tour.tourEnd'),
      intro: `<p style='text-align: left'>${t(
        'company.tour.tourEnd2'
      )}</p><img class="finish-tour-modal" src='/finish.png' />`,
      tooltipClass: 'customTooltip'
    }
  ];
  const switchTo = [
    {
      //key: 'switch',
      subKey: 'allowPortal',
      element: '#introJS-item-switch',
      intro: t('company.tour.tourSwitch'),
      position: 'right'
    }
  ];

  const newFeatureItems = [
    {
      key: 'messaging',
      subKey: 'allowMessaging',
      element: '#introJS-item-1',
      intro: t('company.tour.tourMessaging'),
      position: 'right',
      className: 'neww',
      expiry: '08-01-2022', // date MM-DD-YY
      hasViewed: false,
      isNew: true
    },
    {
      key: 'report',
      element: '#introJS-item-5',
      intro: t('company.tour.tourReports'),
      position: 'right',
      expiry: '08-01-2022', // date MM-DD-YY
      hasViewed: false,
      isNew: true
    },
    {
      key: 'onBoarding',
      element: '#introJS-item-6',
      intro: t('company.tour.tourOnboarding'),
      position: 'right',
      expiry: '08-01-2022', // date MM-DD-YY
      hasViewed: false,
      isNew: true
    }
  ];

  const pagesData = tour.filter(item => roles[item.key].view);
  const analyticsData = analyticGraphs.filter(item => roles?.analytics.view);
  const messagingData = messaging.filter(item => roles?.messaging[item.subKey]);
  const switchToData = switchTo.filter(item => roles?.switch[item.subKey]);
  const newFeatureData = newFeatureItems.filter(
    item =>
      (item.key === 'messaging' && roles?.messaging[item.subKey]) ||
      (item.key !== 'messaging' && roles[item.key].view)
  );

  let defaultData = [...defaultItems];
  if (!roles.settings.view) {
    defaultData.splice(0, 1);
  }
  const result = switchToData.length
    ? insertInArray(defaultData, 0, switchToData[0])
    : [...defaultData];

  const dynamicData = () => {
    let initialSteps = [
      ...analyticsData,
      ...messagingData,
      ...pagesData,
      ...newFeatureData
      // ...result
    ];
    let sidebarMenuNames = portalRoutes.map(route => route.name);
    let sortedSteps = initialSteps.sort(
      (a, b) =>
        sidebarMenuNames.indexOf(a.key) - sidebarMenuNames.indexOf(b.key)
    );
    let defaultSteps = [...sortedSteps, ...result];

    let savedTour = JSON.parse(storage.getItem('Tour'));
    if (savedTour?.tourModeEnabled && Object.keys(savedTour.pages).length) {
      // show new feature tour here
      let newSteps = newFeatureData.filter(step => {
        return (
          !savedTour.pages[TourEnum[step.key]] && !hasStepExpired(step.expiry)
        );
      });

      if (newSteps.length) {
        defaultSteps = newSteps;
      }
    }

    return defaultSteps;
  };
  const memoizedData = useMemo(dynamicData, [props.isTourCompleted]);

  return memoizedData;
};

export default TourQuickStatsData;
