import React from 'react';

const ViewCompany = React.lazy(() =>
  import('features/company/pvt-components/view')
);
const ViewConversation = React.lazy(() => import('features/conversation/view'));
const ViewLead = React.lazy(() => import('features/lead/pvt-components/view'));
const ViewReport = React.lazy(() => import('pages/portal/report/view'));
const ViewProvision = React.lazy(() => import('pages/portal/provision/view'));
const WidgetSettings = React.lazy(() =>
  import('features/settings/pvt-components/settings-detail')
);

const nestedRoutes = [
  {
    path: '/portal/companies/:id',
    component: props => <ViewCompany {...props} />,
    exact: true
  },
  {
    path: '/portal/conversations/:id',
    component: props => <ViewConversation {...props} />,
    exact: true
  },
  {
    path: '/portal/leads/:id',
    component: props => <ViewLead {...props} />,
    exact: true
  },
  {
    path: '/portal/report/:id',
    component: props => <ViewReport {...props} />,
    exact: true
  },
  {
    path: '/portal/onboarding/:id',
    component: props => <ViewProvision {...props} />,
    exact: true
  },
  {
    path: '/portal/settings/:companyId/:type?',
    name: 'settings-comany-details',
    title: 'Company settings',
    component: props => <WidgetSettings {...props} />,
    exact: false
  }
];

export default nestedRoutes;
