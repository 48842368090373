import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { ReportAPI, MyReportAPI, QueuedReportAPI } from 'api/endpoints';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    reports: {
      newReport: null,
      selectedReport: null
    }
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setSelectedReport: (state, action) => {
      state.reports.selectedReport = action.payload;
    },

    removeReportFromState: (state, action) => {
      state.reports[action.payload.key] = null;
    },

    updateSelectedReport: (state, action) => {
      state.reports.selectedReport = {
        ...state.reports.selectedReport,
        ...action.payload
      };
    },

    updateNewReport: (state, action) => {
      state.reports.newReport = {
        ...state.reports.newReport,
        ...action.payload
      };
    },

    removeAllReportsFromState: (state, action) => {
      state.reports = [];
    }
  }
});

export const {
  setSelectedReport,
  removeReportFromState,
  updateSelectedReport,
  removeAllReportsFromState,
  updateNewReport
} = reportsSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/
export const getReportAsync = payload => (dispatch, getState) => {
  let reportAPI = ReportAPI.get + '/' + payload.params.id;
  return ApiServices.getWithParams(reportAPI, payload)
    .then(async ({ data }) => {
      if (data?.success && data?.data) {
        await dispatch(setSelectedReport(data.data));
        return Promise.resolve(data);
      }
      return Promise.reject('rejected');
    })
    .catch(error => {
      return Promise.reject(error);
    });
  // }
};
/* called a selector and allows to select values from the state */
export const selectReports = state => {
  return state.reports;
};

export const getSelectedReport = state => {
  return state.reports.reports.selectedReport;
};

export const selectNewReport = state => {
  return state.reports.reports.newReport;
};

export const selectReport = (state, id) => {
  let reports = state.reports.reports;
  if (id && reports.length) {
    let temp = reports.filter(c => c.id === id);

    if (temp.length) {
      return { report: temp[0] };
    } else return { report: {} };
  } else return { report: {} };
};

export const getAllMyReportList = payload => {
  let reportAPI = MyReportAPI.getAll;
  return ApiServices.getWithParams(reportAPI, { params: payload });
};

export const getAllQueuedReportList = payload => {
  let reportAPI = QueuedReportAPI.getAll;
  return ApiServices.getWithParams(reportAPI, { params: payload });
};
export const exportAllMyReportList = payload => {
  let reportAPI = MyReportAPI.export;
  return ApiServices.WCF_getWithParams(reportAPI, { params: payload });
};

export const exportAllQueuedReportList = payload => {
  let reportAPI = QueuedReportAPI.export;
  return ApiServices.WCF_getWithParams(reportAPI, { params: payload });
};

export const getQueuedReportById = payload => {
  let { id, format } = payload;
  let query = QueuedReportAPI.downloadQueuedReport(id, format);
  return ApiServices.report_getwithParams(query);
};

export const downloadQueuedReport = payload => {
  let { id, format } = payload;
  let query = QueuedReportAPI.downloadQueuedReport(id, format);
  return ApiServices.report_get(query, { responseType: 'blob' });
};

export default reportsSlice.reducer;
