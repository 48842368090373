import { Box, Button, Card, Grid, makeStyles } from '@material-ui/core';
import Axios from 'axios';
import React, { Component } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import '../pages/auth/sso/styles.scss';

const useStyles = makeStyles({
  root: {
    minWidth: '100%',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  card: {
    maxWidth: '40%',
    minHeight: '20vh',
    display: 'flex',
    alignItems: 'center'
  }
});

export const ReadMessage = ({ ...props }) => {
  var styles = useStyles();
  let params = new URLSearchParams(props.location.search);
  const [timer, setTimer] = useState(0);
  let referenceId = params.get('referenceId');
  let identifier = params.get('identifier');
  let token = params.get('token');
  let code = params.get('code');
  let timerInSeconds = 90;
  let elapsedTime = 0;
  const [data, setData] = useState(null);

  const validateUser = async () => {
    return await Axios({
      method: 'get',
      url: `${process.env.REACT_APP_PORTAL_URL}/handlers/optinvalidationhandler.ashx`,
      params: {
        referenceId,
        identifier,
        token,
        code
      }
    });
  };

  useEffect(() => {
    if (data == null) {
      validateUser().then(t => {
        setData(t.data);
      });
    }
  }, [data]);

  const startTimer = () => {
    var timer = setInterval(() => {
      elapsedTime++;
      setTimer(elapsedTime);
      if (elapsedTime > timerInSeconds) {
        setTimer(0);
        clearInterval(timer);
      }
    }, 1000);
  };

  const resendEmail = async () => {
    startTimer();
    return await Axios({
      method: 'get',
      url: `${process.env.REACT_APP_PORTAL_URL}/handlers/optinvalidationhandler.ashx`,
      params: {
        referenceId,
        identifier,
        token,
        code,
        resendemail: true
      }
    }).then(t => {});
  };

  if (data != null && data.confirmed) {
    window.location.href =
      '/sso/' +
      data.jwtToken +
      '?redirect=/portal/messaging?chatId=' +
      referenceId;
  } else if (data != null && data.confirmationEmailSent) {
    return (
      <Grid
        spacing={0}
        alignItems="center"
        justify="center"
        className={styles.root}
      >
        <Card elevation={0}>
          <div
            style={{
              textAlign: 'center',
              fontSize: '24px',
              fontWeight: 700,
              marginBottom: '15px'
            }}
          >
            Check your inbox!
          </div>
          <div style={{ fontSize: '20px', textAlign: 'center' }}>
            a verification email has been sent to <br />{' '}
            <strong>{data.email}</strong>
          </div>
          <Box textAlign="center" pt={3}>
            Didn't receive it?{' '}
            <a href="#" onClick={resendEmail}>
              Resend Email{' '}
              {timer > 0 ? (
                <span>{timerInSeconds - timer}</span>
              ) : (
                <span></span>
              )}
            </a>
          </Box>
        </Card>
      </Grid>
    );
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center sso-no-data">
        <div className="verify-container">
          <i className="uil uil-spinner spinner ml-2 spinner-size"></i>
          <br />
          Please wait.. verification is in process...
        </div>
      </div>
    );
  }
};
