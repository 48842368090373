import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { WidgetEditor } from 'api/endpoints';
import storage from 'services/utils/storage';

/**
 * @name @createSlice
 * @description create redux store slice for Appearance widget
 **/
export const widgetAppearanceSlice = createSlice({
  name: 'widgetAppearance',
  initialState: {
    skins: {},
    previewHTML: {},
    widgetTemplates: {},
    companiesData: {},
    loadingMeta: { text: 'Loading components...', count: 0 }
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setSkin: (state, action) => {
      let skinId = action?.payload?.id;
      state.skins[skinId] = action.payload;
    },
    setPreviewHtml: (state, action) => {
      let { key, data } = action.payload;
      console.log('key', key);
      state.previewHTML[key] = data;
      // state.previewHTML[key] = key;
    },
    setTemplate: (state, action) => {
      let { type, key, data } = action.payload;
      state.widgetTemplates[type] = {
        ...state.widgetTemplates[type],
        [key]: data
      };
    },
    setCompanyData: (state, action) => {
      let { id, data } = action.payload;
      state.companiesData[id] = {
        ...state.companiesData[id],
        ...data //must be object
      };
    },
    setLoadingMeta: (state, action) => {
      let { text, count, reset = false } = action.payload;
      state.loadingMeta.text = text;
      if (reset) {
        state.loadingMeta.count = 0;
      } else {
        state.loadingMeta.count += count;
      }
    }
  }
});

export const {
  setSkin,
  setPreviewHtml,
  setTemplate,
  setCompanyData,
  setLoadingMeta
} = widgetAppearanceSlice.actions;

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/

/* called a selector and allows to select values from the state */
export const getSkinById = state => skinId => {
  return state.widgetAppearance.skins[skinId] || null;
};

export const getHTMLByKey = state => key => {
  return state.widgetAppearance.previewHTML[key] || null;
};

export const getTemplateByWidgetType = state => (type, key) => {
  let template = state.widgetAppearance.widgetTemplates[type] || null;
  return template ? template[key] : null;
};

export const isTemplateAvailable = state => key => {
  return !!state.widgetAppearance.widgetTemplates[key];
};

export const isTemplateKeyAvailable = state => (type, key) => {
  return !!state.widgetAppearance.widgetTemplates[type][key];
};

export const getCompanyDataById = state => companyId => {
  return state.widgetAppearance.companiesData[companyId] || null;
};

export const getLoadingMeta = state => {
  return state.widgetAppearance.loadingMeta;
};

export default widgetAppearanceSlice.reducer;

export const fetchProfile = payload => async dispatch => {
  let endpoint = WidgetEditor.getProfile(payload.id);
  return ApiServices.get(endpoint)
    .then(async response => {
      if (response?.data?.skin) {
        await dispatch(setSkin(response?.data?.skin));
        return Promise.resolve(response.data.skin);
      }
      return Promise.reject(response);
    })
    .catch(async err => {
      return Promise.reject(err);
    });
};
