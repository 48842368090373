import { createSlice } from '@reduxjs/toolkit';
import { loadScript } from 'services/utils/generic.methods';
import storage from 'services/utils/storage';

/**
 * @name @createSlice
 * @description create redux store slice for auth
 **/
export const scriptLoadSlice = createSlice({
  name: 'scriptLoad',
  initialState: {
    scriptLoad: JSON.parse(storage.getItem('ScriptLoad')) || false
  },

  reducers: {
    /**
     * @name @login
     * @description login reducer to call login async API with axios instance
     * @requires ApiServices.get method, state and API payload
     **/
    setScriptToState: (state, action) => {
      state.scriptLoad = action.payload;
    }
  }
});

export const { setScriptToState } = scriptLoadSlice.actions;

export const setScript = payload => (dispatch, getState) => {
  loadScript(payload.src);
  dispatch(setScriptToState(payload.isLoaded));
  storage.setItem('ScriptLoad', payload.isLoaded);
};
/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/

// }
/* called a selector and allows to select values from the state */
export const selectLoadedScript = state => {
  return state.scriptLoad;
};

export default scriptLoadSlice.reducer;
