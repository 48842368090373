import React from 'react';
import Button from 'react-bootstrap/Button';
import { UilCheckCircle, UilTimesCircle } from '@iconscout/react-unicons';

const StatusButton = ({ value, variant }) => {
  const getButtonValue = (value, variant) => {
    if (variant === 'enable-status') {
      return value ? 'Enabled' : 'Disabled';
    } else {
      return value ? 'Yes' : 'No';
    }
  };

  return (
    <span
      className={`${value ? 'text-success' : 'text-danger'}`}
      // style={{ margin: 'auto' }}
    >
      {getButtonValue(value, variant)}
    </span>
  );
};

export default StatusButton;
